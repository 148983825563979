import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import error from "../../Assets/Pictures/error.png"
const Notfound = () => {
  const navigate = useNavigate();
  return (
    <div className="container-fluid p-0 backerror">
      <span>
        <Navbar />
      </span>
      <div className="row gx-0">
        <div className="page404">
          <img src={error} alt="" className="error" />
          <button className="mt-3" onClick={() =>   navigate(-1)}>
            Go back !
            <FontAwesomeIcon icon={faArrowRight} className="ms-2 mt-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notfound;
