import React, { useState } from "react";
import {
  EmailValidation,
  NonEmptyValidation,
  PasswordValidation,
  PasswordValidationNew,
  StringValidation,
} from "../../../Utils/validate";
import { useNavigate } from "react-router-dom";
import { CreateAdmin } from "../../../Repository/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../../Utils/Toastify";

const AdminCreate = () => {
  const [data, setData] = useState({});
  const [validation, setValidation] = useState({});
  const [password, setPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state?.login);
  const setValues = (value, key) => {
    setData({ ...data, [key]: value });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const togglepassword = () => {
    setPassword(!password);
  };
  const formdata = [
    {
      label: "First Name",
      type: "text",
      value: data.firstName,
      placeholder: "Enter First Name",
      key: "firstName",
      valid: StringValidation,
      required: true,
    },
    {
      label: "Last Name",
      type: "text",
      value: data.lastName,
      placeholder: "Enter Last Name",
      key: "lastName",
      valid: StringValidation,
      required: true,
    },
    {
      label: "Email ID",
      type: "email",
      value: data.emailId,
      placeholder: "Enter Email ID",
      key: "emailId",
      valid: EmailValidation,
      required: true,
    },
    {
      label: "Password",
      type: "password",
      value: data.password,
      placeholder: "Enter password",
      key: "password",
      valid: PasswordValidationNew,
    },
  ];

  const handlesubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    formdata.forEach((field) => {
      if(field?.type!=="password"){
        validate[field.key] = field?.valid(field.value);
      }
    
    });
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const payload = {
      
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        emailId: data.emailId,
        userType: "admin",
      };
      const response = await CreateAdmin(payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response?.message);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        errorMsg(response?.message);
      }
    }
  };

  return (
    <div>
      <div className="content-header">
        <p>
          <span>
            <i
              class="fa-regular fa-circle-left me-3"
              onClick={() => navigate(-1)}
            />
          </span>
          Create Admin
        </p>
        {/* <button className='admin-btn' onClick={handlesubmit}>Create Admin</button> */}
      </div>
      <div className="content-body">
        <div className="row gx-5">
          {formdata.map((item, i) => {
            return (
              <div className="col-lg-6">
                <div className="d-flex flex-column create-input">
                  <label>
                    {item.label}{" "}
                    {item.required && <span className="star">*</span>}
                  </label>
                  <input
                    type={
                      item.type !== "password"
                        ? item.type
                        : password
                        ? "text"
                        : item.type
                    }
                    value={item.value}
                    placeholder={item.placeholder}
                    onChange={(e) => setValues(e.target.value, item.key)}
                    onBlur={(e) =>
                      setValidation({
                        ...validation,
                        [item.key]: {
                          isValid: item.type !== "password" ? item.valid(e.target.value) : true,
                        },
                      })
                    }
                    
                  />
                  
                  {item.type === "password" && (
                    <>
                      {password ? (
                        <i class="fa-solid fa-eye" onClick={togglepassword}></i>
                      ) : (
                        <i
                          class="fa-solid fa-eye-slash"
                          onClick={togglepassword}
                        ></i>
                      )}
                    </>
                  )}
                  <small className="validation-error error-space">
                    {validation?.[item.key]?.message
                      ? `${item.label} ${validation?.[item.key]?.message}`
                      : ""}
                  </small>
                </div>
              </div>
            );
          })}
        </div>
        <small className="note">
          (The password must contain at least 1 uppercase letter, 1 lowercase
          letter, 1 number, 1 special character and Atleast 8 Character. Password is not mandatory,
          Otherwise It will be mailed)
        </small>
        <div className="d-flex justify-content-center mt-3">
          <button className="admin-btn" onClick={handlesubmit}>
            Create Admin
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminCreate;
