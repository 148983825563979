import React from 'react'
import { routes } from './routes'
import { Routes, Route } from 'react-router-dom'
const PageContent = () => {
    return (
        <div className='page-content'>
            <Routes>
                {routes.map((item, i) => {
                    return (
                        <Route
                            path={`${item.path}`}
                            element={<item.component />}
                        />
                    )
                })}
            </Routes>
        </div>
    )
}

export default PageContent