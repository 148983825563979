import React, { useState } from 'react'
import Logo from '../../Assets/Pictures/logo.png'
import { EmailValidation } from '../../Utils/validate'
import { Forgotpassword } from '../../Repository/Api'
import { errorMsg, successMsg } from '../../Utils/Toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { registerSuccess } from '../../Store.js/RegisterSlice'
const ForgotPassword = () => {

    const [email, setEmail] = useState("")
    const [validation, setValidation] = useState({})
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const validate = {}
        validate.email = EmailValidation(email)
        console.log(validate)
        setValidation(validate)
        if (Object.values(validate).every(v => v.status === true)) {
            const payload = {
                emailId: email
            }
            const response = await Forgotpassword(payload)
            if (response && response.statusCode === 200) {
                successMsg(response?.message)
                dispatch(registerSuccess(payload))
                setTimeout(() => {
                    navigate('/verify-otp')
                }, 1000);
            } else {
                errorMsg(response.message)
            }
        }
    }
    return (
        <div className='conatainer'>
            <div className='forgot-password-page'>
                <div className=''>
                    <div className='d-flex justify-content-center'>
                        <img src={Logo} />
                    </div>
                    <div>
                        <p className='forgot-header'>Forgot Password?</p>
                        <label>Please Enter Your Email</label>
                        <div className='forgot-input'>
                            <i class="fa-solid fa-envelope"></i>
                            <input
                                type='email'
                                placeholder='Enter Email ID'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <small className='validation-error'>
                            {
                                validation?.email?.message ?
                                    `Email ID ${validation?.email?.message}` : ""
                            }
                        </small>
                        <button
                            className='admin-btn'
                            onClick={handleSubmit}
                        >
                            Get Otp
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword