import { createSlice } from "@reduxjs/toolkit";

const RegisterSlice = createSlice({
    name: "register",
    initialState: {
        emailId: ""
    },
    reducers: {
        registerSuccess: (state, action) => {
            console.log(action.payload)
            const userDetails = action.payload;
            state.emailId = userDetails?.emailId;
        },
    },
});

export const { registerSuccess } = RegisterSlice.actions;

export default RegisterSlice.reducer;
