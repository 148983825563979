import React from 'react'
import Sidebar from './routes/Sidebar'
import PageContent from './PageContent'
import Header from './Header'

const Layout = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <PageContent />
        </>
    )
}

export default Layout