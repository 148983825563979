import React, { useState } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import "./contactus.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrowleft from "../../Assets/image/arrowleft.png";
import arrowright from "../../Assets/image/arrowright (2).png";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../Utils/validate";
import { createContactform } from "../../Repository/Api";
import { errorMsg, successMsg } from "../../Utils/Toastify";
import { useSelector } from "react-redux";
import CustomModal from "../Common/CustomModal";

const CantactUs = () => {
  const [createAdmin, setCreateAdmin] = useState({});
  const [validation, setValidation] = useState({});
  const [open, setOpen] = useState(false);

  const setData = (e, key) => {
    setCreateAdmin({ ...createAdmin, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };
  const { token } = useSelector((state) => state?.login);
  const handleSubmit = async () => {
    let validate = {};

    validate.name = StringValidation(createAdmin?.name);
    validate.emailId = EmailValidation(createAdmin?.emailId);
    validate.phoneNumber = PhoneNumberValidation(createAdmin?.phoneNumber);
    validate.subject = NonEmptyValidation(createAdmin?.subject);
    validate.message = NonEmptyValidation(createAdmin?.message);

    setValidation(validate);

    console.log(validate, "validate");

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        name: createAdmin.name,
        emailId: createAdmin.emailId,
        phoneNumber: createAdmin.phoneNumber,
        subject: createAdmin.subject,
        message: createAdmin.message,
      };
      const response = await createContactform(Payload, token);
      if (response && response.statusCode === 200) {
        setCreateAdmin({
          name: "",
          emailId: "",
          phoneNumber: "",
          subject: "",
          message: "",
        });
        setValidation({});
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          window.location.reload();
        }, 1000);
      } else {
        errorMsg(response?.message);
      }
    }
  };

  return (
    <div>
      <span>
        <Navbar />
      </span>
      <div className="banner text-center pt-5 pb-5">
        <div className="container conabsolute">
          <h1>
            Contact <span>us</span>
          </h1>
          {/* <p className="mt-4">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. <br />
            Lorem Ipsum has been the industry's standard
          </p> */}
          <img src={arrowleft} className="conimgarrow1" alt="arrow" />
          <img src={arrowright} className="conimgarrow2" alt="arrow" />
        </div>
      </div>
      {/* content2  */}
      <div className="info">
        <div className="sec1 ">
          <i className="icon mt-2 ">
            <FontAwesomeIcon icon={faPhone} className="styleicon me-4" />
          </i>
          <span className="conts">
            <a href="tel:+1-940-394-2948">510-676-1539</a>
            {/* <a href="tel:+1-389-385-3807 ">+1-389-385-3807</a> */}
          </span>
        </div>

        <div className="sec2 ">
          <i className="icon mt-2 ">
            <FontAwesomeIcon icon={faEnvelope} className="styleicon me-4" />
          </i>

          <span className="conts">
            <a href="mailto:infletio@gmail.com"> infletio@gmail.com</a>
            {/* <a href="mailto: contact@infletio.in"> contact@infletio.in</a> */}
          </span>
        </div>

        <div className="sec3 ">
          <i className="icon mt-2">
            <FontAwesomeIcon icon={faLocationDot} className="styleicon me-4" />
          </i>
          <p> 901 East 5th Street Unit 1424
          </p>
        </div>
      </div>
      {/* content3 */}

      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="table">
              <div className="row">
                <div className="col-lg-6 field">
                  <div className="form">
                    <label for="fname">
                      Name<span className="hash">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      name="fname"
                      maxlength="30"
                      autofocus
                      placeholder="i.e. John Doe"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setData(e.target.value, "name");
                      }}
                      onBlur={(e) => {
                        setValidationValue(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="textDanger">
                      {validation?.name?.message
                        ? `Name ${validation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className=" col-lg-6 field">
                  <div className="form">
                    <label for="fname">
                      Email<span className="hash">*</span>
                    </label>
                    <br />
                    <input
                      type="email"
                      name="fname"
                      placeholder="i.e. john@mail.com"
                      required
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setData(e.target.value, "emailId");
                      }}
                      onBlur={(e) => {
                        setValidationValue(
                          "emailId",
                          EmailValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="textDanger">
                      {validation?.emailId?.message
                        ? `Email-Id ${validation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className=" col-lg-6 field gy-2">
                  <div className="">
                    <label for="fname">
                      Phone Number<span className="hash">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      name="fname"
                      required
                      placeholder="i.e. +1-234-567-7890"
                      style={{ width: "100%" }}
                      maxLength={10}
                      onChange={(e) => {
                        setData(e.target.value, "phoneNumber");
                      }}
                      onBlur={(e) => {
                        setValidationValue(
                          "phoneNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="textDanger">
                      {validation?.phoneNumber?.message
                        ? `Phone Number ${validation?.phoneNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className=" col-lg-6 field gy-2">
                  <div className="">
                    <label for="fname">
                      Subject<span className="hash">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      name="fname"
                      placeholder="i.e. I need a help"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setData(e.target.value, "subject");
                      }}
                      onBlur={(e) => {
                        setValidationValue(
                          "subject",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="textDanger">
                      {validation?.subject?.message
                        ? `Subject ${validation?.subject?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className=" col-lg-6 field gy-2">
                  <div className="correction">
                    <label for="fname">
                      Message<span className="hash">*</span>
                    </label>
                    <br />
                    <textarea
                      type="text"
                      name="fname"
                      class="form-control h-auto"
                      rows={3}
                      placeholder="Enter a Message"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setData(e.target.value, "message");
                      }}
                      onBlur={(e) => {
                        setValidationValue(
                          "message",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="textDanger">
                      {validation?.message?.message
                        ? `Message ${validation?.message?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
              <button
                className="sendbtn mt-5 ms-2"
                onClick={() => {
                  handleSubmit();
                  // successMsg("Thanks for contacting us ");
                }}
              >
                Send
              </button>
              {/*  <CopyToClipboard text={window.location.href}>
                      <button
                        onClick={() => successMsg("successfully Copied !")}
                      >
                        Copy
                      </button>
                    </CopyToClipboard> */}
            </div>
          </div>
        </div>
      </div>
      <div className="contactfooter">
        <Footer />
      </div>

      <CustomModal
        open={open}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className="modal-content">
          <div className="header">
            <div className="text-center ">
              <i class="fa-solid fa-circle-check green fs-1"></i>
            </div>
          </div>
          <div className="successData">
            <p className="text-center fw-bold mb-0">
              Your request has been submitted successfully
              <span></span>
            </p>
            <div className="share-btn text-center">
              <button
                onClick={() => {
                  setOpen(false);

                  window.location.reload();
                  setCreateAdmin({
                    name: "",
                    emailId: "",
                    phoneNumber: "",
                    subject: "",
                    message: "",
                  });
                  setValidation({});
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};
export default CantactUs;
