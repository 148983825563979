import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { MuiTableOptions } from "../../Utils/utils";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  DeleteJobs,
  ImageBaseUrl,
  Jobslist,
  UpdateJobs
} from "../../Repository/Api";
import DeleteModal from "../../Utils/DeleteModal";
import { successMsg } from "../../Utils/Toastify";
const Jobs = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteid, setDeleteid] = useState();
  const Tabs = ["company", "job"];
  const [selectedTab, setSelectedTab] = useState(Tabs[0]);
  const { token } = useSelector((state) => state?.login);
  const navigate = useNavigate();
  const GetData = async () => {
    const response = await Jobslist(token);
    if (response && response.statusCode === 200) {
      // setData(response?.data);
      const sortedData = response.data.sort((a, b) => b._id.localeCompare(a._id));
      setData(sortedData);
    }
  };
  const handleStatus = async (payload) => {
    const response = await UpdateJobs(payload, token);
    if (response && response?.statusCode === 200) {
      successMsg(response?.message);
      GetData();
    }
  };
  useEffect(() => {
    GetData();
  }, []);

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },

    {
      name: "companyLogo",
      label: "Company Logo",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
            // onClick={() =>
            //   Swal.fire({
            //     imageUrl:
            //       ImageBaseUrl +
            //       value,
            //     imageHeight: 350,
            //     imageAlt: "Your Profile Picture",
            //     confirmButtonColor: "rgb(31 37 59)",

            //     confirmButtonText: "Close",
            //   })
            // }
            >
              <img
                width={"50px"}
                height={"50px"}
                src={`https://infletio-prod.s3.us-east-2.amazonaws.com/${value}`}
                alt=""
              />
            </div>
          );
        },
      },
    },
    {
      name: "company",
      label: "Company",
    },

    {
      name: "jobTitle",
      label: "Job Role",
    },
    {
      name: "experience",
      label: "Experience",
    },
    {
      name: "salaryRange",
      label: "Salary Range",
    },
    {
      name: "qualification",
      label: "Qualification",
    },

    {
      name: "status",
      label: "Status",
      options: {
        display: false,
      },
    },

    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, metaData) => {
          const status = metaData?.rowData[7] === "active" ? true : false;
          const id = metaData?.rowData[0];
          return (
            <div className="action-icons jobicons">
              <i
                class="fa-regular fa-pen-to-square"
                onClick={() => navigate(`/admin/Update-Job/${id}`)}
              />
              <i
                class="fa-solid fa-trash"
                onClick={() => {
                  setOpen(true);
                  setDeleteid(metaData?.rowData[0]);
                }}
              />
              <label className="switch ms-2">
                <input
                  type="checkbox"
                  checked={status}
                  onChange={async (e) => {
                    const status =
                      e.target.checked === true ? "active" : "inactive";
                    const payload = {
                      _id: metaData.rowData[0],
                      status: status,
                    };
                    handleStatus(payload);
                  }}
                />
                <span className="slider round"></span>
              </label>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <DeleteModal
        open={open}
        setOpen={() => {
          setOpen(false);
        }}
        api={DeleteJobs}
        id={deleteid}
        token={token}
        get={GetData}
      />
      <div className="content-header">
        <p>Job Management</p>

        <button
          onClick={() => navigate("/admin/jobs-Create")}
          className="admin-btn"
        >
          Add Job
        </button>
      </div>
      <MUIDataTable
        title="Job List"
        data={data}
        columns={columns}
        options={MuiTableOptions}
      />
    </div>
  );
};

export default Jobs;
