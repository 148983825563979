import React, { useState } from "react";
import Logo from "../../Assets/Pictures/logo.png";
import { EmailValidation, PasswordValidation } from "../../Utils/validate";
import { Changepassword } from "../../Repository/Api";
import { errorMsg, successMsg } from "../../Utils/Toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerSuccess } from "../../Store.js/RegisterSlice";
const ForgotPassword = () => {
  const [code, setcode] = useState("");
  const [validation, setValidation] = useState({});
  console.log(validation);
  const [password, setPassword] = useState(false);
  const [password2, setPassword2] = useState(false);
  const { emailId } = useSelector((state) => state?.register);
  const navigate = useNavigate();
  const togglepassword = () => {
    setPassword(!password);
  };
  const togglepassword2 = () => {
    setPassword2(!password2);
  };
  const setValues = (e, key) => {
    setcode({ ...code, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.password = PasswordValidation(code.password);
    validate.password2 = PasswordValidation(code.password2);
    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      if (code.password === code.password2) {
        const payload = {
          emailId: emailId,
          password: code.password,
        };
        const response = await Changepassword(payload);
        if (response && response.statusCode === 200) {
          successMsg(response?.message);
          setTimeout(() => {
            navigate("/adminlogin");
          }, 1000);
        } else {
          errorMsg(response.message);
        }
      } else {
        errorMsg("Password does not match");
      }
    }
  };
  return (
    <div className="conatainer">
      <div className="forgot-password-page">
        <div className="">
          <div className="d-flex justify-content-center">
            <img src={Logo} />
          </div>
          <div>
            <div className="forgot-input">
              <i class="fa-solid fa-key"></i>
              <input
                type={password ? "text" : "password"}
                placeholder="Enter new Password"
                onChange={(e) => setValues(e.target.value, "password")}
              />
              {!password ? (
                <i class="fa-solid fa-eye" onClick={togglepassword}></i>
              ) : (
                <i class="fa-solid fa-eye-slash" onClick={togglepassword}></i>
              )}
              <small className="validation-error ">
                {validation?.password?.message
                  ? `Password ${validation?.password?.message}`
                  : ""}
              </small>
            </div>
            <div className="forgot-input">
              <i class="fa-solid fa-key"></i>
              <input
                type={password2 ? "text" : "password"}
                placeholder="Enter Confirm Password"
                onChange={(e) => setValues(e.target.value, "password2")}
              />
              {!password2 ? (
                <i class="fa-solid fa-eye" onClick={togglepassword2}></i>
              ) : (
                <i class="fa-solid fa-eye-slash" onClick={togglepassword2}></i>
              )}
              <small className="validation-error ">
                {validation?.password2?.message
                  ? `Password ${validation?.password2?.message}`
                  : ""}
              </small>
            </div>
            <button className="admin-btn" onClick={handleSubmit}>
              Update Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
