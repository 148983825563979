import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { MuiTableOptions } from "../../Utils/utils";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Bloglist, DeleteBlog, UpdateBlog } from "../../Repository/Api";
import DeleteModal from "../../Utils/DeleteModal";
import { successMsg } from "../../Utils/Toastify";
const Blogs = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteid, setDeleteid] = useState();
  const Tabs = ["company", "job"];
  const [selectedTab, setSelectedTab] = useState(Tabs[0]);
  const { token } = useSelector((state) => state?.login);
  const navigate = useNavigate();
  const GetData = async () => {
    const response = await Bloglist(token);
    if (response && response.statusCode === 200) {
      setData(response?.data);
    }
  };
  const handleStatus = async (payload) => {
    const response = await UpdateBlog(payload, token);
    if (response && response?.statusCode === 200) {
      successMsg(response?.message);
      GetData();
    }
  };
  useEffect(() => {
    GetData();
  }, []);

  const updateOrder = async (id, order) => {
    const payload = {
      _id: id,
      displayOrder: order,
    };
    const response = await UpdateBlog(payload, token);

    if (response && response.statusCode === 200) {
      GetData();
      successMsg(response?.message);
    }
  };
  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },



    {
      name: "authorImage",
      label: "Author Image",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <img width={"50px"} height={"50px"} src={`https://infletio-prod.s3.us-east-2.amazonaws.com/${value}`} alt="" />
            </div>
          );
        },
      },
    },
    {
      name: "author",
      label: "Author",
    },
    {
      name: "title",
      label: "Blogs Title",
    },
    // {
    //   name: "content",
    //   label: "Content",
    // },
    {
      name: "minutes",
      label: "Minutes",
    },

    {
      name: "status",
      label: "Status",
      options: {
        display: false,
      },
    },
    // {
    //     name: "displayOrder",
    //     label: "Display Order",
    //     options: {

    //       customBodyRender: (value, tableMeta) => {
    //         return tableMeta.rowIndex !== 0  ? (
    //           <div>
    //             <input
    //               className="order-input"
    //               type=""
    //               defaultValue={value}
    //               onBlur={(e) =>
    //                 updateOrder(tableMeta.rowData[0], e.target.value)
    //               }
    //             />
    //           </div>
    //         ) : 0; 
    //       },
    //     },
    //   },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, metaData) => {
          const status = metaData?.rowData[5] === "active" ? true : false;
          const id = metaData?.rowData[0];
          return (
            <div className="action-icons">
              <i
                class="fa-regular fa-pen-to-square"
                onClick={() => navigate(`/admin/Blog-edit/${id}`)}
              />
              <i
                class="fa-solid fa-trash"
                onClick={() => {
                  setOpen(true);
                  setDeleteid(metaData?.rowData[0]);
                }}
              />
              <label className="switch ms-2">
                <input
                  type="checkbox"
                  checked={status}
                  onChange={async (e) => {
                    const status =
                      e.target.checked === true ? "active" : "inactive";
                    const payload = {
                      _id: metaData.rowData[0],
                      status: status,
                    };
                    handleStatus(payload);
                  }}
                />
                <span className="slider round"></span>
              </label>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <DeleteModal
        open={open}
        setOpen={() => {
          setOpen(false);
        }}
        api={DeleteBlog}
        id={deleteid}
        token={token}
        get={GetData}
      />
      <div className="content-header">
        <p>Blogs Management</p>

        <button
          onClick={() => navigate("/admin/BlogCreate")}
          className="admin-btn"
        >
          Add Blogs
        </button>
      </div>
      <MUIDataTable
        title="Blogs List"
        data={data}
        columns={columns}
        options={MuiTableOptions}
      />
    </div>
  );
};

export default Blogs;
