import React, { useState } from "react";

const ExpandableText = ({ text, maxLength, className }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    const removeHTMLTags = (input) =>
        input
            ?.replace(/<\/?[^>]+(>|$)/g, "")
            ?.replace(/&nbsp;|&#160;|&#xA0;/g, "")
            .trim();

    const truncatedText =
        text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    return (
        <div>
            <p style={{ display: "inline" }} className={className}>
                {isExpanded ? removeHTMLTags(text) : removeHTMLTags(truncatedText)}
            </p>
            {text.length > maxLength && (
                <button
                    style={{ display: "inline-block", marginLeft: "5px" }}
                    className="text-sm underline font-bold-outfit seemore-less-btn"
                    onClick={toggleText}
                >
                    <div className="d-flex">
                        {isExpanded ? (
                            <>
                                <span className="text">See less</span>
                                <span className="material-symbols-outlined">
                                    keyboard_arrow_up
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="text">See more</span>
                                <span className="material-symbols-outlined">
                                    keyboard_arrow_down
                                </span>
                            </>
                        )}
                    </div>
                </button>
            )}
        </div>
    );
};

export default ExpandableText;

// import React, { useState } from "react";

// const ExpandableText = ({ text, maxLength, className }) => {
//     const [isExpanded, setIsExpanded] = useState(false);

//     const toggleText = () => {
//         setIsExpanded(!isExpanded);
//     };

//     const removeHTMLTags = (input) =>
//         input
//             ?.replace(/<\/?[^>]+(>|$)/g, "")
//             ?.replace(/&nbsp;|&#160;|&#xA0;/g, " ")
//             .trim();

//     const cleanText = removeHTMLTags(text);

//     const truncatedText =
//         cleanText.length > maxLength ? `${cleanText.slice(0, maxLength)}...` : cleanText;

//     return (
//         <div>
//             <p style={{ display: "inline" }} className={className}>
//                 {isExpanded ? cleanText : truncatedText}
//             </p>
//             {cleanText.length > maxLength && (
//                 <button
//                     style={{ display: "inline-block", marginLeft: "5px" }}
//                     className="text-sm underline font-bold-outfit seemore-less-btn"
//                     onClick={toggleText}
//                 >
//                     <div className="d-flex">
//                         {isExpanded ? (
//                             <>
//                                 <span className="text">See less</span>
//                                 <span className="material-symbols-outlined">
//                                     keyboard_arrow_up
//                                 </span>
//                             </>
//                         ) : (
//                             <>
//                                 <span className="text">See more</span>
//                                 <span className="material-symbols-outlined">
//                                     keyboard_arrow_down
//                                 </span>
//                             </>
//                         )}
//                     </div>
//                 </button>
//             )}
//         </div>
//     );
// };

// export default ExpandableText;
