import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { MuiTableOptions } from "../../../Utils/utils";
import {
  Contactuslist,
  DeleteContactUs,
  SpecficContactUs,
  UpdateContactus,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { successMsg } from "../../../Utils/Toastify";
import DeleteModal from "../../../Utils/DeleteModal";

import moment from "moment";
import ContactModel from "../../ContactModel";
import CustomModal from "../../../Utils/CustomModal";
const ContactUsList = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteChildListModal, setdeleteChildListModal] = useState(false);
  const [deleteid, setDeleteid] = useState();
  const { token } = useSelector((state) => state?.login);
  const navigate = useNavigate();

  const GetData = async () => {
    const response = await Contactuslist(token);
    if (response && response.statusCode === 200) {
      setData(response?.data);
    }
  };
  useEffect(() => {
    GetData();
  }, []);
  const [contactUsSpecfic, setContactUsSpecific] = useState({});

  const GetDataSpecific = async (id) => {
    const response = await SpecficContactUs(id, token);
    if (response && response.statusCode === 200) {
      setContactUsSpecific(response?.data);
    }
  };

  console.log(data);
  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "emailId",
      label: "Email Id",
    },
    {
      name: "subject",
      label: "Subject",
    },
    {
      name: "read",
      label: "read",
      options: {
        display: false,
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, metaData) => {
          console.log(metaData);
          const status = metaData?.rowData[2] === "active" ? true : false;
          const id = metaData?.rowData[0];
          return (
            <div className="action-icons">
              <i
                class="fa-solid fa-eye"
                onClick={() => {
                  GetDataSpecific(id);
                  setdeleteChildListModal(true);
                }}
              />
              <i
                class="fa-solid fa-trash"
                onClick={() => {
                  setOpen(true);
                  setDeleteid(metaData?.rowData[0]);
                }}
              />
            </div>
          );
        },
      },
    },
  ];
  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[4] === false) {
        return {
          style: { background: "#c3f4ed", cursor: "pointer" },

        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <DeleteModal
        open={open}
        setOpen={() => {
          setOpen(false);
        }}
        api={DeleteContactUs}
        id={deleteid}
        token={token}
        get={GetData}
      />
      <div className="content-header">
        <p>Contact Management</p>
      </div>
      <CustomModal
        open={deleteChildListModal}
        onClickOutside={() => {
          setdeleteChildListModal(false);
          GetData();
        }}
      >
        <div className="modal-content contact-form-admin-modal">


          <div className=" messagecontent " >
            <div className="row d-flex align-items-center justify-content-center  mt-0">

              <span
                class="material-symbols-rounded filled-icon close-icon"
                onClick={() => {
                  setdeleteChildListModal(false);
                  GetData();
                }}
              // style={{
              //   marginRight: "25px",
              //   marginTop: "25px",
              //   textShadow: "rgba(171, 167, 167, 0.44) 0px 3px 6px",
              // }}
              >
                close
              </span>
              <div className="row justify-content-center px-3">
                <div className="col-lg-12">
                  <div className="row justify-content-center py-4">
                    {/* <div className="col-lg-4"></div> */}
                    <div className="col-lg-11 my-auto">
                      <h3 className="popupmess fw-bold">Contact Us List</h3>
                      <div className="modal-content-childlist">

                        <table>
                          <tr>
                            <th >
                              <h6 className="me-4 text-heading-tab">Name</h6>
                            </th>
                            <td >
                              <p className="mb-0 ">
                                {contactUsSpecfic?.name}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <h6 className="me-4 text-heading-tab">Mobile number</h6>
                            </th>
                            <td>
                              <p className="mb-0 ">
                                {contactUsSpecfic?.phoneNumber}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <h6 className="me-4 text-heading-tab">Email-Id</h6>
                            </th>
                            <td>
                              <p className="mb-0">
                                {contactUsSpecfic?.emailId}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <h6 className="me-4 text-heading-tab">Subject</h6>
                            </th>
                            <td>
                              <p className="mb-0">
                                {contactUsSpecfic?.subject}
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <h6 className="me-4 text-heading-tab"> Message</h6>
                            </th>
                            <td>
                              <p className="mb-0">
                                {contactUsSpecfic?.message}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <h6 className="me-4 text-heading-tab">Requested At</h6>
                            </th>
                            <td>
                              <p className="mb-0">
                              <span className="market">
                              {moment(contactUsSpecfic?.createdAt).format(
                                  "DD/MM/YYYY"
                                )}</span> 
                              </p>
                            </td>
                          </tr>
                          {/* <tr>
                                <th>
                                  <h6>Updated-At</h6>
                                </th>
                                <td>
                                  <p className="mb-0">
                                    {moment(contactUsSpecfic?.updatedAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </td>
                              </tr> */}
                          <tr>
                            <th >
                              <h6 className="me-4 text-heading-tab" >Status</h6>
                            </th>
                            <td>
                              <p className="mb-0">
                                {contactUsSpecfic?.status}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </CustomModal>
      <MUIDataTable
        title="Contact List"
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default ContactUsList;
