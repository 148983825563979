import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Home from "./Components/Pages/Home";
import Aboutus from "./Components/Pages/Aboutus";
import JobOpportunities from "./Components/Pages/JobOpportunities";
import ContactUs from "./Components/Pages/CantactUs";
import BrowseCompanies from "./Components/Pages/BrowseCompanies";
import BrowseSub from "./Components/Pages/BrowseSub";
import Blogs from "./Components/Pages/Blogs";
import Adminlogin from "./Components/Pages/Adminlogin";
import Layout from "./Components/Admin/Layout";
import ForgotPassword from "./Components/Admin/ForgotPassword";
import VerifyOtp from "./Components/Admin/VerifyOtp";
import ChangePassword from "./Components/Admin/ChangePassword";
import BlogDetails from "./Components/Pages/BlogDetails";
import JobDetails from "./Components/Pages/JobDetails";

import { useSelector } from "react-redux";
import Notfound from "./Components/Pages/Error";
import Privacypolicy from "./Components/Pages/Privacypolicy";
import Terms from "./Components/Pages/Terms";

const Main = () => {
  const auth = useSelector((state) => state?.login);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/jobopportunities" element={<JobOpportunities />} />
        <Route path="/Contactus" element={<ContactUs />} />
        <Route path="/BrowseCompanies" element={<BrowseCompanies />} />
        <Route path="/BrowseSub" element={<BrowseSub />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/BlogDetails/:id" element={<BlogDetails />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/Jobdetails/:id" element={<JobDetails />} />
        <Route path="/Policy" element={<Privacypolicy />} />
        <Route path="/Terms" element={<Terms />} />
        <Route
          path="/adminlogin"
          element={
            !auth?.isLoggedIn ? (
              <Adminlogin />
            ) : (
              <Navigate to="/admin/admin-list" replace />
            )
          }
        />
        <Route
          path="/admin/*"
          element={
            auth?.isLoggedIn ? (
              <Layout />
            ) : (
              <Navigate to="/adminlogin" replace />
            )
          }
        />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Main;
