import React, { useEffect, useState } from "react";
import {
  NonEmptyValidation,
  LengthValidation,
  StringValidation,
  NumberValidation,
  StringRegex,
  NumberRegex,
} from "../../../Utils/validate";

import { useNavigate, useParams } from "react-router-dom";
import {
  Categorylist,
  Companylist,
  CreateBlogs,
  CreateCategory,
  CreateCompany,
  CreateJobs,
  CreateMasterData,
  MasterDatalist,
  SpecficJobs,
  UpdateJobs,
  UploadFile,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg, warningMsg } from "../../../Utils/Toastify";
import { CloudFrontUrl, EditorOption } from "../../../Utils/utils";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
const UpdateJob = () => {
  const [CreateBlog, setCreateBlog] = useState({});

  const [validation, setValidation] = useState({});
  const [JobCategoryList, setJobCategoryList] = useState([]);
  const [JobTypeList, setJobTypegoryList] = useState([]);
  const [CompanyList, setCompanyList] = useState([]);
  const [salaryRange, setSalaryRange] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [JobExperience, setJobExperience] = useState([]);


  const navigate = useNavigate();

  let htmlToDraft = null;
  if (typeof window === "object") {
    htmlToDraft = require("html-to-draftjs").default;
  }
  //Job Tittle Category
  const { id } = useParams();

  const { token } = useSelector((state) => state?.login);
  const GetSpecificData = async () => {
    const response = await SpecficJobs(id, token);
    console.log(response);
    if (response && response.statusCode === 200) {
      let dummy = response?.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.jobDescription) {
        let blocksFromHtml = htmlToDraft(dummy.jobDescription);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.jobDescription = cmsDescription;
      setCreateBlog(dummy);
    }
  };
  useEffect(() => {
    GetSpecificData();
  }, [id]);
  const GetData = async () => {
    const response = await MasterDatalist("pending_approved", token);

    if (response && response.statusCode === 200) {
      setJobCategoryList(
        response?.data
          .filter((a) => a.masterType === "jobTitle")
          .map((m) => {
            return {
              _id: m._id,
              value: m.masterValue,
              label: m.masterValue,
            };
          })
      );
    }
  };
  useEffect(() => {
    GetData();
  }, []);
  //job type
  const GetJobType = async () => {
    const response = await MasterDatalist("pending_approved", token);
    if (response && response.statusCode === 200) {
      setJobTypegoryList(
        response?.data
          .filter((a) => a.masterType === "jobType")
          .map((m) => {
            return {
              _id: m._id,
              value: m.masterValue,
              label: m.masterValue,
            };
          })
      );
    }
  };
  const GetDataExperience = async () => {
    const response = await MasterDatalist("pending_approved", token);

    if (response && response.statusCode === 200) {
      setJobExperience(
        response?.data
          .filter((a) => a.masterType === "experience")
          .map((m) => {
            return {
              _id: m._id,
              value: m.masterValue,
              label: m.masterValue,
            };
          })
      );
    }
  };
  const Getcompany = async () => {
    const response = await Companylist(token);
    if (response && response.statusCode === 200) {
      setCompanyList(
        response?.data
          .filter((a) => a.status === "active")
          .map((m) => {
            return {
              _id: m._id,
              value: m.name,
              label: m.name,
            };
          })
      );
    }
  };
  const GetsalaryRange = async () => {
    const response = await MasterDatalist("pending_approved", token);
    if (response && response.statusCode === 200) {
      setSalaryRange(
        response?.data
          .filter((a) => a.masterType === "salaryRange")
          .map((m) => {
            return {
              _id: m._id,
              value: m.masterValue,
              label: m.masterValue,
            };
          })
      );
    }
  };
  const Getqualification = async () => {
    const response = await MasterDatalist("pending_approved", token);
    if (response && response.statusCode === 200) {
      setQualification(
        response?.data
          .filter((a) => a.masterType === "qualification")
          .map((m) => {
            return {
              _id: m._id,
              value: m.masterValue,
              label: m.masterValue,
            };
          })
      );
    }
  };
  useEffect(() => {
    GetJobType("pending_approved");
    Getcompany();
    GetDataExperience("pending_approved")
    GetsalaryRange("pending_approved");
    Getqualification("pending_approved");
  }, []);

  //validation
  const setData = (e, key) => {
    setCreateBlog({ ...CreateBlog, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state, key) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setData(html, "jobDescription");
    }
  };

  const CreateableSelect = async (val) => {
    console.log(val, "val");
    if (!StringRegex.test(val)) {
      warningMsg("Please enter valid Job Tittle");

      return;
    }

    const Payload = {
      masterType: "jobTitle",
      masterValue: val,
    };
    const response = await CreateMasterData(Payload, token);
    if (response && response.statusCode === 200) {
      GetData();
    } else {
      errorMsg(response?.message);
    }
  };
  const CreattypeSelect = async (val) => {
    if (!StringRegex.test(val)) {
      warningMsg("Please enter valid room type");

      return;
    }

    const Payload = {
      masterType: "jobType",
      masterValue: val,
    };
    const response = await CreateMasterData(Payload, token);
    if (response && response.statusCode === 200) {
      GetJobType("pending_approved");
    } else {
      errorMsg(response?.message);
    }
  };
  const CreattypeSelectQualification = async (val) => {
  

    const Payload = {
      masterType: "qualification",
      masterValue: val,
    };
    const response = await CreateMasterData(Payload, token);
    if (response && response.statusCode === 200) {
      Getqualification("pending_approved");
    } else {
      errorMsg(response?.message);
    }
  };

  const CreateableSelectExperience = async (val) => {
    console.log(val, "val");
    if (!NumberRegex.test(val)) {
      warningMsg("Please enter valid Experience");

      return;
    }

    const Payload = {
      masterType: "experience",
      masterValue: val + "years",
    };
    const response = await CreateMasterData(Payload, token);
    if (response && response.statusCode === 200) {
      GetDataExperience();
    } else {
      errorMsg(response?.message);
    }
  };
  const CreattypeSelectSalaryRange = async (val) => {
  
   

    const Payload = {
      masterType: "salaryRange",
      masterValue: val ,
    };
    const response = await CreateMasterData(Payload, token);
    if (response && response.statusCode === 200) {
      GetsalaryRange();
    } else {
      errorMsg(response?.message);
    }
  };

  const handleSubmit = async () => {
    let validate = {};

    validate.jobTypeId = NonEmptyValidation(CreateBlog?.jobTypeId);
    validate.jobTitleId = NonEmptyValidation(CreateBlog?.jobTitleId);
    validate.qualificationId = NonEmptyValidation(CreateBlog?.qualificationId);
    validate.salaryRangeId = NonEmptyValidation(CreateBlog?.salaryRangeId);
    validate.companyId = NonEmptyValidation(CreateBlog?.companyId);
    validate.experienceId = NonEmptyValidation(CreateBlog?.experienceId);

    validate.jobDescription = NonEmptyValidation(
      CreateBlog?.jobDescription?.getCurrentContent()?.getPlainText().trim()
    );
    validate.lastApplyDate = NonEmptyValidation(CreateBlog?.lastApplyDate);

    validate.location = NonEmptyValidation(CreateBlog?.location);

    setValidation(validate);

    console.log(validate, "validate");
    let isNotEmpty =
      CreateBlog.jobDescription &&
      CreateBlog.jobDescription.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(
        convertToRaw(CreateBlog.jobDescription.getCurrentContent())
      );

      if (Object.values(validate).every((v) => v.status === true)) {
        const Payload = {
          _id: id,
          jobTypeId: CreateBlog.jobTypeId,
          jobTitleId: CreateBlog.jobTitleId,
        experienceId: CreateBlog.experienceId,

          qualificationId: CreateBlog.qualificationId,
          salaryRangeId: CreateBlog.salaryRangeId,
          companyId: CreateBlog.companyId,

          jobDescription: html,
          lastApplyDate: CreateBlog.lastApplyDate,
          location: CreateBlog.location,
        };
        console.log(Payload, "Payload");
        const response = await UpdateJobs(Payload, token);
        if (response && response.statusCode === 200) {
          successMsg(response?.message);

          setTimeout(() => {
            navigate(-1);
          }, 1000);
        } else {
          errorMsg(response?.message);
        }
      }
    }
  };

  return (
    <div>
      <div className="jobDescription-header">
        <p>
          <span>
            <i
              class="fa-regular fa-circle-left me-3"
              onClick={() => navigate(-1)}
            />
          </span>
          Job Update
        </p>
       
      </div>
      <div className="content-body">
        <div className="row gx-5">
          {/* <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Company Logo <span className="hash">*</span>
              </label>

              <input
                type="file"
                className="input-file"
                onChange={handleFileChangeBlog}
                accept="image/png,image/webp"
              />

              {blogfile?.length > 0 && (
                <img
                  width={"100px"}
                  height={"100px"}
                  src={blogfile}
                  alt="Uploaded"
                />
              )}

              <small className="validation-error error-space">
                {validation?.companyLogo?.message
                  ? ` Company Logo ${validation?.companyLogo?.message}`
                  : ""}
              </small>
            </div>
          </div> */}
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Job Title<span className="hash">*</span>
              </label>

              <CreatableSelect
                isClearable
                // isMulti
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: "49px",
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    zIndex: 999,
                  }),
                }}
                placeholder="Choose Job Role"
                options={JobCategoryList}
                onChange={(e) => {
                  setData(e?._id, "jobTitleId");
                }}
                value={JobCategoryList.find(
                  (temp) => temp._id === CreateBlog?.jobTitleId
                )}
                onCreateOption={CreateableSelect}
              />

              <small className="validation-error error-space">
                {validation?.jobTitleId?.message
                  ? `Job Role ${validation?.jobTitleId?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Job Type<span className="hash">*</span>
              </label>

              <CreatableSelect
                isClearable
                // isMulti
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: "49px",
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    zIndex: 999,
                  }),
                }}
                placeholder="Choose  Job Type"
                options={JobTypeList}
                onChange={(e) => {
                  setData(e?._id, "jobTypeId");
                }}
                value={JobTypeList.find(
                  (temp) => temp._id === CreateBlog?.jobTypeId
                )}
                onCreateOption={CreattypeSelect}
              />

              <small className="validation-error error-space">
                {validation?.jobTypeId?.message
                  ? ` Job Type ${validation?.jobTypeId?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Job Experience<span className="hash">*</span>
              </label>

              <CreatableSelect
                isClearable
                // isMulti
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: "49px",
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    zIndex: 999,
                  }),
                }}
                placeholder="Choose  Job Experience"
                options={JobExperience}
                onChange={(e) => {
                  setData(e?._id, "experienceId");
                }}
                value={JobExperience.find(
                  (temp) => temp._id === CreateBlog?.experienceId
                )}
                onCreateOption={CreateableSelectExperience}
              />

              <small className="validation-error error-space">
                {validation?.experienceId?.message
                  ? ` Job Experience ${validation?.experienceId?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Company<span className="hash">*</span>
              </label>

              <CreatableSelect
                isClearable
                // isMulti
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: "49px",
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    zIndex: 999,
                  }),
                }}
                placeholder="Choose  Company"
                options={CompanyList}
                onChange={(e) => {
                  setData(e?._id, "companyId");
                }}
                value={CompanyList.find(
                  (temp) => temp._id === CreateBlog?.companyId
                )}
              />

              <small className="validation-error error-space">
                {validation?.companyId?.message
                  ? ` Company ${validation?.companyId?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Salary Range<span className="hash">*</span>
              </label>

              <CreatableSelect
                isClearable
                // isMulti
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: "49px",
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    zIndex: 999,
                  }),
                }}
                placeholder="Choose   Salary Range"
                options={salaryRange}
                onChange={(e) => {
                  setData(e?._id, "salaryRangeId");
                }}
                value={salaryRange.find(
                  (temp) => temp._id === CreateBlog?.salaryRangeId
                )}
                onCreateOption={CreattypeSelectSalaryRange}
              />

              <small className="validation-error error-space">
                {validation?.salaryRangeId?.message
                  ? `  Salary Range ${validation?.salaryRangeId?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label>
                Qualification<span className="hash">*</span>
              </label>

              <CreatableSelect
                isClearable
                // isMulti
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: "49px",
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    zIndex: 999,
                  }),
                }}
                placeholder="Choose Qualification"
                options={qualification}
                onChange={(e) => {
                  setData(e?._id, "qualificationId");
                }}
                value={qualification.find(
                  (temp) => temp._id === CreateBlog?.qualificationId
                )}
                onCreateOption={CreattypeSelectQualification}
              />

              <small className="validation-error error-space">
                {validation?.qualificationId?.message
                  ? ` Qualification ${validation?.qualificationId?.message}`
                  : ""}
              </small>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label className="labeltext">
                Last Apply Date <span className="hash">*</span>
              </label>
              <div>
                <input
                  type="date"
                  className="fieldinput"
                  value={moment(CreateBlog?.lastApplyDate).format("yyyy-MM-DD")}
                  onChange={(e) => {
                    setData(e.target.value, "lastApplyDate");
                  }}
                />
              </div>
              <small className="validation-error error-space">
                {validation?.lastApplyDate?.message
                  ? ` Last Apply Date ${validation?.lastApplyDate?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column create-input">
              <label className="labeltext">
                Location <span className="hash">*</span>
              </label>
              <div>
                <input
                  type="text"
                  className="fieldinput"
                  placeholder="Enter Location"
                  value={CreateBlog?.location}
                  onChange={(e) => {
                    setData(e.target.value, "location");
                  }}
                  onBlur={(e) => {
                    setValidationValue(
                      "location",
                      NonEmptyValidation(e.target.value)
                    );
                  }}
                />
              </div>
              <small className="validation-error error-space">
                {validation?.location?.message
                  ? `Location ${validation?.location?.message}`
                  : ""}
              </small>
            </div>
          </div>

          <div className=" col-lg-12 field">
            <div className="form">
              <label>
                Job Description<span className="hash">*</span>
              </label>
              <br />
              <div className="fieldinput">
                <Editor
                  toolbar={EditorOption}
                  editorState={CreateBlog?.jobDescription}
                  onEditorStateChange={(e) => setData(e, "jobDescription")}
                />
              </div>

              <small className="validation-error error-space">
                {validation?.jobDescription?.message
                  ? `Job Description ${validation?.jobDescription?.message}`
                  : ""}
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
           <button
          className="admin-btn"
          onClick={() => {
            handleSubmit();
          }}
        >
          Update
        </button>
        </div>

       
      </div>
    </div>
  );
};

export default UpdateJob;
