import React, { useEffect, useState } from "react";
import Logo from "../../Assets/Pictures/logo.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faKey, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "../../Store.js/LoginSlice";
import { successMsg } from "../../Utils/Toastify";
import { ImageBaseUrl, ProfileGet } from "../../Repository/Api";
import ProfileImage from "../../Assets/image/fakeProfile.png"
const Header = () => {
  const { token } = useSelector((state) => state?.login);
  const { userId } = useSelector((state) => state?.login);
  const [updateAdmin, setupdateAdmin] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const GetData = async () => {
    const response = await ProfileGet(userId, token);
    if (response && response.statusCode === 200) {
      setupdateAdmin(response?.data);
    }
  };
  useEffect(() => {
    GetData();
  }, [userId, token]);

  return (
    <div className="admin-header">
      <div className="logo">
        <img src={Logo} alt="logo" onClick={() => window.location.reload()} />
      </div>
      <div class="dropdown">
        <div
          class="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="profile">
            <div className="myprofile">
              <div className="uploadprofile">
                {console.log(updateAdmin?.profileImage?.length,"updateAdmin?.profileImage?.length")}
                {updateAdmin?.profileImage?.length === 0 ? (
                  <img
                  width={"50px"}
                  height={"50px"}
                  src={ProfileImage}
                  alt="logo"
                />
                ) : (
                 
                  <img
                  width={"50px"}
                  height={"50px"}
                  src={ImageBaseUrl + updateAdmin?.profileImage}
                  alt="logo"
                />
                )}
              </div>
            </div>
          </div>
        </div>
        <ul class="dropdown-menu admindrop">
          <li onClick={() => navigate("/admin/Profile")}>
            <FontAwesomeIcon icon={faUser} className="me-2" />
            My Profile
          </li>
          <li onClick={() => navigate("/admin/change-new-password")}>
            <FontAwesomeIcon icon={faKey} className="me-2" />
            Change Password
          </li>
          <li
            onClick={(e) => {
              Swal.fire({
                title: "Are you sure?",

                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "rgb(31 37 59)",
                cancelButtonColor: "#E7424B",
                confirmButtonText: "Yes, logout",
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(logoutSuccess());
                  successMsg("Successfully Logout");
                  setTimeout(() => {
                    navigate("/");
                  }, 1000);
                }
              });
            }}
          >
            <FontAwesomeIcon icon={faUnlock} className="me-2" />
            Logout
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
