import React, { useState } from "react";
import Image from "../../Assets/Pictures/login-img.png";
import { EmailValidation, PasswordValidation } from "../../Utils/validate";
import { AdminLogin } from "../../Repository/Api";
import { errorMsg, successMsg } from "../../Utils/Toastify";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../Store.js/LoginSlice";
import { useNavigate } from "react-router-dom";
const Adminlogin = () => {
  const [login, setLogin] = useState({});
  const [validation, setValidation] = useState({});
  const [password, setPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setValues = (e, key) => {
    setLogin({ ...login, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const togglepassword = () => {
    setPassword(!password);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const validate = {};
    validate.email = EmailValidation(login.email);
    validate.password = PasswordValidation(login.password);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const payload = {
        emailId: login.email?.toLowerCase(),
        password: login.password,
      };
      const response = await AdminLogin(payload);
      console.log(response);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setLoading(false);
        localStorage.setItem("userId", response?.data?._id);
        localStorage.setItem("token", response?.data?.accessToken);
        dispatch(loginSuccess(response?.data));
        setTimeout(() => {
          navigate("/admin/admin-list");
        }, 1000);
        console.log(response);
      } else {
        errorMsg(response.message);
        setLoading(false);
      }
    }
  };

  return (
    <div className="adminlogin">
      <div className="container">
        <div className="loginlayout">
          <div className="row">
            <div className="col-lg-6">
              <div className="login-imgs">
                <img src="./images/logo.png" />
                <img src={Image} />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <p className="">Admin Login</p>
                <div className="logininput">
                  <span>
                    <i class="fa-regular fa-envelope"></i>
                  </span>
                  <input
                    type="email"
                    placeholder="Enter Email Id"
                    value={login.email}
                    onChange={(e) => setValues(e.target.value, "email")}
                  />
                  <small className="validation-error">
                    {validation?.email?.message
                      ? `Email Id ${validation?.email?.message}`
                      : ""}
                  </small>
                </div>
                <div className="logininput">
                  <span>
                    <i class="fa-solid fa-key"></i>
                  </span>
                  <input
                    type={password ? "text" : "password"}
                    placeholder="Enter Password"
                    value={login.password}
                    onChange={(e) => setValues(e.target.value, "password")}
                  />
                  {password ? (
                    <i class="fa-solid fa-eye" onClick={togglepassword}></i>
                  ) : (
                    <i
                      class="fa-solid fa-eye-slash"
                      onClick={togglepassword}
                    ></i>
                  )}
                  <small className="validation-error">
                    {validation?.password?.message
                      ? `Password ${validation?.password?.message}`
                      : ""}
                  </small>
                </div>
                <button className="login-btn" onClick={handleSubmit}>
                  Login
                </button>
                <p
                  className="forgot-password"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adminlogin;
