import "./App.css";
import Main from "./Main";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div>
      <Main />
      <ToastContainer />
    </div>
  );
}

export default App;
