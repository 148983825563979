import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import "./JobOpportunities.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faLocationDot,
  faMagnifyingGlass,
  faArrowRight,
  faArrowLeft,
  faBriefcase,
  faSquarePollHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import {
  BlogGet,
  ImageBaseUrl,
  MasterDatalist,
  MasterDatalistwithouttoken,
  jobGet,
  jobGetFilter,
  jobHome,
} from "../../Repository/Api";
import { useSelector } from "react-redux";
import { truncateText } from "../../Utils/utils";
import { useNavigate } from "react-router-dom";
import ExpandableText from "../Typography/ExpandableText";

const JobOpportunities = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  const [showPage1, setShowPage1] = useState(true);
  const [showPage2, setShowPage2] = useState(false);
  const [JobTypeList, setJobTypegoryList] = useState([]);

  const handleClick1 = () => {
    // console.log(showPage1);
    setShowPage1(showPage1);
    // console.log(showPage2);
    // setShowPage2(!showPage2);
  };
  const handleClick2 = () => {
    // setShowPage1(!showPage1);
    setShowPage2(!showPage2);
  };

  return (
    <div>
      <div>
        <Navbar />
      </div>

      <div className="jobbanner text-center pt-5 pb-5">
        <h1>
          Find Your <span>Dream Job</span>
        </h1>
        {/* <p className="mt-4">
          Find your next career at companies like HubSpot, Nike, and Dropbox
        </p> */}
        {/*  */}
        {/* <div className="mt-4 jobsearch ">
          <div className="container">
            <div className="row justify-content-center gx-0 searchblog">
              <div className="col-lg-5 col-12 col-md-12">
                <div className="row d-flex  justify-content-center">
                  <div className="col-lg-5  col-12 brdrght col-md-7">
                    <div className="jobcat">
                      <div className="">
                        <FontAwesomeIcon
                          icon={faClipboardList}
                          className="jobcategoryicon"
                        />
                      </div>
                      <div className="inp1">
                        <input
                          type="text"
                          placeholder="Category"
                          // style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12 col-md-7 p-0">
                    <div className="jobloc">
                      <div className="d-flex searchrgt">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="joblocicon"
                        />

                        <div className="inp2">
                          <input type="text" placeholder="Location" />
                        </div>
                      </div>

                      <div className="jobsearch ">
                        <button className="jobsrcbtn">
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="jobsicon"
                          />
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/*  */}

      <div className="pagesec mt-4">
        {/* <div className="findjobs">
          <p href="#JobOpportunities">Find Jobs</p>
        </div> */}
        {/* <div className="BrowseComp ms-5">
          <a href="#BrowseCompanies ">Browse Companies</a>
        </div> */}
      </div>
      {showPage1 && <Findjobs />}
      {/* {showPage2 && <BrowseCompanies />} */}

      {/* FOOTER */}
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

function Findjobs() {
  const scrollToTop = () => {
    window.scrollTo(400, 400);
  };
  const [blogPopularList, setBlogPopularList] = useState([]);
  const [JobFilter, setJobFilter] = useState([]);

  const navigate = useNavigate();

  const [jobTypeIds, setJobTypeIds] = useState([]);

  const GetData = async () => {
    const payload = {
      jobTypeId: jobTypeIds,
    };

    const response = await jobGet(payload);

    if (response && response.statusCode === 200) {
      setBlogPopularList(
        response?.data?.filter((blog) => blog?.status === "active")
      );
    } else {
      setBlogPopularList([]);
    }
  };

  const GetDataFilterList = async (jobTypeId) => {
    let array = [...jobTypeIds];
    let index = array.findIndex((a) => a === jobTypeId);
    if (index >= 0) {
      onPageChange(1);
      array.splice(index, 1);

    } else {
      onPageChange(1);
      array.push(jobTypeId);
    }

    const payload = {
      jobTypeId: array,
    };

    const response = await jobGet(payload);

    if (response && response.statusCode === 200) {

      setBlogPopularList(
        response?.data?.filter((blog) => blog?.status === "active")
      );
    } else {
      setBlogPopularList([]);
    }
    setJobTypeIds(array);
  };

  const GetDataFilter = async () => {
    const payload = {
      jobTypeIds: [],
    };
    const response = await jobGetFilter(payload);
    if (response && response.statusCode === 200) {

      setJobFilter(response?.data?.jobType);
      console.log(response?.data?.jobType, "197");
    } else {
      setJobFilter([]);
    }
  };


  useEffect(() => {
    GetDataFilter();
    GetData();
  }, []);
  const [currentPage, setCurrentPage] = useState(2);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const itemsPerPage = 6;

  useEffect(() => {
    onPageChange(1);

  }, []);
  const totalPages = Math.ceil((blogPopularList?.length || 0) / itemsPerPage);

  return (
    <div>
      <div className="container">
        <div className="alljobs mt-5">
          <div className="container">
            <div className="row">
              {/* Type of employee */}
              <div className="col-lg-3">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                      // data-bs-toggle="collapse"
                      // data-bs-target="#collapseTwo"
                      >
                        Type Of Employment
                      </button>
                    </h2>
                    {/* <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    > */}
                    {JobFilter?.filter((item) => item.count > 0)?.map(
                      (item, index) => (
                        <div className="accordion-body" key={index}>
                          <div className="typeofemp">
                            <input
                              type="checkbox"
                              className="check"
                              id={`checkbox_${index}`}
                              checked={jobTypeIds.includes(item?._id)}
                              onChange={() => GetDataFilterList(item?._id)}
                            />
                            {console.log(item?._id)}
                            <label
                              htmlFor={`checkbox_${index}`}
                              className="ps-2 checklabel pt-0"
                            >
                              {item?.value}({item?.count})
                            </label>
                          </div>
                        </div>
                      )
                    )}

                    {/* </div> */}
                  </div>
                </div>
                {/* CATEGORIES */}
                {/* <div class="accordion" id="accordionExample">
                  <div class="accordion-item mt-4">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Categories
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="typeofemp">
                          <input type="checkbox" className="check" />
                          <label
                            for="vehicle1"
                            className="ps-2 checklabel pt-0"
                          >
                            Design (24)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            Sales (3)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            Marketing (3)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            Business (3)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            Human Resource (6)
                          </label>
                          <div className="typeofemp mt-2">
                            <input type="checkbox" className="check" />
                            <label for="vehicle1" className="ps-2 checklabel">
                              Finance (4)
                            </label>
                          </div>
                          <div className="typeofemp mt-2">
                            <input type="checkbox" className="check" />
                            <label for="vehicle1" className="ps-2 checklabel">
                              Engineering (4)
                            </label>
                          </div>
                          <div className="typeofemp mt-2">
                            <input type="checkbox" className="check" />
                            <label for="vehicle1" className="ps-2 checklabel">
                              Technology (5)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* JOB LEVEL */}
                {/* <div class="accordion" id="accordionExample">
                  <div class="accordion-item mt-4">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Job Level
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="typeofemp">
                          <input type="checkbox" className="check" />
                          <label
                            for="vehicle1"
                            className="ps-2 checklabel pt-0"
                          >
                            Entry Level (57)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            Mid Level (3)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            Senior Level (5)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            Director (12)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            VP or Above (8)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* SALARY RANGE */}
                {/* <div class="accordion" id="accordionExample">
                  <div class="accordion-item mt-4">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Salary Range
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="typeofemp">
                          <input type="checkbox" className="check" />
                          <label
                            for="vehicle1"
                            className="ps-2 checklabel pt-0"
                          >
                            $700 - $1000 (4)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            $100 - $1500 (6)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            $1500 - $2000 (10)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            $3000 or above (4)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* RIGHTSIDE STARTS */}
              <div className="col-lg-9">
                <div className="righttop">
                  <div className="alljob">
                    <h4>All Jobs</h4>
                    {/* <p>Showing 73 results</p> */}
                  </div>
                  {/* <div className="sortby ">
                    Sort By:
                    <select className="ms-3 sortdrop">
                      <option value="input"> Most Revelant Jobs</option>
                      <option value="description"> Most Search Jobs</option>
                      <option value="packing"> High Paid Jobs </option>
                    </select>
                  </div> */}
                </div>
                {/* RIGHTBOX */}
                {
                  // blogPopularList?.slice(
                  //   (currentPage - 1) * itemsPerPage,
                  //   currentPage * itemsPerPage
                  // )?.map((items, index) => (
                  blogPopularList
                    ?.slice()
                    .reverse()
                    ?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                    ?.map((items, index) => (
                      <div className="mt-4" key={index}>
                        <div className="jobbox1 ">
                          <div className=" alljobsjob">
                            <div className="jobjpg">
                              <img
                                src={ImageBaseUrl + items?.companyLogo}
                                alt="..."
                              />
                            </div>
                            <div className="ms-3">
                              <h5>{items?.company}</h5>
                              <p>{items?.location}</p>
                            </div>
                          </div>
                          <div className="jobcont mt-2">
                            <h5>{items?.jobTitle}</h5>
                            <div className="mt-4">
                              <span className="fulltmbox1">{items?.jobType}</span>
                              {/* <span className="fulltmbox2 ">On Site</span> */}
                              <span className="fulltmbox3 ">
                                {items?.salaryRange}
                              </span>
                            </div>
                          </div>
                          <div className="container-fluid px-1 mt-3">
                            <div className="row">
                              <div className="col-lg-9">
                                <div>
                                  {/* <p className="recentcontpara">
                                <div
                                  className="faq-body"
                                  dangerouslySetInnerHTML={{
                                    __html: truncateText(items?.jobDescription, 10),
                                  }}
                                />
                              </p> */}
                                  <ExpandableText
                                    className="faq-body"
                                    maxLength={150}
                                    text={items?.jobDescription}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="viejob ">
                                  <div
                                    // href="..."
                                    onClick={() =>
                                      navigate(`/JobDetails/${items?._id}`)
                                    }
                                    className="allblogread text-end"
                                  >
                                    View Job Details
                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>

                          {/* <div className="mobres">
       <button className="jobappbtn">Apply</button>
       <div className="progress mt-2">
         <div
           className="progress-bar"
           role="progressbar"
           style={{ width: "50%" }}
         ></div>
       </div>
       <p className="mt-1">
         <strong>5 applied </strong>of 10 capacity
       </p>
     </div> */}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
        {/* pagination */}
        {blogPopularList?.length > 0 && (
          <div>
            <nav aria-label="Page navigation example ">
              <ul class="pagination justify-content-center mt-5">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                  // onClick={() => onPageChange(currentPage - 1)}
                  // disabled={currentPage === 1}
                  onClick={() => {
                    if (currentPage !== 1) {
                      onPageChange(currentPage - 1);
                      scrollToTop();
                    }
                  }}
                >
                  <a
                    class="page-link"
                  // onClick={() => {
                  //   onPageChange(currentPage - 1);
                  //   scrollToTop();
                  // }}
                  // disabled={currentPage === 0}
                  >
                    Previous
                  </a>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    class="page-item"
                    key={index}
                    onClick={() => {
                      onPageChange(index + 1);
                      scrollToTop();
                    }}
                  >
                    <a
                      class="page-link"
                      style={{
                        backgroundColor:
                          currentPage === index + 1 ? " #10bea9" : "#fff",
                        color: currentPage === index + 1 ? " white" : "#000",
                      }}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}

                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                  // onClick={() => {
                  //   onPageChange(currentPage + 1);
                  //   scrollToTop();
                  // }}
                  // disabled={currentPage === totalPages}
                  onClick={() => {
                    if (currentPage !== totalPages) {
                      onPageChange(currentPage + 1);
                      scrollToTop();
                    }
                  }}
                >
                  <a class="page-link">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
}
function BrowseCompanies() {
  return (
    <div className="reccompanies mt-5 ">
      <div className="container">
        <div className="rechead">
          <h4>Recommended Companies</h4>
          <p>Based on your profile, company preferences, and recent activity</p>
        </div>
        <div className="row">
          <div className="col-lg-4  mt-4">
            <div className="reccombox1">
              <div className=" recboxtop">
                <img src="./images/alljoblog1.png" alt="..." />
                <p>3 jobs</p>
              </div>
              <h5 className="mt-3">Nomad</h5>
              <p className="mt-3">
                Nomad is located in Paris, France. Nomad has generates $728,000
                in sales (USD).
              </p>
              <p2 className="business">Business Service</p2>
            </div>
          </div>
          <div className="col-lg-4  mt-4">
            <div className="reccombox1">
              <div className=" recboxtop">
                <img src="./images/alljoblog1.png" alt="..." />
                <p>3 jobs</p>
              </div>
              <h5 className="mt-3">Discord</h5>
              <p className="mt-3">
                We'd love to work with someone like you. We care about creating
                a delightful experience.
              </p>
              <p2 className="business">Business Service</p2>
            </div>
          </div>
          <div className="col-lg-4  mt-4">
            <div className="reccombox1">
              <div className=" recboxtop">
                <img src="./images/alljoblog1.png" alt="..." />
                <p>3 jobs</p>
              </div>
              <h5 className="mt-3">Maze</h5>
              <p className="mt-3">
                We're a passionate bunch working from all over the world to
                build the future of rapid testing together.
              </p>
              <p2 className="business">Business Service</p2>
            </div>
          </div>
          {/* secondrow */}
          <div className="col-lg-4 mt-4">
            <div className="reccombox1">
              <div className=" recboxtop">
                <img src="./images/alljoblog1.png" alt="..." />
                <p>3 jobs</p>
              </div>
              <h5 className="mt-3">Udacity</h5>
              <p className="mt-3">
                Udacity is a new type of online university that teaches the
                actual programming skills.
              </p>
              <p2 className="business">Business Service</p2>
            </div>
          </div>
          <div className="col-lg-4 mt-4">
            <div className="reccombox1">
              <div className=" recboxtop">
                <img src="./images/alljoblog1.png" alt="..." />
                <p>3 jobs</p>
              </div>
              <h5 className="mt-3">Webflow</h5>
              <p className="mt-3">
                Webflow is the first design and hosting platform built from the
                ground up for the mobile age.
              </p>
              <div>
                <p2 className="business">Business Service</p2>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-4">
            <div className="reccombox1">
              <div className=" recboxtop">
                <img src="./images/alljoblog1.png" alt="..." />
                <p>3 jobs</p>
              </div>
              <h5 className="mt-3">Foundation</h5>
              <p className="mt-3">
                Foundation helps creators mint and auction their digital
                artworks as NFTs on the Ethereum blockchain.
              </p>
              <p2 className="business">Business Service</p2>
              <p3 className="tech ms-3">Crypto</p3>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div>
        <div className="compbycat">
          <div className="container mt-5">
            <div className="compbycathead">
              <h4>Companies by Category</h4>
              <a href="..">
                <FontAwesomeIcon icon={faArrowRight} className="scrollicon" />
              </a>
            </div>

            <div className="scroll mt-4">
              <div className="scrollowlback">
                <div className="container  part2 text-center ">
                  <div className="row">
                    <div className="col-md-12 owlbox">
                      <OwlCarousel
                        className="owl-theme"
                        loop
                        autoplay={true}
                        items="2"
                        dots={true}
                        smartSpeed={2000}
                        nav={false}
                        margin={20}
                        center={true}
                        autoplayTimeout={5000}
                        responsive={{
                          360: {
                            items: "1",
                          },
                          414: {
                            items: "2",
                          },
                          670: {
                            items: "3",
                          },
                          992: {
                            items: "4",
                          },
                          1200: {
                            items: "5",
                          },
                        }}
                      >
                        <div class="scrollitem ">
                          <div>
                            <a href="..">
                              <FontAwesomeIcon
                                icon={faBriefcase}
                                className="owlscrollicon mt-1 mt-1"
                              />
                            </a>
                            <p className="mt-2">Design</p>
                          </div>
                        </div>
                        <div class="scrollitem ">
                          <a href="..">
                            <FontAwesomeIcon
                              icon={faBriefcase}
                              className="owlscrollicon mt-1"
                            />
                          </a>
                          <p className="mt-2">Fintech</p>
                        </div>
                        <div class="scrollitem ">
                          <a href="..">
                            <FontAwesomeIcon
                              icon={faBriefcase}
                              className="owlscrollicon mt-1"
                            />
                          </a>
                          <p className="mt-2">Hosting</p>
                        </div>
                        <div class="scrollitem ">
                          <a href="..">
                            <FontAwesomeIcon
                              icon={faBriefcase}
                              className="owlscrollicon mt-1"
                            />
                          </a>
                          <p className="mt-2">Business Service</p>
                        </div>
                        <div class="scrollitem ">
                          <a href="..">
                            <FontAwesomeIcon
                              icon={faBriefcase}
                              className="owlscrollicon mt-1"
                            />
                          </a>
                          <p className="mt-2">Developer</p>
                        </div>
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}

      <div className="container mt-5">
        <div className="d-flex">
          <a href="..">
            <FontAwesomeIcon
              icon={faSquarePollHorizontal}
              className="resulticon"
              style={{ color: "#12c0ab" }}
            />
          </a>
          <h5 className="results ms-3">24 Results</h5>
        </div>
        {/*  */}
        <div className="resultbox24">
          <div className="row ">
            <div className="col-lg-3 text-center mt-4">
              <div className="resulbox">
                <img src="./images/resultjp1.png" alt="..." />
                <h6 className="mt-3">Pentagram</h6>
                <p className="mt-3">
                  <span>3 jobs</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-center mt-4">
              <div className="resulbox">
                <img src="./images/resultjp2.png" alt="..." />
                <h6 className="mt-3">Wolff Olins</h6>
                <p className="mt-3">
                  <span>3 jobs</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-center mt-4">
              <div className="resulbox">
                <img src="./images/resultjp3.png" alt="..." />
                <h6 className="mt-3">Clay</h6>
                <p className="mt-3">
                  <span>3 jobs</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-center mt-4">
              <div className="resulbox">
                <img src="./images/resultjp4.png" alt="..." />
                <h6 className="mt-3">MediaMonks</h6>
                <p className="mt-3">
                  <span>3 jobs</span>
                </p>
              </div>
            </div>
            {/*  */}
            <div className="col-lg-3 text-center mt-4">
              <div className="resulbox">
                <img src="./images/resultjp5.png" alt="..." />
                <h6 className="mt-3">Packer</h6>
                <p className="mt-3">
                  <span>3 jobs</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-center mt-4">
              <div className="resulbox">
                <img src="./images/resultjp6.png" alt="..." />
                <h6 className="mt-3">Square</h6>
                <p className="mt-3">
                  <span>3 jobs</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-center mt-4">
              <div className="resulbox">
                <img src="./images/resultjp7.png" alt="..." />
                <h6 className="mt-3">Divy</h6>
                <p className="mt-3">
                  <span>3 jobs</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-center mt-4">
              <div className="resulbox">
                <img src="./images/resultjp8.png" alt="..." />
                <h6 className="mt-3">WebFlow</h6>
                <p className="mt-3">
                  <span>3 jobs</span>
                </p>
              </div>
            </div>
            {/* viewmore */}
            <div className="viewmorecomp mt-4">
              <a href="...">View more Design companies</a>{" "}
              <FontAwesomeIcon
                icon={faArrowRight}
                className=" ms-3 viewmorecompicon"
                style={{ color: "#12c0ab" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default JobOpportunities;
