import React from "react";
import { Link, NavLink } from "react-router-dom";
import { menu } from "./Sidebarmenu";
const Sidebar = () => {
  return (
    <div className="sidebar">
      <ul>
        {menu?.map((item, i) => {
          return (
            <li key={i}>
              <NavLink to={item?.path}>
                <div
                  className={
                    window.location.href.includes("/admin/admin-list")
                      ? "sidebar-menuInActive"
                      : "sidebar-menuActive"
                  }
                >
                  <span>{item?.icon}</span>
                  <p className="mb-0">{item?.name}</p>
                </div>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
