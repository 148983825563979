import React, { useEffect, useRef, useState } from "react";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../Utils/validate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { errorMsg, successMsg, warningMsg } from "../../Utils/Toastify";
import Imgupload from "../../Assets/Pictures/custom-img-upload.png";
import { useNavigate } from "react-router-dom";
import {
  ImageBaseUrl,
  ProfileGet,
  UpdateProfile,
  UploadFile,
} from "../../Repository/Api";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { loginStateUpadte } from "../../Store.js/LoginSlice";
const Profile = () => {
  const ProfileRef = useRef();
  const dispatch = useDispatch();

  const [updateAdmin, setupdateAdmin] = useState({});
  const [file, setFile] = useState(updateAdmin?.profileImage);
  const [validation, setValidation] = useState({});
  const navigate = useNavigate();
  const { token } = useSelector((state) => state?.login);
  const { userId } = useSelector((state) => state?.login);
  const [showPopup, setShowPopup] = useState(false);

  const handleMouseEnter = () => {
    setShowPopup(true);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  const setData = (e, key) => {
    setupdateAdmin({ ...updateAdmin, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };
  const GetData = async () => {
    const response = await ProfileGet(userId, token);
    if (response && response.statusCode === 200) {
      setupdateAdmin(response?.data);
    }
  };
  useEffect(() => {
    GetData();
  }, [userId, token]);

  const handleFileChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));

    handleUploadFile(e.target.files[0], "profileImage");
  };
  const handleUploadFile = async (fileURL, key) => {
    let formdata = new FormData();
    formdata.append("file", fileURL);
    formdata.append("path", "Blog/");

    const Response = await UploadFile(formdata);
    console.log(Response?.data?.statusCode);
    if (Response?.data?.statusCode === 200) {
      setData(Response?.data?.data?._id, key);

      successMsg(Response?.data?.message);
    } else {
      errorMsg(Response.message);
    }
  };

  console.log(file, "file");
  const handleSubmit = async () => {
    let validate = {};

    validate.firstName = StringValidation(updateAdmin?.firstName);
    validate.lastName = StringValidation(updateAdmin?.lastName);

    console.log(validate, "validate");
    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        _id: userId,
        firstName: updateAdmin.firstName,
        lastName: updateAdmin.lastName,
        profileImage: updateAdmin.profileImage,
      };
      const response = await UpdateProfile(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response?.message);
        dispatch(loginStateUpadte(response.data));

        window.location.reload();
      } else {
        errorMsg(response?.message);
      }
    }
  };

  const handleRemoveProfile = async () => {
    const payload = {
      _id: userId,

      profileImage: "",
    };

    try {
      const response = await UpdateProfile(payload, token);

      if (response && response.statusCode === 200) {
        dispatch(loginStateUpadte(response.data));
        GetData();
        successMsg(response.message);
        window.location.reload();
      } else {
        errorMsg(response.message);
      }
    } catch (error) {
      errorMsg("An error occurred while handling the profile removal.");
    }
  };
  return (
    <>
      <div className="content-header">
        <p>
          <span>
            <i
              class="fa-regular fa-circle-left me-3"
              onClick={() => navigate(-1)}
            />
          </span>
          My Profile
        </p>
        {/* <button
          className="admin-btn"
          onClick={() => {
            handleSubmit();
          }}
        >
          Update profile
        </button> */}
      </div>
      <div className="content-body">
        <div className="mt-5 profile-data">
          <p>Your Profile Picture</p>
          <div
            className="upload-and-progress d-flex justify-content-between"
            style={{ position: "relative" }}
          >
            <div className="d-flex  flex-md-row flex-column">
              <div className="d-flex">
                <div className="">
                  {[1].map((_, index) => (
                    <React.Fragment key={index}>
                      {file?.length > 0 ? (
                        <div className="myprofile">
                          <div className="uploadprofile">
                            <img
                              width={"100px"}
                              height={"100px"}
                              src={file}
                              alt="Uploaded"
                            />
                            <div
                              className=""
                              onClick={() => ProfileRef.current.click()}
                            >
                              <FontAwesomeIcon
                                icon={faUserPlus}
                                className="uploadsvg"
                              />

                              <input
                                autoComplete="off"
                                type="file"
                                name="myfile"
                                ref={ProfileRef}
                                hidden
                                onChange={handleFileChange}
                                accept="image/jpeg, image/png,image/webp"
                              />
                            </div>
                            {/* <label
                              for="customimgupload"
                              className="profile-update"
                            >
                              <FontAwesomeIcon
                                icon={faUserPlus}
                                className="uploadsvg"
                              />
                            </label> */}
                          </div>
                        </div>
                      ) : (
                        <>
                          {updateAdmin?.profileImage ? (
                            <>
                             
                            
                              <div className="myprofile">
                                <div className="uploadprofile">
                                <div className="profileremove">
                                  
                              <span
                                class="material-symbols-outlined"
                                onClick={() => handleRemoveProfile()}
                              >
                                Close
                              </span>
                              </div>
                                  <img
                                    onClick={() =>
                                      Swal.fire({
                                        imageUrl:
                                          ImageBaseUrl +
                                          updateAdmin?.profileImage,
                                        imageHeight: 350,
                                        imageAlt: "Your Profile Picture",
                                        confirmButtonColor: "rgb(31 37 59)",

                                        confirmButtonText: "Close",
                                      })
                                    }
                                    width={"100px"}
                                    height={"100px"}
                                    src={
                                      ImageBaseUrl + updateAdmin?.profileImage
                                    }
                                    alt="Uploaded"
                                  />

                                  <div
                                    className=""
                                    onClick={() => ProfileRef.current.click()}
                                  >
                                    <FontAwesomeIcon
                                      icon={faUserPlus}
                                      className="uploadsvg"
                                    />

                                    <input
                                      autoComplete="off"
                                      type="file"
                                      name="myfile"
                                      ref={ProfileRef}
                                      hidden
                                      onChange={handleFileChange}
                                      accept="image/jpeg, image/png,image/webp"
                                    />
                                  </div>
                                  {/* <label
                                    for="customimgupload"
                                    className="profile-update"
                                  >
                                    <FontAwesomeIcon
                                      icon={faUserPlus}
                                      className="uploadsvg"
                                    />
                                  </label> */}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <label htmlFor="customimgupload">
                                <div className="custom-imgupload">
                                  <img src={Imgupload} alt="custom-imgupload" />
                                  <p className="mb-0 text-center">
                                    Upload your
                                    <br />
                                    photo
                                  </p>
                                </div>
                              </label>
                              <input
                                type="file"
                                className="d-none"
                                id="customimgupload"
                                onChange={handleFileChange}
                                accept="image/jpeg, image/png, image/webp"
                              />
                            </>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="profile-form">
            <p className="h5 mt-5">Profile Info</p>
            <div className="row g-4">
              <div className="col-lg-6">
                <label>
                  Frist Name <span className="hash">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Please Enter your  First Name "
                  value={updateAdmin?.firstName}
                  onChange={(e) => {
                    setData(e.target.value, "firstName");
                  }}
                  onBlur={(e) => {
                    setValidationValue(
                      "firstName",
                      StringValidation(e.target.value)
                    );
                  }}
                />
                {console.log(validation, "validation?.firstName?.message")}
                <small className="textDanger">
                  {validation?.firstName?.message
                    ? ` First Name ${validation?.firstName?.message}`
                    : ""}
                </small>
              </div>
              <div className="col-lg-6">
                <label>
                  Last Name <span className="hash">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Last Name"
                  value={updateAdmin?.lastName}
                  onChange={(e) => {
                    setData(e.target.value, "lastName");
                  }}
                  onBlur={(e) => {
                    setValidationValue(
                      "lastName",
                      StringValidation(e.target.value)
                    );
                  }}
                />
                <small className="textDanger">
                  {validation?.lastName?.message
                    ? ` Last Name${validation?.lastName?.message}`
                    : ""}
                </small>
              </div>
              <div className="col-lg-6">
                <label>
                  E-mail Id <span className="hash">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={updateAdmin?.emailId}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <button
            className="admin-btn"
            onClick={() => {
              handleSubmit();
            }}
          >
            Update profile
          </button>
        </div>
      </div>
    </>
  );
};

export default Profile;
