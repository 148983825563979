import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import "./Aboutus.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ImageBaseUrl, jobHome, userNewsLetter } from "../../Repository/Api";
import aboutusimg from "../../Assets/images/about-us.jpeg";
import self1 from "../../Assets/images/self1.png";
import respect from "../../Assets/images/respect.png";
import determination from "../../Assets/images/determination.png";
import family from "../../Assets/images/family.png";
import ourvisleft from "../../Assets/images/ourvisleft.png";
import ourvisright from "../../Assets/images/ourvisright.png";
import ourmiss from "../../Assets/images/ourmiss.png";
import arrowleft from "../../Assets/image/arrowleft.png";
import arrowright from "../../Assets/image/arrowright (2).png";
import LazyLoad from "react-lazyload";
import { EmailValidation } from "../../Utils/validate";
import Swal from "sweetalert2";
import { errorMsg } from "../../Utils/Toastify";
const Aboutus = () => {
  const scrollToTop = () => {
    window.scrollTo(490, 490);
  };

  const [login, setLogin] = useState({});
  const [validation, setValidation] = useState({});
  const setValues = (e, key) => {
    setLogin({ ...login, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const handleSubmit = async (e) => {
    const validate = {};
    validate.emailId = EmailValidation(login.emailId);

    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const payload = {
        emailId: login.emailId,
      };
      const response = await userNewsLetter(payload);
      console.log(response);
      if (response && response.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "Thank You",
          text: "You will be receiving a newsletter via emailId",
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        errorMsg(response.message);
      }
    } else {
      errorMsg(`Email Id ${validate?.emailId?.message}`);
    }
  };
  const [data, setData] = useState([]);

  const GetData = async () => {
    try {
      const response = await jobHome();

      if (response && response.statusCode === 200) {
        setData(response?.data?.filter((a) => a.status === "active"));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    GetData();
  }, []);
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="aboutbanner text-center pt-5 pb-5">
        <h1 className="">
          <span>#1 </span>Platform For Jobs
        </h1>
        {/* <p className="mt-4">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p> */}
      </div>
      {/* owlcarosel */}
      <div className="owlback">
        <div className="container  part2 text-center ">
          <div className="row">
            <div className="col-md-12 owlbox">
              {data?.length > 0 && (
                <OwlCarousel
                  className="owl-theme"
                  loop
                  autoplay={true}
                  items="6"
                  dots={true}
                  smartSpeed={1000}
                  nav={false}
                  margin={20}
                  center={true}
                  autoplayTimeout={3000}
                  responsive={{
                    360: { items: 1 },
                    414: { items: 2 },
                    670: { items: 3 },
                    992: { items: 4 },
                    1200: { items: 6 },
                  }}
                >
                  {data?.map((item, index) => (
                    <div className="item" key={index}>
                      <img
                        src={ImageBaseUrl + item.companyLogo}
                        className="owlimage"
                        alt="logo"
                      />
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* about us img */}
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={aboutusimg}
                alt=".."
                className="aboutusimg"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-lg-6">
              <div className="aboutright">
                <h3>
                  About <span>Us</span>
                </h3>
                <p className="mt-4">
                  In today's job market, there's a lack of support from the staffing
                  industry for companies based in the Renewable Energy space.
                </p>
                <p>
                  At Infletio Consulting, we understand the unique demands of the renewable energy sector alongside GTM functionality.
                </p> <p>
                  As a dedicated staffing partner, we offer comprehensive solutions tailored to meet the workforce needs of companies involved in renewable energy projects as well as any sales org.
                </p>
                <div>
                  <button
                    className="aboutrightbtn mt-3"
                    onClick={() => {
                      navigate(`/ContactUs/`);
                      scrollToTop();
                    }}
                  >
                    Get In Touch
                    <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* OUR VALUES */}
      <div className="homeourvalues">
        <div className="container ">
          {/* <div className="row"> */}
          <div className="homevalues text-center">
            <h3 className="">
              Our <span>Values</span>
            </h3>
            <p>
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. <br />
              Lorem Ipsum has been the industry's standard */}
            </p>
          </div>
          {/* ourvalbox 1 */}
          <div className="row ">
            <div className="col-lg-6 mt-5">
              <div className="firstrow">
                <div className="selfabt">
                  <img src={self1} className="selfim" alt="self1" />
                </div>
                <div className="firstpara">
                  <h5>Selflessness:</h5>
                  <p>
                    Infletio looks to provide before retrieving. Our model guarantees that our clients requests are completed before any payment. Infletio has a community-first mentality and actively looks to give back to the community with our Non-Profit cut.
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="col-lg-6 mt-5">
              <div className="firstrow mobtop">
                <div className="selfabt">
                  <img src={respect} className="selfim" alt="self1" />
                </div>
                <div className="firstpara">
                  <h5>Respect:</h5>
                  <p>
                    Our platform provides full transparency and looks to provide job-seekers and employers with mutual respect.
                  </p>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="col-lg-6 mt-5">
              <div className="secondrow">
                <div className="selfabt ">
                  <img src={determination} className="selfim" alt="self1" />
                </div>
                <div className="secondpara">
                  <h5> Determination:</h5>
                  <p>
                    Your request is our command. We promise to work with you to get the staffing you’re looking for, before any deadline agreed upon.
                  </p>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div className="col-lg-6 mt-5">
              <div className="secondrow">
                <div className="selfabt">
                  <img src={family} className="selfim" alt="self1" />
                </div>
                <div className="secondpara">
                  <h5 className="ms">Family:</h5>
                  <p>
                    The team at Infletio treats all our relationships
                    as family. After the staffing cycle is over, we maintain
                    relationship and look to support our clients for life. From
                    letters to gifts, the Infletio family goes above and beyond.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* our mission  */}
      <div className="ourmission ">
        <div className="container text-center">
          <div className="ourmisstop">
            <h3>
              Our <span>Mission</span>
            </h3>
          </div>
          {/* box */}
          <div className="row">
            <div className="col-lg-5">
              <div className="mt-5">
                <img src={ourmiss} alt=".." style={{ width: "100%" }} />
              </div>
            </div>
            <div className="col-lg-7 mt-5">
              <div className="ourmissright">
                {/* <div className="ourmiss">
                  <div className="circle">
                    <span className="text">1</span>
                  </div>
                  <div className="ourmisspara">
                    <p>
                      Our mission at Infletio Consulting is to ensure the right people find their place in the
                      right companies, all for the betterment of our planet—spoiler alert: it's Earth.
                    </p>
                  </div>
                </div>
                <div className="ourmiss mt-5">
                  <div className="circle">
                    <span className="text">2</span>
                  </div>
                  <div className="ourmisspara">
                    <p>
                      We
                      recognize that a healthy planet benefits everyone, and we're dedicated to fulfilling our
                      role in maintaining this vision.By connecting individuals
                    </p>
                  </div>
                </div>
                <div className="ourmiss mt-5">
                  <div className="circle">
                    <span className="text">3</span>
                  </div>
                  <div className="ourmisspara">
                    <p>
                      with companies that share our
                      commitment to environmental maintenance, we aim to contribute to a sustainable
                      future for generations to come.
                    </p>
                  </div>
                </div> */}
                <div className="ourmisspara">
                  <p>
                    Our mission at Infletio Consulting is to ensure the right people find their place in the
                    right companies, all for the betterment of our planet—spoiler alert: it's Earth.  We
                    recognize that a healthy planet benefits everyone, and we're dedicated to fulfilling our
                    role in maintaining this vision.By connecting individuals with companies that share our
                    commitment to environmental maintenance, we aim to contribute to a sustainable
                    future for generations to come.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ourvision */}
      <div className="ourvision mt-5">
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-6">
              <div className="ourvisright">
                {/* <span className="ourvistop"> */}
                <h3>
                  Our <span>vision</span>
                </h3>
                <p className="mt-4 ourvispara">
                  Leading the sustainability movement with the right people for the job.
                  Providing companies with sellers that will directly impact revenue.

                </p>
                {/* </span> */}
                <div className="ourvislefttimg">
                  <img src={ourvisleft} alt="..." style={{ width: "100%" }} />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ourvisrightimg">
                <img src={ourvisright} alt="..." style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          {/*  */}
          <div className="row">
            <div className="ourvisdown d-flex">
              <div className="col-lg-4">
                <div className=" ourvisdowm1 d-flex">
                  <span>
                    <FontAwesomeIcon icon={faStar} className="fontstart" />
                  </span>
                  <div className="lorem ms-3">
                    {/* <h4>Lorem Ipsum</h4> */}
                    <p>
                      Eventually we want people to give us the nickname “Professional Cupid” for the way we set up companies with job seekers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" ourvisdowm1 d-flex">
                  <span>
                    <FontAwesomeIcon icon={faStar} className="fontstart" />
                  </span>
                  <div className="lorem ms-3">
                    {/* <h4>Lorem Ipsum</h4> */}
                    <p>
                      Starting off by contributing towards a cleaner world and eventually expanding out to other industries.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" ourvisdowm1 d-flex">
                  <span>
                    <FontAwesomeIcon icon={faStar} className="fontstart" />
                  </span>
                  <div className="lorem ms-3">
                    {/* <h4>Lorem Ipsum</h4> */}
                    <p>
                      Every jobseeker deserves to find fulfillment at their workplace, every company deserves to expand with the right team.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* lets start */}
      <div className="container text-center mt-5 let-start">
        <div className="row">
          <div className="col-lg-12 mt-5 letstart">
            <h3>

              <span className="letstarthead">Start your job hunt now!</span>
            </h3>
            <p className="mt-4">
              Join now and see what we have to offer!
            </p>
            <div className="subscribe mt-5">
              <div className="subscribes">
                <input
                  type="emailId"
                  placeholder="Enter Your Work Email"
                  onChange={(e) => setValues(e.target.value, "emailId")}
                />

                <button onClick={handleSubmit}>Join Now</button>
              </div>
            </div>

            <span>
              <img
                src={arrowleft}
                className="img-fluid imgarrow1"
                alt="arrow"
              />
              <img
                src={arrowright}
                className="img-fluid imgarrow2"
                alt="arrow"
              />
            </span>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Aboutus;
