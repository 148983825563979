import React, { useState } from "react";
const HideText = ({ text, maxLength, className }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    const removeHTMLTags = (input) =>
        input
            ?.replace(/<\/?[^>]+(>|$)/g, "")
            ?.replace(/&nbsp;|&#160;|&#xA0;/g, "")
            .trim();

    const truncatedText =
        text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    return (
        <div>
            <p style={{ display: "inline" }} className={className}>
                {isExpanded ? removeHTMLTags(text) : removeHTMLTags(truncatedText)}
            </p>
         
        </div>
    )
}

export default HideText