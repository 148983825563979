export const menu = [
  {
    path: "/admin/admin-list",
    icon: <i class="fa-solid fa-user"></i>,
    name: "Admin Management",
  },
  // {
  //   path: "/admin/category-list",
  //   icon: <i class="fa-solid fa-list-ul"></i>,
  //   name: "Category Management",
  // },

  {
    path: "/admin/company-list",
    icon: <i class="fa-regular fa-building"></i>,
    name: "Companies Management",
  },
  {
    path: "/admin/master-Data",
    icon: <i class="fa-solid fa-bars-progress"></i>,
    name: "Master Data Management",
  },
  {
    path: "/admin/jobs-list",
    icon: <i class="fa-solid fa-table-list"></i>,
    name: "Jobs Management",
  },
  {
    path: "/admin/blog-list",
    icon: <i class="fa-brands fa-blogger"></i>,
    name: "Blogs Management",
  },
  {
    path: "/admin/contact-us-list",
    icon: <i class="fa-regular fa-address-book"></i>,
    name: "User Contact Management",
  },
  {
    path: "/admin/News-letter",
    icon: <i class="material-symbols-outlined">mark_email_unread</i>,
    name: "News Letter Management",
  },
];
