import axios from "axios";
import { url } from "./BaseUrl";

const ApiBaseUrl = url + "/infletio/api/v1";
export const ImageBaseUrl = "https://infletio-prod.s3.us-east-2.amazonaws.com/";
const TOKEN = localStorage.getItem("token");

export const AdminLogOut = async (Payload) => {
  const response = await axios
    .post(`${ApiBaseUrl}/user/logout`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + TOKEN,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};
const logoutUser = async () => {
 
  const USERID = localStorage.getItem("userId");
  const res = await AdminLogOut({_id: USERID });

  if (res.statusCode === 200) {
    localStorage.clear();

    window.location.href = "/";
  }
};
axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;
axios.interceptors.request.use(
  function (config) {
    // UPDATE: Add this code to show global loading indicator

    document.body.classList.add("loading-indicator");

    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
 
  function (response) {
    console.log(response, "response");

    // UPDATE: Add this code to hide global loading indicator

    document.body.classList.remove("loading-indicator");

    return response;
  },

  function (error) {
    // console.log(error);

    if (error?.response?.status === 401) {
      console.log("TOKEN Expired: " + error.response);

      logoutUser();

      return;
    }

    document.body.classList.remove("loading-indicator");

    return Promise.reject(error);
  }
);

const makeRequest = async (method, url, payload = {}, token = "") => {
  try {
    const response = await axios({
      method,
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      data: method !== "get" ? payload : null,
    });
    return response.data;
  } catch (error) {
    return { error: error };
  }
};

export const AdminLogin = async (payload) => {
  return makeRequest("post", `${ApiBaseUrl}/user/login`, payload);
};
export const userNewsLetter = async (payload) => {
  return makeRequest("post", `${ApiBaseUrl}/newsLetter/submit`, payload);
};

export const Forgotpassword = async (payload) => {
  return makeRequest(
    "post",
    `${ApiBaseUrl}/user/forgotPassword/sendOTP`,
    payload
  );
};

export const VerifyOtp = async (payload) => {
  return makeRequest(
    "post",
    `${ApiBaseUrl}/user/forgotPassword/verifyOTP`,
    payload
  );
};

export const Changepassword = async (payload) => {
  return makeRequest(
    "post",
    `${ApiBaseUrl}/user/forgotPassword/passwordUpdate`,
    payload
  );
};
export const ChangeNewpassword = async (payload) => {
  return makeRequest("post", `${ApiBaseUrl}/user/changePassword`, payload);
};
// =============================================ADMIN API=================================================//

export const CreateAdmin = async (payload, token) => {
  return makeRequest("post", `${ApiBaseUrl}/admin/create`, payload, token);
};

export const UpdateAdmin = async (payload, token) => {
  return makeRequest("put", `${ApiBaseUrl}/admin/update`, payload, token);
};

export const DeleteAdmin = async (payload, token) => {
  return makeRequest("delete", `${ApiBaseUrl}/admin/delete`, payload, token);
};

export const Adminlist = async (token) => {
  return makeRequest("get", `${ApiBaseUrl}/admin/findAll`, {}, token);
};

export const SpecficAdmin = async (id, token) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/admin/findParticular?_id=${id}`,
    {},
    token
  );
};
// =============================================CATEGORY API=================================================//

export const CreateCategory = async (payload, token) => {
  return makeRequest(
    "post",
    `${ApiBaseUrl}/admin/category/create`,
    payload,
    token
  );
};

export const UpdateCategory = async (payload, token) => {
  return makeRequest(
    "put",
    `${ApiBaseUrl}/admin/category/update`,
    payload,
    token
  );
};

export const DeleteCategory = async (payload, token) => {
  return makeRequest(
    "delete",
    `${ApiBaseUrl}/admin/category/delete`,
    payload,
    token
  );
};

export const Categorylist = async (token,status) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/admin/category/find?categoryType=${status}`,
    {},
    token
  );
};

export const SpecficCategory = async (id, token) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/admin/category/findParticular?_id=${id}`,
    {},
    token
  );
};
//master data
export const MasterDatalist = async (status, token) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/admin/masterData/find?status=${status}`,
    {},
    token
  );
};

// without token
export const MasterDatalistwithouttoken = async (status) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/admin/masterData/find?status=${status}`,
    {},
  
  );
};
export const UpdateMasterData = async (payload, token) => {
  return makeRequest(
    "put",
    `${ApiBaseUrl}/admin/masterData/update`,
    payload,
    token
  );
};
export const CreateMasterData = async (payload, token) => {
  return makeRequest(
    "post",
    `${ApiBaseUrl}/admin/masterData/create`,
    payload,
    token
  );
};

// =============================================COMPANY API=================================================//
export const CreateCompany = async (payload, token) => {
  return makeRequest(
    "post",
    `${ApiBaseUrl}/admin/company/create`,
    payload,
    token
  );
};

export const UpdateCompany = async (payload, token) => {
  return makeRequest(
    "put",
    `${ApiBaseUrl}/admin/company/update`,
    payload,
    token
  );
};

export const DeleteCompany = async (payload, token) => {
  return makeRequest(
    "delete",
    `${ApiBaseUrl}/admin/company/delete`,
    payload,
    token
  );
};

export const Companylist = async (token) => {
  return makeRequest("get", `${ApiBaseUrl}/admin/company/find`, {}, token);
};

export const SpecficCompany = async (id, token) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/admin/company/findParticular?_id=${id}`,
    {},
    token
  );
};

// =============================================CONTACT US API=================================================//
export const DeleteContactUs = async (payload, token) => {
  return makeRequest(
    "delete",
    `${ApiBaseUrl}/admin/contactUs/delete`,
    payload,
    token
  );
};

export const Contactuslist = async (token) => {
  return makeRequest("get", `${ApiBaseUrl}/admin/contactUs/find`, {}, token);
};
export const GetNewsletterList = async (token) => {
  return makeRequest("get", `${ApiBaseUrl}/admin/newsLetter/find`, {}, token);
};

export const SpecficContactUs = async (id, token) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/admin/contactUs/findParticular?_id=${id}`,
    {},
    token
  );
};

export const UpdateContactus = async (payload, token) => {
  return makeRequest(
    "put",
    `${ApiBaseUrl}/admin/contactUs/update`,
    payload,
    token
  );
};
//user side contact form

export const createContactform = async (payload, token) => {
  return makeRequest(
    "post",
    `${ApiBaseUrl}/user/contactUs/submit`,
    payload,
    token
  );
};
//profile
export const UpdateProfile = async (payload, token) => {
  return makeRequest("put", `${ApiBaseUrl}/admin/update`, payload, token);
};
export const ProfileGet = async (_id, token) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/admin/findParticular?_id=${_id}`,
    {},
    token
  );
};
//user side blog
export const BlogGet = async (token) => {
  return makeRequest("get", `${ApiBaseUrl}/user/blogs/find`, token);
};
export const BlogRecent = async (token) => {
  return makeRequest("get", `${ApiBaseUrl}/user/blogs/topBlogs`, token);
};
export const SpecficUserBlog = async (id, token) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/user/blogs/findParticular?_id=${id}`,
    {},
    token
  );
};

//ADMIN Blog Module

export const CreateBlogs = async (payload, token) => {
  return makeRequest(
    "post",
    `${ApiBaseUrl}/admin/blogs/create`,
    payload,
    token
  );
};

export const UpdateBlog = async (payload, token) => {
  return makeRequest("put", `${ApiBaseUrl}/admin/blogs/update`, payload, token);
};

export const DeleteBlog = async (payload, token) => {
  return makeRequest(
    "delete",
    `${ApiBaseUrl}/admin/blogs/delete`,
    payload,
    token
  );
};

export const Bloglist = async (token) => {
  return makeRequest("get", `${ApiBaseUrl}/admin/blogs/find`, {}, token);
};

export const SpecficBlog = async (id, token) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/admin/blogs/findParticular?_id=${id}`,
    {},
    token
  );
};

//upload
export const UploadFile = async (payload) => {
  try {
    const response = await axios.post(`${ApiBaseUrl}/file/upload`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    return {error:error?.message};
  }
};


//user side Job
export const jobGet = async (payload) => {
  return makeRequest("post", `${ApiBaseUrl}/user/jobs/find`,payload);
};
export const jobGetFilter = async (payload) => {
  return makeRequest("post", `${ApiBaseUrl}/user/jobs/filter`, payload);
};
export const SpecficJobUser = async (id, token) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/user/jobs/findParticular?_id=${id}`,
    {},
    token
  );
};
//ADMIN job Module

export const CreateJobs = async (payload, token) => {
  return makeRequest("post", `${ApiBaseUrl}/admin/jobs/create`, payload, token);
};

export const UpdateJobs = async (payload, token) => {
  return makeRequest("put", `${ApiBaseUrl}/admin/jobs/update`, payload, token);
};

export const DeleteJobs = async (payload, token) => {
  return makeRequest(
    "delete",
    `${ApiBaseUrl}/admin/jobs/delete`,
    payload,
    token
  );
};

export const Jobslist = async (token) => {
  console.log("get", `${ApiBaseUrl}/admin/jobs/find`, {}, token)
  return makeRequest("get", `${ApiBaseUrl}/admin/jobs/find`, {}, token);
};

export const SpecficJobs = async (id, token) => {
  return makeRequest(
    "get",
    `${ApiBaseUrl}/admin/jobs/findParticular?_id=${id}`,
    {},
    token
  );
};




// user side job
export const jobHome = async () => {
  return makeRequest("post", `${ApiBaseUrl}/user/jobs/find`);
};



export const DeleteMaster = async (payload, token) => {
  return makeRequest(
    "delete",
    `${ApiBaseUrl}/admin/masterData/delete`,
    payload,
    token
  );
};