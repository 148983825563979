import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";

const Terms = () => {
  return (
    <div>
      <span>
        <Navbar />
      </span>
      <div className="mt-5">
        <div className="container">
          <p>
            Избранная статья Мадам Бовари Вступительные титры фильма «Мадам
            Бовари» «Мадам Бовари» (фр. Madame Bovary) — французский
            драматический фильм режиссёра Жана Ренуара, снятый в 1933 году.
            Создан по одноимённому роману Гюстава Флобера, является второй его
            экранизацией в истории кинематографа. Первоначально крупный издатель
            и начинающий кинопродюсер Гастон Галлимар предложил место режиссёра
            Жаку Фейдеру. Галлимар хотел видеть в роли Эммы Бовари свою
            любовницу Валентину Тесье, однако после встречи с ней Фейдер отверг
            её кандидатуру и продюсер решил отказаться от его услуг. Актриса
            предложила на роль Шарля Бовари, мужа Эммы, Пьера Ренуара, своего
            бывшего партнёра по сцене, с которым у неё ранее были романтические
            отношения. Актёр убедил Галлимара взять на место режиссёра своего
            брата — Жана Ренуара. Фильм снимался летом и осенью 1933 года в
            нормандских городах и их окрестностях — там, где происходило
            действие книги. В начале сентября съёмки на натуре были закончены,
            до начала октября они продолжались в павильонах студии в Бийянкуре.
            Ренуару удалось собрать коллектив единомышленников, и члены
            съёмочной группы много времени проводили вместе. К созданию музыки
            был привлечён композитор Дариюс Мийо. «Мадам Бовари» стала первой
            работой Ренуара с оригинальным музыкальным сопровождением, хотя при
            этом он, по своему обыкновению, не отказался и от использования не
            связанной с фильмом музыки. Первый публичный показ фильма прошёл в
            Париже в конце 1933 года в кинотеатре Palais-Rochechouart. В прокат
            он вышел 12 января 1934 года в парижском кинотеатре Ciné-Opéra.
            После этого сокращённая версия демонстрировалась на провинциальных
            экранах. Фильм не имел успеха, ни у публики, ни у критиков. Ренуар,
            а вслед за ним и многие специалисты склонны были винить в этом
            существенные сокращения, на которые режиссёр вынужден был пойти под
            давлением прокатчиков.
          </p>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default Terms;
