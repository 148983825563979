export const CloudFrontUrl = 'https://www.instagram.com'

export const MuiTableOptions = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
};

export const EditorOption={
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker'],
    inline: { options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'] },
    blockType: { options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'] },
    fontSize: { options: [8, 10, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72] },
    fontFamily: { options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'] },
    list: { options: ['unordered', 'ordered'] },
    textAlign: { options: ['left', 'center', 'right', 'justify'] },
    colorPicker: { options: ['default', 'red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet'] },
   
   
    emoji: { options: ['emoji'] },
   
    history: { options: ['undo', 'redo'] },
  }


  export const truncateText = (text, lines) => {
    const lineLimit = lines * 1;
    const words = text.split(",");
    if (words.length > lineLimit) {
      return words.slice(0, lineLimit).join(" ") + "...";
    }
    return words;
  };