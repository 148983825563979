import React, { useEffect, useState } from "react";
import { NonEmptyValidation, LengthValidation } from "../../../Utils/validate";
import { useNavigate, useParams } from "react-router-dom";
import {
  ImageBaseUrl,
  SpecficCategory,
  UpdateCategory,
  UploadFile,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../../Utils/Toastify";
import { CloudFrontUrl } from "../../../Utils/utils";
import moment from "moment";
const CategoryCreate = () => {
  const [data, setData] = useState({});
  const [validation, setValidation] = useState({});
  const [password, setPassword] = useState(false);
  const [file, setFile] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useSelector((state) => state?.login);
  const setValues = (value, key, max) => {
    setData({ ...data, [key]: value });
    if (validation[key]) setValidation({ ...validation, [key]: false });
    if (value.length === max) {
      setValidation({ ...validation, [key]: LengthValidation(value, max) });
      setTimeout(() => {
        setValidation({ ...validation, [key]: false });
      }, 3000);
    }
  };
  const togglepassword = () => {
    setPassword(!password);
  };
  const GetSpecificData = async () => {
    const response = await SpecficCategory(id, token);
    if (response && response.statusCode === 200) {
      setData(response?.data);
    }
  };
  const UploadImage = async (e, key) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
  

    let formData = new FormData();

    formData.append("file", file);

    formData.append("path", "profile/");
    const response = await UploadFile(formData);

    if (response?.data?.statusCode === 200) {
      setValues(response?.data?.data?._id, key);
      successMsg(response.message);
    } else {
      errorMsg(response.message);
    }
  };
  useEffect(() => {
    GetSpecificData();
  }, []);
  const formdata = [
    {
      label: "Category Type",
      type: "select",
      value: data.categoryType,
      options: [
        { key: "company", value: "Company" },
        { key: "job", value: "Job" },
      ],
      placeholder: "Select Category Type",
      key: "categoryType",
      valid: NonEmptyValidation,
      required: true,
      disable: true,
    },
    {
      label: "Category Title",
      type: "text",
      value: data.title,
      placeholder: "Enter Category Title",
      key: "title",
      valid: NonEmptyValidation,
      required: true,
      max: 20,
    },
  ];
  if (data.categoryType === "company") {
    formdata.push({
      label: " Company Logo",
      type: "file",
      value: data.icon,
      accept: "image/png,image/webp",
      key: "icon",
      valid: NonEmptyValidation,

      required: true,
      // hint: 'Max Image Size 20kb'
    });
  }
  const handlesubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    formdata.forEach((field) => {
      validate[field.key] = field.valid(field.value);
    });
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
        let payload ;
       if (data.categoryType === "company"){
        payload = {
            title: data.title,
            _id: id,
            icon:data?.icon,
          };
       }else{
        payload = {
            title: data.title,
            _id: id,
       
          };
       }
      
      const response = await UpdateCategory(payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response?.message);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        errorMsg(response?.message);
      }
    }
  };

  return (
    <div>
      <div className="content-header">
        <p>
          <span>
            <i
              class="fa-regular fa-circle-left me-3"
              onClick={() => navigate(-1)}
            />
          </span>
          Category Update
        </p>
        <button className="admin-btn" onClick={handlesubmit}>
          Update Category
        </button>
      </div>
      <div className="content-body">
        <div className="row gx-5">
          {formdata.map((item, i) => {
            return (
              <div className="col-lg-6" key={i}>
                <div className="d-flex flex-column create-input">
                  <label>
                    {item.label} {item.required && <span>*</span>}
                  </label>
                  {item.type === "select" ? (
                    <select
                      value={item.value}
                      onChange={(e) => setValues(e.target.value, item.key)}
                      disabled
                      onBlur={(e) =>
                        setValidation({
                          ...validation,
                          [item.key]: item.valid(e.target.value),
                        })
                      }
                    >
                      <option value="">{item.placeholder}</option>
                      {item.options.map((option, index) => (
                        <option key={index} value={option.key}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                  ) : item.type == "file" ? (
                    <div>
                      <input
                        type={item.type}
                        id={item.key}
                        className="d-none"
                        onChange={(e) => UploadImage(e, item.key)}
                        accept={item.accept}
                      />
                      <label for={item.key} className="btn-delete">
                        Upload Image
                      </label>
                


{file?.length > 0 ? (
                <img
                  width={"100px"}
                  height={"100px"}
                  src={file}
                  alt="Uploaded"
                />
              ) : (
                <img
                  width={"100px"}
                  height={"100px"}
                  src={ImageBaseUrl + data?.icon}
                  alt="Uploaded"
                />
              )}
                    </div>
                  ) : (
                    <input
                      type={
                        item.type !== "password"
                          ? item.type
                          : password
                          ? "text"
                          : item.type
                      }
                      value={item.value}
                      placeholder={item.placeholder}
                      maxLength={item.max}
                      onChange={(e) =>
                        setValues(e.target.value, item.key, item.max)
                      }
                      onBlur={(e) =>
                        setValidation({
                          ...validation,
                          [item.key]: item.valid(e.target.value),
                        })
                      }
                    />
                  )}
                  {item.type === "password" && (
                    <>
                      {!password ? (
                        <i
                          className="fa-solid fa-eye"
                          onClick={togglepassword}
                        ></i>
                      ) : (
                        <i
                          className="fa-solid fa-eye-slash"
                          onClick={togglepassword}
                        ></i>
                      )}
                    </>
                  )}
                  {item?.max && (
                    <small className="max-hint">
                      ({`Maximum ${item.max} Characters`})
                    </small>
                  )}
                  <small className="validation-error error-space">
                    {validation?.[item.key]?.message
                      ? `${item.label} ${validation?.[item.key]?.message}`
                      : ""}
                  </small>
                </div>
              </div>
            );
          })}
        </div>
        {/* <small className='note'>
                    (The password must contain at least 1 capital letter, 1 lowercase letter, 1 number, and 1 special character. Password is not mandatory, Otherwise It will be mailed)
                </small> */}
      </div>
    </div>
  );
};

export default CategoryCreate;
