import React, { useState } from "react";
import { PasswordValidation } from "../../Utils/validate";
import { useNavigate } from "react-router-dom";
import { ChangeNewpassword } from "../../Repository/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../Utils/Toastify";

const ChangeNewPassword = () => {
  const [data, setData] = useState({});
  const [validation, setValidation] = useState({});
  const [password0, setPassword0] = useState(false);
  const [password1, setPassword1] = useState(false);
  const [password2, setPassword2] = useState(false);
  const navigate = useNavigate();
  const { token, emailId } = useSelector((state) => state?.login);
  const setValues = (value, key) => {
    setData({ ...data, [key]: value });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const togglepassword = (i) => {
    if (i === 0) {
      setPassword0(!password0);
    }
    if (i === 1) {
      setPassword1(!password1);
    }
    if (i === 2) {
      setPassword2(!password2);
    }
  };
  const formdata = [
    {
      label: "Old Password",
      type: "password",
      value: data.oldPassword,
      placeholder: "Enter Old Password",
      key: "oldPassword",
      valid: PasswordValidation,
      required: true,
    },
    {
      label: "New Password",
      type: "password",
      value: data.newPassword,
      placeholder: "Enter New Password",
      key: "newPassword",
      valid: PasswordValidation,
      required: true,
    },
    {
      label: "Confirm Password",
      type: "password",
      value: data.confirmPassword,
      placeholder: "Enter Confirm Password",
      key: "confirmPassword",
      valid: PasswordValidation,
      required: true,
    },
  ];

  const handlesubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    formdata.forEach((field) => {
      validate[field.key] = field.valid(field.value);
    });
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      if (data.newPassword === data.confirmPassword) {
        const payload = {
          emailId: emailId,
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        };
        const response = await ChangeNewpassword(payload, token);
        if (response && response.statusCode === 200) {
          successMsg(response?.message);
        } else {
          errorMsg(response?.message);
        }
      } else {
        errorMsg("Password does not match");
      }
    }
  };

  return (
    <div>
      <div className="content-header">
        <p>
          <span>
            <i
              class="fa-regular fa-circle-left me-3"
              onClick={() => navigate(-1)}
            />
          </span>
          Change Password
        </p>
        {/* <button className='admin-btn' onClick={handlesubmit}>Update Password</button> */}
      </div>
      <div className="content-body">
        <div className=" gx-5">
          {formdata.map((item, i) => {
            return (
              <div className="col-lg-6">
                <div className="d-flex flex-column create-input">
                  <label>
                    {item.label} {item.required && <span>*</span>}
                  </label>
                  <input
                    type={
                      item.type !== "password"
                        ? item.type
                        : i === 0
                        ? password0
                          ? "text"
                          : "password"
                        : i === 1
                        ? password1
                          ? "text"
                          : "password"
                        : password2
                        ? "text"
                        : "password"
                    }
                    value={item.value}
                    placeholder={item.placeholder}
                    onChange={(e) => setValues(e.target.value, item.key)}
                    onBlur={(e) =>
                      setValidation({
                        ...validation,
                        [item.key]: item.valid(e.target.value),
                      })
                    }
                  />
                  {item.type === "password" && (
                    <>
                      {!eval(`password${i}`) ? (
                       <i
                       className="fa-solid fa-eye-slash"
                       onClick={() => togglepassword(i)}
                     ></i>
                      ) : (
                        <i
                        className="fa-solid fa-eye"
                        onClick={() => togglepassword(i)}
                      ></i>
                       
                      )}
                    </>
                  )}
                  <small className="validation-error error-space">
                    {validation?.[item.key]?.message
                      ? `${item.label} ${validation?.[item.key]?.message}`
                      : ""}
                  </small>
                </div>
              </div>
            );
          })}
        </div>
        <small className="note">
          (The password must contain at least 1 capital letter, 1 lowercase
          letter, 1 number, 1 special character and Atleast 8 character )
        </small>
        <div className="d-flex justify-content-center mt-4">
          <button className="admin-btn" onClick={handlesubmit}>
            Update Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeNewPassword;
