import React, { useEffect, useState } from "react";
// import blue from "../../";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share";
const Sharebutton = () => {
  const [isVisibleSocialMediaLinks, setIsVisibleSocialMediaLinks] =
    useState(false);
  useEffect(() => {
    setIsVisibleSocialMediaLinks(!isVisibleSocialMediaLinks);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {isVisibleSocialMediaLinks && (
        <div className="share-content-child d-flex ">
          <div className="me-3">
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon size={50} round={true} />
              <p>Facebook</p>
            </FacebookShareButton>
          </div>

          <div className="me-3">
            <TwitterShareButton url={window.location.href}>
              <TwitterIcon size={50} round={true} />
              <p>Twitter</p>
            </TwitterShareButton>
          </div>
          <div className="me-3">
            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon size={50} round={true} />
              <p>Whatsapp</p>
            </WhatsappShareButton>
          </div>
          <div className="me-3">
            <EmailShareButton url={window.location.href}>
              <EmailIcon size={50} round={true} />
              <p>Email</p>
            </EmailShareButton>
          </div>
          <div className="me-3">
            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon size={50} round={true} />
              <p>LinkedIn</p>
            </LinkedinShareButton>
          </div>
        </div>
      )}
    </div>
  );
};
export default Sharebutton;
