import React, { useState } from "react";
import {
  EmailValidation,
  NonEmptyValidation,
  NumberValidation,
  PasswordValidation,
  StringValidation,
} from "../../../Utils/validate";
import { useNavigate } from "react-router-dom";
import { CreateCategory, CreateMasterData } from "../../../Repository/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../../Utils/Toastify";

const MasterCreate = () => {
  const [data, setData] = useState({});
  const [validation, setValidation] = useState({});
  const [password, setPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state?.login);
  const setValues = (value, key) => {
    setData({ ...data, [key]: value });
    if (validation[key]) setValidation({ ...validation, [key]: false });
    console.log(data);
  };
  const togglepassword = () => {
    setPassword(!password);
  };

  const formdata = [
    {
      label: "Master-Data Type",
      type: "select",
      value: data.masterType,
      options: [
        // { key: "companySize", value: "Company Size" },
        { key: "jobTitle", value: "Job Title" },
        { key: "experience", value: "Experience" },
        { key: "jobType", value: "Job Type" },
        { key: "salaryRange", value: "Salary Range" },
        { key: "skills", value: "Skills" },
        { key: "qualification", value: "Qualification" },
      ],
      placeholder: "Select Master-Data Type",
      key: "masterType",
      valid: NonEmptyValidation,
      required: true,
    },
    {
      label: "Master-Data Name",
      value: data.masterValue,
      placeholder: "Enter Master-Data Name",
      key: "masterValue",
      type: data?.masterType === "experience" ? "number" : "text",
      valid:
        data?.masterType === "experience"
          ? NumberValidation
          : NonEmptyValidation,
      required: true,
    },
  ];

  const handlesubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    formdata.forEach((field) => {
      validate[field.key] = field.valid(field.value);
    });
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let payload;

      if (data?.masterType === "experience") {
        payload = {
          masterValue: data.masterValue + "years",
          masterType: data?.masterType,
        };
      } else {
        payload = {
          ...data,
        };
      }

      const response = await CreateMasterData(payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response?.message);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        errorMsg(response?.message);
      }
    }
  };

  return (
    <div>
      <div className="content-header">
        <p>
          <span>
            <i
              class="fa-regular fa-circle-left me-3"
              onClick={() => navigate(-1)}
            />
          </span>
          Create Master Data
        </p>
        {/* <button className="admin-btn" onClick={handlesubmit}>
          {" "}
          Master Data Create
        </button> */}
      </div>
      <div className="content-body">
        <div className="row gx-5">
          {formdata.map((item, i) => {
            return (
              <div className="col-lg-6" key={i}>
                <div className="d-flex flex-column create-input">
                  <label>
                    {item.label}{" "}
                    {item.required && <span className="star">*</span>}
                  </label>
                  {item.type === "select" ? (
                    <select
                      value={item.value}
                      onChange={(e) => setValues(e.target.value, item.key)}
                      onBlur={(e) =>
                        setValidation({
                          ...validation,
                          [item.key]: item.valid(e.target.value),
                        })
                      }
                    >
                      <option value="">{item.placeholder}</option>
                      {item.options.map((option, index) => (
                        <option key={index} value={option.key}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type={
                        item.type !== "password"
                          ? item.type
                          : password
                          ? "text"
                          : item.type
                      }
                      value={item.value}
                      placeholder={item.placeholder}
                      onChange={(e) => setValues(e.target.value, item.key)}
                      onBlur={(e) =>
                        setValidation({
                          ...validation,
                          [item.key]: item.valid(e.target.value),
                        })
                      }
                    />
                  )}

                  <small className="validation-error error-space">
                    {validation?.[item.key]?.message
                      ? `${item.label} ${validation?.[item.key]?.message}`
                      : ""}
                  </small>
                </div>
              </div>
            );
          })}
          <div className="d-flex justify-content-center mt-5">
            <button className="admin-btn" onClick={handlesubmit}>
              Create
            </button>
          </div>
        </div>
        {/* <small className='note'>
                    (The password must contain at least 1 capital letter, 1 lowercase letter, 1 number, and 1 special character. Password is not mandatory, Otherwise It will be mailed)
                </small> */}
      </div>
    </div>
  );
};

export default MasterCreate;
