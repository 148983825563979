import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareNodes,
  faCircleCheck,
  faArrowRight,
  faStethoscope,
  faTowerObservation,
  faVideo,
  faPeopleGroup,
  faHouseLaptop,
  faWindowRestore,
  faHandsHoldingCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Footer";
import Sharebutton from "../Common/Sharebutton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { successMsg } from "../../Utils/Toastify";
import { useNavigate, useParams } from "react-router-dom";
import { ImageBaseUrl, SpecficJobUser, jobGet } from "../../Repository/Api";
import { useSelector } from "react-redux";
import moment from "moment";

const JobDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { token } = useSelector((state) => state?.login);
  const [popupVisible, setPopupVisible] = useState(false);
  const [JobspecificList, setJobspecificList] = useState([]);
  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };
 
  const GetData = async () => {
    const response = await SpecficJobUser(id, token);
    if (response && response.statusCode === 200) {
      setJobspecificList(response?.data);
    } else {
      setJobspecificList([]);
    }
  };
  const [blogPopularList, setBlogPopularList] = useState([]);

  const GetDataSmilar = async () => {
    const response = await jobGet();
    if (response && response.statusCode === 200) {
      setBlogPopularList(
        response?.data?.filter(
          (blog) =>
            blog?.status === "active" &&
            JobspecificList?.jobTitle === blog?.jobTitle &&
            blog?._id !== id
        )
      );
    } else {
      // setBlogPopularList([]);
    }
  };
  useEffect(() => {
    GetData();
   
  }, [id]);
  useEffect(() => {
   
    GetDataSmilar();
  }, []);
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="container">
        <div className="specificbox">
          <div className="specifichead">
            <img src={ImageBaseUrl + JobspecificList?.companyLogo} alt="" />
            <div className="specificboxpara ms-4">
              <h4>{JobspecificList?.jobTitle}</h4>
              <p>
                {JobspecificList?.company} ,{JobspecificList?.location}
              </p>
            </div>
          </div>
          <div>
            <span onClick={togglePopup}>
              <FontAwesomeIcon icon={faShareNodes} className="shareicon " />
            </span>
            {popupVisible && (
              <div className="popup-container">
                <div className="popup">
                  <div className="share">
                    <p>Share</p>
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="cancelicon "
                      onClick={togglePopup}
                    />
                  </div>
                  <Sharebutton />
                  <div className="copylink">
                    <input
                      value={window.location.href}
                      className=""
                      disabled
                      style={{ width: "77%" }}
                    />
                    <CopyToClipboard text={window.location.href}>
                      <button
                        onClick={() => successMsg("successfully Copied !")}
                      >
                        Copy
                      </button>
                    </CopyToClipboard>
                  </div>
                  {/* <div className="social">
                    <div className="sociallink me-3">
                      <a href="https://www.instagram.com" className="anchor">
                        <img
                          src="./images/link1.png"
                          alt="logo"
                          className="popimg"
                        />
                        <p>Facebook</p>
                      </a>
                    </div>
                    <div className="sociallink me-3">
                      <a href="https://www.instagram.com" className="anchor">
                        <img
                          src="./images/link2.png"
                          alt="logo"
                          className="popimg"
                        />
                        <p>Instagram</p>
                      </a>
                    </div>
                    <div className="sociallink me-3">
                      <a href="https://www.instagram.com" className="anchor">
                        <img
                          src="./images/link3.png"
                          alt="logo"
                          className="popimg"
                        />
                        <p>WhatsApp</p>
                      </a>
                    </div>
                    <div className="sociallink me-3">
                      <a href="https://www.instagram.com" className="anchor ">
                        <img
                          src="./images/link4.png"
                          alt="logo"
                          className="popimg"
                        />
                        <p>LinkedIn</p>
                      </a>
                    </div>
                    <div className="sociallink me-3">
                      <a href="https://www.instagram.com" className="anchor ">
                        <img
                          src="./images/link5.png"
                          alt="logo"
                          className="popimg"
                        />
                        <p>E-mail</p>
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
            )}
            {/* <button className="specificbtn">Apply</button> */}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 mt-5">
            <div className="Responsibilities">
              <h4 className="mt-4">Qualification</h4>
              <div className="respoints mt-3">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>{JobspecificList?.qualification}</p>
              </div>
            </div>
            {/* <div className="Responsibilities">
              <h4 className="mt-4">Responsibilities</h4>
              <div className="respoints mt-3">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>
                  Community engagement to ensure that is supported and actively
                  represented online
                </p>
              </div>
              <div className="respoints">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>Focus on social media content development and publication</p>
              </div>
              <div className="respoints">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>Marketing and strategy support</p>
              </div>
              <div className="respoints">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>
                  Stay on top of trends on social media platforms, and suggest
                  content ideas to the team
                </p>
              </div>
              <div className="respoints">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>Engage with online communities</p>
              </div>
              <h4 className="mt-4">Who You Are</h4>
              <div className="respoints mt-3">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>
                  Community engagement to ensure that is supported and actively
                  represented online
                </p>
              </div>
              <div className="respoints">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>
                  You have a sense for beautiful spaces and office experiences
                </p>
              </div>
              <div className="respoints">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>
                  You are a confident office manager, ready for added
                  responsibilities
                </p>
              </div>
              <div className="respoints">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>You're detail-oriented and creative</p>
              </div>
              <div className="respoints">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>You're a growth marketer and know how to run campaigns</p>
              </div>
              <h4 className="mt-4">Nice-To-Haves</h4>
              <div className="respoints mt-3">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>Fluent in English</p>
              </div>
              <div className="respoints">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>Project management skills</p>
              </div>
              <div className="respoints">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ticksicon mt-1 me-2"
                />
                <p>Copy editing skills</p>
              </div>
            </div> */}
          </div>
          <div className="col-lg-4 mt-5">
            <div className="Abtrole">
              <div className="aboutrole">
                <h4>About this role</h4>
                {/* <div className="appliedprog mt-3">
                  <p>5 applied of 10 capacity</p>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style={{ width: "50%" }}
                    ></div>
                  </div>
                </div> */}
                <div className="applycont mt-3">
                  <p>Apply Before</p>
                  <p>
                    {" "}
                    <span className="market">
                      {moment(JobspecificList?.lastApplyDate).format(
                        "MMM Do YY"
                      )}
                    </span>
                  </p>
                </div>

                <div className="applycont">
                  <p>Job Type</p>
                  <p>{JobspecificList?.jobType}</p>
                </div>
                <div className="applycont pb-3">
                  <p>Salary Range</p>
                  <p>{JobspecificList?.salaryRange}</p>
                </div>
              </div>
            </div>
            {/* <div className="Categories mt-4">
              <h4>Categories</h4>
              <div className="catsub d-flex mt-4">
                <p className="market">Marketing</p>
                <p className=" desig ms-4">Design</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="perk mt-5">
        <div className="container ">
          <div className="mt-5">
            <h4 className="">Job Description</h4>
            {/* <p>This job comes with several perks and benefits</p> */}
            <div className="row">
              <div className="col-lg-8 mt-3">
                <div className="Description">
                  <div className="respoints">
                    {/* <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="ticksicon mt-1 me-2"
                    /> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: JobspecificList?.jobDescription,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-3">
                <div className="perkbox">
                  <FontAwesomeIcon
                    icon={faStethoscope}
                    className="ticksicon mt-1 me-2"
                  />
                  <h6 className="mt-2">Full Healthcare</h6>
                  <p>
                    We believe in thriving communities and that starts with our
                    team being happy and healthy.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="perkbox">
                  <FontAwesomeIcon
                    icon={faTowerObservation}
                    className="ticksicon mt-1 me-2"
                  />
                  <h6 className="mt-2">Unlimited Vacation</h6>
                  <p>
                    We believe you should have a flexible schedule that makes
                    space for family, wellness, and fun.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="perkbox">
                  <FontAwesomeIcon
                    icon={faVideo}
                    className="ticksicon mt-1 me-2"
                  />
                  <h6 className="mt-2">Skill Development</h6>
                  <p>
                    We believe in always learning and leveling up our skills.
                    Whether it's a conference or online course.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="perkbox">
                  <FontAwesomeIcon
                    icon={faPeopleGroup}
                    className="ticksicon mt-1 me-2"
                  />
                  <h6 className="mt-2">Team Summits</h6>
                  <p>
                    Every 6 months we have a full team summit where we have fun,
                    reflect, and plan for the upcoming quarter.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="perkbox">
                  <FontAwesomeIcon
                    icon={faHouseLaptop}
                    className="ticksicon mt-1 me-2"
                  />
                  <h6 className="mt-2">Remote Working</h6>
                  <p>
                    You know how you perform your best. Work from home, coffee
                    shop or anywhere when you feel like it.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="perkbox">
                  <FontAwesomeIcon
                    icon={faWindowRestore}
                    className="ticksicon mt-1 me-2"
                  />
                  <h6 className="mt-2">Commuter Benefits</h6>
                  <p>
                    We’re grateful for all the time and energy each team member
                    puts into getting to work every day.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="perkbox">
                  <FontAwesomeIcon
                    icon={faHandsHoldingCircle}
                    className="ticksicon mt-1 me-2"
                  />
                  <h6 className="mt-2">We give back.</h6>
                  <p>
                    We anonymously match any donation our employees make (up to
                    $/€ 600) so they can support the organizations they care
                    about most—times two.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="container">
        {/* <div className="row">
          <div className="col-lg-6">
            <div className="SocialMediaAssistant">
              <div className="socialcont">
                <img src="./images/alljoblog1.png" alt="" />
                <div className="ms-4">
                  <h4>Social Media Assistant</h4>
                  <div className="redirect">
                    <a href="..">Read more about Social Media Assistant</a>
                    <span>
                      <a href="../">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="rightarrowicon mt-1 ms-2"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <p className="socialpara">
                Stripe is a technology company that builds economic
                infrastructure for the internet. Businesses of every size—from
                new startups to public companies—use our software to accept
                payments and manage their businesses online.
              </p>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="socialimg">
              <img src="./images/social.png" alt="..." />
            </div>
          </div>
        </div> */}
      </div>
      {blogPopularList.map((items, index) => (
        <div className="container mt-5" key={index}>
          <div className="simjob">
            <h4>similar jobs</h4>
            <div className="showallssim">
              <a href="#JobOpportunities">show all jobs</a>
              <span>
                <a href="../">
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="rightarrowicon mt-1 ms-2"
                  />
                </a>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 mt-4">
              <div className=" simbox">
                <div>
                  <img src={ImageBaseUrl + items?.companyLogo} alt="..." />
                </div>
                <div className="simboxcon ps-4">
                  <h5>{items?.jobTitle}</h5>
                  <p>
                    {items?.company}, {items?.location}
                  </p>
                  <div className="d-flex simflex">
                    <p className="simfull">{items?.jobType}</p>
                    {/* <p className="simonsite">On-Site</p> */}
                    <p className="dollar"> {items?.salaryRange}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default JobDetails;
