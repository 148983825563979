import React, { useState } from "react";
import "./homecontent.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrowleft from "../../Assets/image/arrowleft.png";
import arrowright from "../../Assets/image/arrowright (2).png";
import bannerpicleft from "../../Assets/images/bannerpicleft.png";
import bannerpicright from "../../Assets/images/bannerpicright.png";
import box1 from "../../Assets/images/box1.jpeg";
import box2 from "../../Assets/images/box2.jpg";
import box3 from "../../Assets/images/box3.jpeg";
import self1 from "../../Assets/images/self1.png";
import respect from "../../Assets/images/respect.png";
import determination from "../../Assets/images/determination.png";
import family from "../../Assets/images/family.png";
import aboutusimg from "../../Assets/images/about-us.jpeg";
import newshelp1 from "../../Assets/images/newshelp1.png";
import newshelp2 from "../../Assets/images/newshelp2.png";
import newshelp3 from "../../Assets/images/newshelp3.png";
import LazyLoad from "react-lazyload";
import {
  faPlay,
  faArrowRight,
  faCheck,
  faMagnifyingGlass,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";

import ScrollTrigger from "react-scroll-trigger";

import Aos from "aos";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ImageBaseUrl, jobHome, userNewsLetter } from "../../Repository/Api";
import { useSelector } from "react-redux";
import { EmailValidation } from "../../Utils/validate";
import Swal from "sweetalert2";
import { errorMsg } from "../../Utils/Toastify";

const Homecontent = () => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  const [login, setLogin] = useState({});
  const [validation, setValidation] = useState({});
  const [Counteron, SetcounterOn] = useState(false);
  const setValues = (e, key) => {
    setLogin({ ...login, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const handleSubmit = async (e) => {
    const validate = {};
    validate.emailId = EmailValidation(login.emailId);

    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const payload = {
        emailId: login.emailId,
      };
      const response = await userNewsLetter(payload);
      console.log(response);
      if (response && response.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "Thank You",
          text: "You will be receiving a newsletter via emailId",
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        errorMsg(response.message);
      }
    } else {
      errorMsg(`Email Id ${validate?.emailId?.message}`);
    }
  };
  const [data, setData] = useState([]);
  console.log(data)
  const GetData = async () => {
    try {
      const response = await jobHome();

      if (response && response.statusCode === 200) {
        // setData(response?.data.filter((a) => a.status === "active"));
        // Filter active jobs
        const activeJobs = response.data.filter((a) => a.status === "active");

        // Sort active jobs based on _id in descending order
        const sortedData = activeJobs.sort((a, b) => b._id.localeCompare(a._id));
        setData(sortedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    GetData();
  }, []);

  const [showAll, setShowAll] = useState(false);

  const handleViewMore = () => {
    setShowAll(true);
    navigate("/JobOpportunities");
  };

  return (
    <div className="homecontent">
      <div className="fluid pt-5">
        <div className="container text-center part1">
          <div className="row">
            <div className="col-md-12 section1">
              <h1>
                One Step Closer To
                <span id="nextline">Your Dream Job</span>
              </h1>
              <p>We're not just staffers; we're matchmakers.
              </p>
              {/* <div className="btns mt-5 ">
                <button className="getbtn">Get Started</button>
                <div className="play">
                  <i className="plybtn ">
                    <FontAwesomeIcon icon={faPlay} />
                  </i>
                </div>
                <div className="para">
                  <p className="ourstorybtn">Our Story</p>
                </div>
              </div> */}
              <img className=" arrowleft col-" src={arrowleft} alt="arrow" />
              <img className=" arrowright" src={arrowright} alt="arrow" />
              <img className=" boxleft" src={bannerpicleft} alt="arrow" />
              <img className=" boxright" src={bannerpicright} alt="arrow" />
              <div className="number mt-5">
                {/* <div className="me-4">
                  <ScrollTrigger
                    onEnter={() => SetcounterOn(true)}
                    onExit={() => SetcounterOn(false)}
                  >
                    <span className="num">
                      {Counteron && (
                        <CountUp start={0} end={25} duration={2} delay={0} />
                      )}
                      M+
                    </span>
                  </ScrollTrigger>
                  <span className="name">Users</span>
                </div> */}
                <div className=" me-4">
                  <ScrollTrigger
                    onEnter={() => SetcounterOn(true)}
                    onExit={() => SetcounterOn(false)}
                  >
                    <span className="num">
                      {Counteron && (
                        <CountUp start={0} end={500} duration={2} delay={0} />
                      )}
                      K+
                    </span>
                  </ScrollTrigger>
                  <span className="name">Jobs</span>
                </div>
                <div className="">
                  <ScrollTrigger
                    onEnter={() => SetcounterOn(true)}
                    onExit={() => SetcounterOn(false)}
                  >
                    <span className="num">
                      {Counteron && (
                        <CountUp start={0} end={100} duration={2} delay={0} />
                      )}
                      +
                    </span>
                  </ScrollTrigger>
                  <span className="name">Partners</span>
                </div>
              </div>
            </div>
          </div>
          <p className="bannerlast">Work with exciting companies</p>
        </div>
      </div>   {data?.length > 0 && (
        <div className="owlback">
          <div className="container  part2 text-center ">
            <div className="row">
              <div className="col-md-12 owlbox">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  autoplay={true}
                  items="6"
                  dots={true}
                  smartSpeed={2000}
                  nav={false}
                  margin={20}
                  center={true}
                  autoplayTimeout={5000}
                  responsive={{
                    360: { items: 1 },
                    414: { items: 2 },
                    670: { items: 3 },
                    992: { items: 4 },
                    1200: { items: 6 },
                  }}
                >

                  {data?.map((item, index) => {
                    console.log(item, '223')
                    return (
                      <div className="item" key={index}>
                        <img
                          src={ImageBaseUrl + item.companyLogo}
                          className="owlimage"
                          alt="logo"
                        />
                        {console.log(ImageBaseUrl + item.companyLogo, '2222')}
                      </div>
                    )
                  }

                  )}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>)}
      {/* After carosel */}
      <div className="container text-center mt-5 part3">
        <div className="row">
          <div className="col-md-12 part3cont">
            <h2>
              More Easier To
              <span className="ms-2">
                Find A Job <br />
                With Our Platform
              </span>
            </h2>
            <p className="mt-4">
              Imagine having a Neuralink-like connection to our platform, where
              it understands your unique talents and aspirations. We provide
              personalized job recommendations that align perfectly with your
              skills and ambitions, empowering you to reach for the stars in
              your career.
            </p>
          </div>
          <div className="row pt-5 pb-5  gx-0">
            <div className="col-md-4 mt-4">
              <div className="cardimg">
                <img src={box1} alt="box1" />
                <div className="cardbody3 mt-5">
                  <h4>Easy Applying</h4>
                  <p>The process for applying for jobs is easy and fast</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="cardimg">
                <img src={box2} alt="box1" />
                <div className="cardbody3 mt-5">
                  <h4>Many Vacancies</h4>
                  <p>There are many job vacancies from various companies</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="cardimg">
                <img src={box3} alt="box1" />
                <div className="cardbody3 mt-5">
                  <h4>Best Support</h4>
                  <p>
                    We provide full support for jobseekers to achieve nothing but the best
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*OUR SERVICE  */}
      <div>
        <div className="service">
          <div className="container ">
            <div className="row pb-4">
              <div className="col-lg-6 ourservice1">
                <h3 className="mt-4">
                  Our <span>Services</span>
                </h3>
                <p className="mt-4 ourservicepara">
                  Infletio is committed to being the best. Offering time-sensitive deadlines, quality-assurance, and immediate support.
                </p>
                {/* <button className="morebtn px-4 py-2">
                  More Details
                  <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
                </button> */}
              </div>
              <div className="col-lg-6 ourserviceleft">
                <h5 className="mt-4 me-5">
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                  Temporary / Full Time
                </h5>
                <p className="mt-3">
                  Offering flexibility on our front. We’re able to accommodate any staffing placement.
                </p>
                <h5 className="mt-4">
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                  Industries
                </h5>
                <p className="mt-3">
                  As of now, extending towards the Renewable Energy space + roles related to the Sales Industry
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* LOOKING FOR A JOB */}
      <div className="container text-center mt-5 lookingmain">
        <div className="row">
          <div className="col-md-12 mt-5 looking">
            <h2>
              Look For A<br />
              <span id="head"> Job Now</span>
            </h2>
            <p className="mt-4 mb-3">
              What other company cares about you, the planet, and is this fun?
            </p>
          </div>
          {/*  */}
          <div className="container">
            <div className="row justify-content-center gx-0 searchblog">
              <div className="col-lg-5 col-12 col-md-12">
                <div className="row d-flex  justify-content-center">
                  {/* <div className="col-lg-5  col-12 brdrght col-md-7">
                    <div className="jobcat">
                      <div className="">
                        <FontAwesomeIcon
                          icon={faClipboardList}
                          className="jobcategoryicon"
                        />
                      </div>
                      <div className="inp1">
                        <input
                          type="text"
                          placeholder="Category"
                          // style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-12 col-12 col-md-7 p-0">
                    <div className="jobloc">
                      <div className="d-flex searchrgt">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="joblocicon"
                        />

                        <div className="inp2">
                          <input
                            type="text"
                            placeholder="Location"
                            value={inputValue}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="jobsearch ">
                        <button className="jobsrcbtn">
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="jobsicon"
                          />
                          Search
                        </button>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          {/* <p className="popularcat mt-5">Popular Category</p> */}

          {/* <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 mb-3">
                <div className="row justify-content-center gx-0">
                  <div className="col-lg-2 col-md-6 col-6 paddrght">
                    <div className="con1 ">
                      <p className="para1">Associate Director</p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6" id="left">
                    <div className="con1 ">
                      <p className="para1">BI Analyst </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11 mb-3">
                <div className="row justify-content-center">
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA</p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6" id="left">
                    <div className="con1 ">
                      <p className="para1">BI Analyst </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11  mb-3 ">
                <div className="row justify-content-center">
                  <div className="col-lg-2  col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Officer</p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2  col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2  col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2  col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">BI Analyst</p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="morecategory mt-4"
              onClick={() => {
                navigate("/JobOpportunities");
                scrollToTop();
              }}
            >
              More Categories
              <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
            </button>
          </div> */}
        </div>
      </div>
      {/* avilablebox */}
      <div className="availablejobs">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-12 availablehead">
              <h3>
                Choose Our <span className="smallavil">Available Jobs</span>
              </h3>
              <p className=" availpara mt-3">
                Find the following job that suits you
              </p>
              {/* <button className="recent me-4 mt-4">Recent </button>
              <button className="popular">Popular </button> */}
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div>
            <div className="row">
              {data.slice(0, showAll ? data.length : 6).map((item, index) => (
                <div className="col-md-6 col-lg-4 mt-4" key={index}>
                  <div className="cardavail">
                    {item?.companyLogo?.length > 0 ? (
                      <img
                        src={ImageBaseUrl + item.companyLogo}
                        alt="..."
                        className="dummy"
                      />
                    ) : (
                      <img
                        src="./images/company-logo.png"
                        alt="..."
                        className="dummy"
                      />
                    )}

                    <h4 className="cardavailhead">{item.jobTitle}</h4>
                    <p className=" cardavailpara">{item.company}</p>
                    <div className="smalls ">
                      <span className="fulltime">{item.jobType}</span>
                      {/* <span className="Onsite ">Onsite</span> */}
                      <span className="thousands ">{item.salaryRange}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {!showAll && data.length > 1 && (
              <button className="showalljobs mt-4" onClick={handleViewMore}>
                Show All Jobs
                <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
              </button>
            )}
          </div>
        </div>
      </div>
      {/* OUR VALUES */}
      <div className="homeourvalues">
        <div className="container pb-5 ">
          {/* <div className="row"> */}
          <div className="homevalues text-center">
            <h3 className="">
              Our <span>Values</span>
            </h3>
            <p>
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. <br />
              Lorem Ipsum has been the industry's standard */}
            </p>
          </div>
          {/* ourvalbox 1 */}
          <div className="row ">
            <div className="col-lg-6 mt-5">
              <div className="firstrow">
                <div className="selfabt">
                  <img src={self1} className="selfim" alt="self1" />
                </div>
                <div className="firstpara">
                  <h5>Selflessness:</h5>
                  <p>
                    Infletio looks to provide before retrieving. Our model guarantees that our clients requests are completed before any payment. Infletio has a community-first mentality and actively looks to give back to the community with our Non-Profit cut.
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="col-lg-6 mt-5">
              <div className="firstrow mobtop">
                <div className="selfabt">
                  <img src={respect} className="selfim" alt="self1" />
                </div>
                <div className="firstpara">
                  <h5>Respect:</h5>
                  <p>
                    Our platform provides full transparency and looks to provide job-seekers and employers with mutual respect.
                  </p>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="col-lg-6 mt-5">
              <div className="secondrow">
                <div className="selfabt ">
                  <img src={determination} className="selfim" alt="self1" />
                </div>
                <div className="secondpara">
                  <h5> Determination:</h5>
                  <p>
                    Your request is our command. We promise to work with you to get the staffing you’re looking for, before any deadline agreed upon.
                  </p>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div className="col-lg-6 mt-5">
              <div className="secondrow">
                <div className="selfabt">
                  <img src={family} className="selfim" alt="self1" />
                </div>
                <div className="secondpara">
                  <h5 className="ms">Family:</h5>
                  <p>
                    The team at Infletio treats all our relationships
                    as family. After the staffing cycle is over, we maintain
                    relationship and look to support our clients for life. From
                    letters to gifts, the Infletio family goes above and beyond.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* ABOUT US */}
      <div className="container mt-5 aboutcon">
        <div className="row">
          <div className="col-lg-6 aboutus  aboutushme mt-5 ">
            <img
              className=" image-fluid aboutimg "
              src={aboutusimg}
              alt="abiut"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-lg-6 aboutright aboutrighthme mt-5">
            <h3>
              About <span className="abouthead">Us</span>
            </h3>
            <p className="aboutpara mt-4">
              In today's job market, there's a lack of support from the staffing
              industry for companies based in the Renewable Energy space.
            </p>
            <p className="aboutpara mt-3">
              At Infletio Consulting, we understand the unique demands of the renewable energy sector alongside GTM functionality.
            </p>
            <p className="aboutpara mt-3"> As a dedicated staffing partner, we offer comprehensive solutions tailored to meet the workforce needs of companies involved in renewable energy projects as well as any sales org.
            </p>
            <button
              className="aboutbtn mt-3 "
              onClick={() => navigate("/Aboutus/")}
            >
              About Us <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
            </button>
          </div>
        </div>
      </div>
      {/* news that help */}
      <div className="newshelp">
        <div className="container text-center ">
          <div className="row">
            <div className="col-lg-12 news mt-5">
              <h3>
                News That <span className="newshead">Helps</span>
              </h3>
              <p className="mt-3">
                Check out our content below. If there’s any questions or topics
                <br />
                you want us to cover, feel free to contact us!
              </p>
            </div>
          </div>
        </div>
        {/* box */}
        <div className="container">
          <div className="newsbox mt-5 pb-5">
            <div className="row pb-2 ">
              <div className="col-lg-4 col-md-4">
                <div className="card">
                  <img src={newshelp1} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      <span>Lifestyle</span>
                    </h5>
                    <p className="card-text mt-3">
                      How to win any job you want. Get started with 5 steps.
                    </p>
                    <div className="mt-2 read" href="">
                      Read More
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4" id="newsboxtop2">
                <div className="card">
                  <img src={newshelp2} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      <span>Lifestyle</span>
                    </h5>
                    <p className="card-text mt-3 ">
                      10 ways to reduce your office work depression.
                    </p>
                    <div className="mt-2 read" href="">
                      Read More
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4" id="newsboxtop3">
                <div className="card">
                  <img src={newshelp3} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      <span>Lifestyle</span>
                    </h5>
                    <p className="card-text mt-3">
                      Why should you work as a team even on small projects.
                    </p>
                    <div className="mt-2 read" href="">
                      Read More
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="newsbtn pb-5">
            <button className="newshelpbtn">
              View More <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
            </button>
          </div> */}
        </div>
      </div>
      {/* lets start */}
      <div className="container text-center mt-5 let-start">
        <div className="row">
          <div className="col-lg-12 mt-5 letstart">
            <h3>
              <span className="letstarthead"> Start your job hunt now!</span>
            </h3>
            <p className="mt-4">
              Join now and see what we have to offer!
            </p>
            <div className="subscribe mt-5">
              <div className="subscribes">
                <input
                  type="emailId"
                  placeholder="Enter Your Work Email"
                  onChange={(e) => setValues(e.target.value, "emailId")}
                />

                <button onClick={handleSubmit}>Join Now</button>
              </div>
            </div>

            <span>
              <img
                src="./image/arrowleft.png"
                className="img-fluid imgarrow1"
                alt="arrow"
              />
              <img
                src="./image/arrowright (2).png"
                className="img-fluid imgarrow2"
                alt="arrow"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homecontent;
