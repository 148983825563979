import AdminList from "../AdminManagement/AdminList";
import Companies from "../Companies";
import Jobs from "../Jobs";
import Blogs from "../Blogs";
import AdminCreate from "../AdminManagement/AdminCreate";
import AdminEdit from "../AdminManagement/AdminEdit";
import CategoryList from "../CategoryManagement/CategoryList";
import CategoryCreate from "../CategoryManagement/CategoryCreate";
import CategoryEdit from "../CategoryManagement/CategoryEdit";
import CompaniesList from "../CompaniesManagement/CompaniesList";
import CompanyCreate from "../CompaniesManagement/CompanyCreate";
import CompanyEdit from "../CompaniesManagement/CompanyEdit";
import ChangeNewPassword from "../ChangeNewPassword";
import MasterList from "../MaterdataMangement.j/MasterList";
import MasterCreate from "../MaterdataMangement.j/MasterCreate";
import ContactUsList from "../ContactUsManagement/ContactUsList";
import ContactUsSpecific from "../ContactUsManagement/ContactUsSpecific";
import Profile from "../Profile";
import BlogUpdate from "../Blog/BlogUpdate";
import BlogCreate from "../Blog/BlogCreate";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CreateJob from "../Job/CreateJob";
import UpdateJob from "../Job/UpdateJob";
import NewsletterList from "../ContactUsManagement/NewsLetter/NewsletterList";
export const routes = [
    
    {
        path: '/admin-list',
        component: AdminList
    },
    {
        path: '/companies-list',
        component: Companies
    },
    {
        path: '/jobs-list',
        component: Jobs
    },
    {
        path: '/jobs-Create',
        component: CreateJob
    },
    {
        path: '/Update-Job/:id',
        component: UpdateJob
    },
    
    {
        path: '/blog-list',
        component: Blogs
    },
    {
        path: '/BlogCreate',
        component: BlogCreate
    },
    {
        path: '/Blog-Edit/:id',
        component: BlogUpdate
    },
    {
        path: '/admin-create',
        component: AdminCreate
    },
    {
        path: '/admin-edit/:id',
        component: AdminEdit
    },
    {
        path: '/category-list',
        component: CategoryList
    },
    {
        path: '/category-create',
        component: CategoryCreate
    },
    {
        path: '/category-edit/:id',
        component: CategoryEdit
    },
    {
        path: '/company-list',
        component: CompaniesList
    },
    {
        path: '/company-create',
        component: CompanyCreate
    },
    {
        path: '/company-edit/:id',
        component: CompanyEdit
    },
    {
        path: '/change-new-password',
        component: ChangeNewPassword
    },
    {
        path: '/Profile',
        component: Profile
    },
    {
        path: '/master-Data',
        component: MasterList
    },
    {
        path: '/master-create',
        component: MasterCreate
    },
    {
        path: '/contact-us-list',
        component: ContactUsList
    },
   
    {
        path: '/contact-us/specificview/:id',
        component: ContactUsSpecific
    },
    {
        path: '/News-letter',
        component: NewsletterList
    },
]

