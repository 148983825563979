import React, { useEffect, useState } from 'react'
import { NonEmptyValidation, LengthValidation } from '../../../Utils/validate'
import { useNavigate, useParams } from 'react-router-dom'
import { UpdateCompany, Categorylist, SpecficContactUs, UploadFile } from '../../../Repository/Api'
import { useSelector } from 'react-redux'
import { errorMsg, successMsg } from '../../../Utils/Toastify'
import { CloudFrontUrl } from '../../../Utils/utils'
import CreatableSelect from 'react-select/creatable';
import moment from 'moment'
const ContactUsSpecific = () => {
    const [data, setData] = useState({})
    console.log(data)
    const [validation, setValidation] = useState({})
    const [password, setPassword] = useState(false)
    const [multioptions, setmultioptions] = useState([])
  const [file, setFile] = useState("");

    const [selectedOptions, setSelectedoptions] = useState([])
    const navigate = useNavigate()
    const { token } = useSelector((state) => state?.login)
    const { id } = useParams()
    const setValues = (value, key, max) => {
        setData({ ...data, [key]: value })
        if (validation[key]) setValidation({ ...validation, [key]: false })
        if (max && value.length === max) {
            setValidation({ ...validation, [key]: LengthValidation(value, max) })
            setTimeout(() => { setValidation({ ...validation, [key]: false }) }, 3000);
        }
    }
    const handleMultipleSelect = (opt, key) => {
        setSelectedoptions(opt)
        const data = opt.map((m) => m.value)
        console.log(data)
        setValues(data, key)
    }
    const togglepassword = () => {
        setPassword(!password)
    }
    const GetSpecificData = async () => {
        const response = await SpecficContactUs(id, token)
        console.log(response)
        if (response && response.statusCode === 200) {
            setData(response?.data)
            const data = response?.data?.categoryDetails.map((item, i) => ({
                value: item._id,
                label: item.title
            }))
            setSelectedoptions(data)
        }
    }
    const GetCategory = async () => {
        const res = await Categorylist(token)
        if (res && res.statusCode === 200) {
            const data = res?.data?.filter(f => f?.categoryType === 'company').map((m) => ({
                value: m._id,
                label: m.title
            }))
            setmultioptions(data)
        }
    }
    useEffect(() => {
        GetCategory()
        GetSpecificData()
    }, [])
    const UploadImage = async (e, key) => {
        setFile(URL.createObjectURL(e.target.files[0]));
        const file = e.target.files[0];
        // const maxSizeInBytes = 20 * 1024;
        // if (file.size > maxSizeInBytes) {
        //     errorMsg("File size exceeds the limit of 20KB");
        //     return;
        // }
        let formData = new FormData();
        // let str = file.name;
        formData.append("file", file);
        // formData.append("fileName", moment().format("YYYYMMDDHHmmss") + "_" + str);
        formData.append("path", "profile/");

        const response = await UploadFile(formData);
        if (response?.data?.statusCode === 200) {
            setValues(response?.data?.data?._id, key);
            successMsg(response.message);
        } else {
            errorMsg(response.message);
        }
    };
    let formdata = [
        {
            label: "Company Name",
            type: 'text',
            value: data.name,
            placeholder: "Enter Company Name",
            key: "name",
            valid: NonEmptyValidation,
            required: true,
            max: 20,
        },
        {
            label: "Description",
            type: 'text',
            value: data.description,
            placeholder: "Enter Description",
            key: "description",
            valid: NonEmptyValidation,
            required: true,
            max: 60
        },
        {
            label: "Category",
            type: 'multiselect',
            value: selectedOptions,
            placeholder: "Select Categories",
            key: "categoryId",
            valid: NonEmptyValidation,
            required: true,
            option: multioptions
        },
        {
            label: "Company Icon",
            type: 'file',
            value: data.icon,
            accept: "image/png,image/webp",
            key: "companyIcon",
            required: true,
            valid: NonEmptyValidation,
            // hint: 'Max Image Size 20kb'
        }
    ]
    const handlesubmit = async (e) => {
        e.preventDefault()
        let validate = {};
        formdata.forEach(field => {
            validate[field.key] = field.valid(field.value);
        });
        setValidation(validate)
        if (Object.values(validate).every(v => v.status === true)) {
            const payload = {
                _id: data._id,
                categoryId: data.categoryId,
                name: data.name,
                description: data.description
            }
            const response = await UpdateCompany(payload, token)
            if (response && response.statusCode === 200) {
                successMsg(response?.message)
                setTimeout(() => {
                    navigate(-1)
                }, 1000);
            } else {
                errorMsg(response?.message)
            }
        }
    }

    return (
        <div>
            <div className='content-header'>
                <p>
                    <span>
                        <i
                            class="fa-regular fa-circle-left me-3"
                            onClick={() => navigate(-1)}
                        />
                    </span>
                    Contact Specific View
                </p>
            </div>
            <div className='content-body'>
                <div className='row gx-5'>
                    {
                        formdata.map((item, i) => {
                            return (
                                <div className='col-lg-6' key={i}>
                                    <div className='d-flex flex-column create-input'>
                                        <label>
                                            {item.label} {item.required && <span className='star-mark'>*</span>}
                                        </label>
                                        {item.type === 'select' ? (
                                            <select
                                                value={item.value}
                                                onChange={(e) => setValues(e.target.value, item.key)}
                                                onBlur={(e) => setValidation({
                                                    ...validation,
                                                    [item.key]: item.valid(e.target.value)
                                                })}
                                            >
                                                <option value="">{item.placeholder}</option>
                                                {item.option.map((option, index) => (
                                                    <option key={index} value={option.key}>{option.value}</option>
                                                ))}
                                            </select>
                                        ) :
                                            (
                                                item.type == 'file' ? (
                                                    <div>
                                                        <input
                                                            type={item.type}
                                                            id={item.key}
                                                            className='d-none'
                                                            onChange={(e) => UploadImage(e, item.key)}
                                                            accept={item.accept}
                                                        />
                                                        <label
                                                            for={item.key}
                                                            className="btn-delete">
                                                            Upload Image
                                                        </label>
                                                        {file?.length > 0 && (
                <img
                  width={"100px"}
                  height={"100px"}
                  src={file}
                  alt="Uploaded"
                />
              )}
                                                    </div>
                                                ) : (
                                                    item.type === 'multiselect' ? (
                                                        <CreatableSelect
                                                            placeholder={item.placeholder}
                                                            isMulti
                                                            options={item.option}
                                                            value={selectedOptions}
                                                            onChange={(e) => {
                                                                console.log(e)
                                                                handleMultipleSelect(e, item.key)
                                                            }}
                                                            isValidNewOption={() => false}
                                                            noOptionsMessage={() => null}
                                                        />
                                                    ) : (
                                                        <input
                                                            type={item.type !== "password" ? item.type : (password ? "text" : item.type)}
                                                            defaultValue={item.value}
                                                            placeholder={item.placeholder}
                                                            maxLength={item.max}
                                                            onChange={(e) => setValues(e.target.value, item.key, item.max)}
                                                            onBlur={(e) => setValidation({
                                                                ...validation,
                                                                [item.key]: item.valid(e.target.value)
                                                            })}
                                                        />
                                                    )
                                                )
                                            )

                                        }
                                        {item.type === "password" && (
                                            <>
                                                {!password ?
                                                    <i className="fa-solid fa-eye" onClick={togglepassword}></i> :
                                                    <i className="fa-solid fa-eye-slash" onClick={togglepassword}>
                                                    </i>
                                                }
                                            </>
                                        )}
                                        <small className='max-hint'>
                                            {item?.max ?
                                                <>
                                                    ({`Maximum ${item.max} Characters`})
                                                </> :
                                                item?.hint &&
                                                <>
                                                    {item?.hint}
                                                </>
                                            }

                                        </small>
                                        <small className='validation-error error-space'>
                                            {validation?.[item.key]?.message ?
                                                `${item.label} ${validation?.[item.key]?.message}` : ""
                                            }
                                        </small>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default ContactUsSpecific