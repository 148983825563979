import React, { useEffect, useState } from "react";
import {
  EmailValidation,
  NonEmptyValidation,
  PasswordValidation,
  StringValidation,
} from "../../../Utils/validate";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { UpdateAdmin, SpecficAdmin, UploadFile } from "../../../Repository/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../../Utils/Toastify";
const AdminEdit = () => {
  const [data, setData] = useState({});
  console.log(data);
  const [validation, setValidation] = useState({});
  const [password, setPassword] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state?.login);
  const setValues = (value, key) => {
    setData({ ...data, [key]: value });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const togglepassword = () => {
    setPassword(!password);
  };
  const GetAdmin = async () => {
    const response = await SpecficAdmin(id, token);
    if (response && response.statusCode === 200) {
      setData(response?.data);
    }
  };
  const UploadImage = async (e, key) => {
    const file = e.target.files[0];
    // const maxSizeInBytes = 500 * 1024;
    // if (file.size > maxSizeInBytes) {
    //   errorMsg("File size exceeds the limit of 500KB");
    //   return;
    // }
    let formData = new FormData();
  
    formData.append("file", file);

    formData.append("path", "profile/");

    const response = await UploadFile(formData);
    if (response?.data?.statusCode === 200) {
        setValues(response?.data?.data?._id, key);
      successMsg(response.message);
    } else {
      errorMsg(response.message);
    }
  };
  useEffect(() => {
    GetAdmin();
  }, []);

  const formdata = [
    {
      label: "First Name",
      type: "text",
      value: data.firstName,
      placeholder: "Enter First Name",
      key: "firstName",
      valid: StringValidation,
      required: true,
    },
    {
      label: "Last Name",
      type: "text",
      value: data.lastName,
      placeholder: "Enter Last Name",
      key: "lastName",
      valid: StringValidation,
      required: true,
    },
    {
      label: "Email ID",
      type: "email",
      value: data.emailId,
      placeholder: "Enter Email ID",
      key: "emailId",
      valid: EmailValidation,
      required: true,
    },
    {
      label: "Profile Photo",
      type: "file",
      value: data.profileImage,
      accept: "image/jpeg, image/png,image/webp",
      key: "profileImage",
      required: false,
      id: "Adminditimg",
    },
  ];

  const handlesubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    formdata.forEach((field) => {
      if (field?.valid) {
        validate[field.key] = field.valid(field.value);
      }
    });
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        profileImage: data.profileImage,
        _id: id,
      };
      const response = await UpdateAdmin(payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response?.message);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        errorMsg(response?.message);
      }
    }
  };

  return (
    <div>
      <div className="content-header">
        <p>
          <span>
            <i
              class="fa-regular fa-circle-left me-3"
              onClick={() => navigate(-1)}
            />
          </span>
          Admin Update
        </p>
        {/* <button className="admin-btn" onClick={handlesubmit}>
          Update Admin
        </button> */}
      </div>
      <div className="content-body">
        <div className="row gx-5">
          {formdata.map((item, i) => {
            return (
              <div className="col-lg-6">
                <div className="d-flex flex-column create-input">
                  <label>
                    {item.label} {item.required && <span>*</span>}
                  </label>
                  <input
                    type={
                      item.type !== "password"
                        ? item.type
                        : password
                        ? "text"
                        : item.type
                    }
                    defaultValue={item.value}
                    placeholder={item.placeholder}
                    disabled={item.type === "email" && true}
                    {...(item.type !== "file"
                      ? {
                          onChange: (e) => setValues(e.target.value, item.key),
                        }
                      : null)}
                    {...(item.type !== "file" && {
                      onBlur: (e) =>
                        setValidation({
                          ...validation,
                          [item.key]: item.valid(e.target.value),
                        }),
                    })}
                  />
                  {item?.type === "file" && (
                    <>
                      <input
                        type="file"
                        className="input-file d-none"
                        onChange={(e) => UploadImage(e, item.key)}
                        id="blogeditimg"
                        accept="image/jpeg, image/png,image/webp"
                      />

                      <label for="blogeditimg" className="alt-btn">
                        Upload Profile Photo
                      </label>
                    </>

                    // {...(item.type === "file" && { accept: item.accept })}
                  )}
                  {item.type === "password" && (
                    <>
                      {!password ? (
                        <i class="fa-solid fa-eye" onClick={togglepassword}></i>
                      ) : (
                        <i
                          class="fa-solid fa-eye-slash"
                          onClick={togglepassword}
                        ></i>
                      )}
                    </>
                  )}
                  <small className="validation-error error-space">
                    {validation?.[item.key]?.message
                      ? `${item.label} ${validation?.[item.key]?.message}`
                      : ""}
                  </small>
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-center">
          <button className="admin-btn" onClick={handlesubmit}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminEdit;
