import React, { useState } from "react";
import { NonEmptyValidation, LengthValidation } from "../../../Utils/validate";
import { useNavigate } from "react-router-dom";
import { CreateCategory, UploadFile } from "../../../Repository/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../../Utils/Toastify";
import { CloudFrontUrl } from "../../../Utils/utils";
import moment from "moment";
const CategoryCreate = () => {
  const [data, setData] = useState({});
  console.log(data);
  const [validation, setValidation] = useState({});
  const [password, setPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state?.login);
  const setValues = (value, key, max) => {
    setData({ ...data, [key]: value });
    if (validation[key]) setValidation({ ...validation, [key]: false });
    // if (value.length === max) {
    //   setValidation({ ...validation, [key]: LengthValidation(value, max) });
    //   setTimeout(() => {
    //     setValidation({ ...validation, [key]: false });
    //   }, 3000);
    // }
  };

  const togglepassword = () => {
    setPassword(!password);
  };
  const [file, setFile] = useState("");
  const UploadImage = async (e, key) => {
    console.log(e, key, "e, key");
    setFile(URL.createObjectURL(e.target.files[0]));

    const file = e.target.files[0];
    // const maxSizeInBytes = 20 * 1024;
    // if (file.size > maxSizeInBytes) {
    //     errorMsg("File size exceeds the limit of 20KB");
    //     return;
    // }

    let formData = new FormData();
    let str = file.name;
    formData.append("file", file);

    formData.append("path", "Category/");

    const response = await UploadFile(formData);

 
    if (response?.data?.statusCode === 200) {
      setValues(response?.data?.data?._id, key);

      successMsg(response.message);
    } else {
      errorMsg(response.message);
    }
  };
  let formdata = [
    {
      label: "Category Type",
      type: "select",
      value: data.categoryType,
      options: [
        { key: "company", value: "Company" },
        { key: "job", value: "Job" },
      ],
      placeholder: "Select Category Type",
      key: "categoryType",
      valid: NonEmptyValidation,
      required: true,
    },
    {
      label: "Category Title",
      type: "text",
      value: data.title,
      placeholder: "Enter Category Title",
      key: "title",
      valid: NonEmptyValidation,
      required: true,
      max: 20,
    },
  ];

  if (data.categoryType === "company") {
    formdata.push({
      label: "Category Logo",
      type: "file",
      value: data.icon,
      accept: "image/png,image/webp",
      key: "icon",
      valid: NonEmptyValidation,
      required: true,
      // hint: 'Max Image Size 20kb'
    });
  }
  const handlesubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    formdata.forEach((field) => {
      validate[field.key] = field.valid(field.value);
    });
    setValidation(validate);
    console.log(validate,"validate")
    if (Object.values(validate).every((v) => v.status === true)) {
      const payload = {
        ...data,
      };
      const response = await CreateCategory(payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response?.message);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        errorMsg(response?.message);
      }
    }
  };

  return (
    <div>
      <div className="content-header">
        <p>
          <span>
            <i
              class="fa-regular fa-circle-left me-3"
              onClick={() => navigate(-1)}
            />
          </span>
          Category Create
        </p>
        {/* <button className="admin-btn" onClick={handlesubmit}>
          Create Category
        </button> */}
      </div>
      <div className="content-body">
        <div className="row gx-5">
          {formdata.map((item, i) => {
            return (
              <div className="col-lg-6" key={i}>
                <div className="d-flex flex-column create-input">
                  <label>
                    {item.label}{" "}
                    {item.required && <span className="star-mark">*</span>}
                  </label>
                  {item.type === "select" ? (
                    <select
                      value={item.value}
                      onChange={(e) => setValues(e.target.value, item.key)}
                      onBlur={(e) =>
                        setValidation({
                          ...validation,
                          [item.key]: item.valid(e.target.value),
                        })
                      }
                    >
                      <option value="">{item.placeholder}</option>
                      {item.options.map((option, index) => (
                        <option key={index} value={option.key}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                  ) : item.type == "file" ? (
                    <div>
                      <input
                        type={item.type}
                        id={item.key}
                        className="d-none"
                        onChange={(e) => UploadImage(e, item.key)}
                        accept={item.accept}
                      />
                      <label for={item.key} className="btn-delete">
                        Upload Image
                      </label>
                      {file?.length > 0 && (
                        <img
                          width={"100px"}
                          height={"100px"}
                          src={file}
                          alt="Uploaded"
                        />
                      )}
                    </div>
                  ) : (
                    <input
                      type={
                        item.type !== "password"
                          ? item.type
                          : password
                          ? "text"
                          : item.type
                      }
                      value={item.value}
                      placeholder={item.placeholder}
                      maxLength={item.max}
                      onChange={(e) =>
                        setValues(e.target.value, item.key, item.max)
                      }
                      onBlur={(e) =>
                        setValidation({
                          ...validation,
                          [item.key]: item.valid(e.target.value),
                        })
                      }
                    />
                  )}
                  {item.type === "password" && (
                    <>
                      {!password ? (
                        <i
                          className="fa-solid fa-eye"
                          onClick={togglepassword}
                        ></i>
                      ) : (
                        <i
                          className="fa-solid fa-eye-slash"
                          onClick={togglepassword}
                        ></i>
                      )}
                    </>
                  )}
                  <small className="max-hint">
                    {item?.max ? (
                      <>({`Maximum ${item.max} Characters`})</>
                    ) : (
                      item?.hint && <>{item?.hint}</>
                    )}
                  </small>
                  <small className="validation-error error-space">
                    {validation?.[item.key]?.message
                      ? `${item.label} ${validation?.[item.key]?.message}`
                      : ""}
                  </small>
                </div>
              </div>
            );
          })}
          <div className="d-flex justify-content-center mt-5">
            <button className="admin-btn" onClick={handlesubmit}>
              Create Category
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCreate;
