import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { MuiTableOptions } from "../../../Utils/utils";
import {
  DeleteMaster,
  MasterDatalist,
  UpdateMasterData,
} from "../../../Repository/Api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { successMsg } from "../../../Utils/Toastify";
import DeleteModal from "../../../Utils/DeleteModal";
const MasterList = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteid, setDeleteid] = useState();
  const { token, userId } = useSelector((state) => state?.login);
  const AllTabs = ["Pending", "Approved", "Rejected"];

  //* React State Variables
  // [pending, approved, rejected, all]
  const [selectedTab, setSelectedTab] = useState("pending");
  const navigate = useNavigate();
  const GetData = async () => {
    const response = await MasterDatalist(selectedTab, token);
    if (response && response.statusCode === 200) {
      setData(response?.data);
    }
  };
  const handleStatus = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const response = await UpdateMasterData(Payload, token);
    if (response && response?.statusCode === 200) {
      successMsg(response?.message);
      if (value === "approved") {
        setSelectedTab("approved");
        GetData("approved");
      }
      if (value === "rejected") {
        setSelectedTab("rejected");
        GetData("rejected");
      }
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "masterValue",
      label: "Name",
    },
    {
      name: "masterType",
      label: "Master Type",
      options: {
        customBodyRender: (value) => {
          // Define key-value pairs for mapping
          const data = [
            { key: "jobTitle", value: "Job Title" },
            { key: "experience", value: "Experience" },
            { key: "jobType", value: "Job Type" },
            { key: "salaryRange", value: "Salary Range" },
            { key: "skills", value: "Skills" },
            { key: "qualification", value: "Qualification" }
          ];
    
          // Find the matching entry
          const entry = data.find(item => item.key === value);
    
          // Return the corresponding value or a default message
          return entry ? <span>{entry.value}</span> : <span>Unknown</span>;
        },
      },
    }
,    

    {
      name: "status",
      label: "Status",
      options: {
        display: false,
      },
    },

    {
      name: "",
      label: "Approval",
      options: {
        filter: false,
        //   display: (userActions.update === false ? false : true),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="flex flex-row">
              {selectedTab === "pending" && (
                <>
                  <span
                    className="cursor-auto cursor mx-3"
                    onClick={(e) => {
                      handleStatus(e, "approved", tableMeta.rowData[0]);
                    }}
                  >
                    <span class="material-symbols-outlined text-info">
                      task_alt
                    </span>
                  </span>
                  <span
                    className="cursor-pointer cursor mx-3"
                    onClick={(e) => {
                      handleStatus(e, "rejected", tableMeta.rowData[0]);
                    }}
                  >
                    <span class="material-symbols-outlined text-danger">
                      cancel
                    </span>
                  </span>
                </>
              )}

              {selectedTab === "approved" && (
                <span
                  className="cursor-pointer  cursor mx-3"
                  onClick={(e) => {
                    handleStatus(e, "rejected", tableMeta.rowData[0]);
                  }}
                >
                  <span class="material-symbols-outlined text-danger">
                    cancel
                  </span>
                </span>
              )}
              {selectedTab === "rejected" && (
                <span
                  className="cursor-pointer cursor mx-3"
                  onClick={(e) => {
                    handleStatus(e, "approved", tableMeta.rowData[0]);
                  }}
                >
                  <span class="material-symbols-outlined text-info">
                    task_alt
                  </span>
                </span>
              )}
            </div>
          );
        },
      },
    },

    //   {
    //     name: "",
    //     label: "Actions",
    //     options: {
    //         customBodyRender: (value, metaData) => {
    //             console.log(metaData)
    //             const status = metaData?.rowData[4] === "active" ? true : false
    //             const id = metaData?.rowData[0]
    //             return (
    //                 <div className='action-icons'>

    //                     <i
    //                         class="fa-solid fa-trash"
    //                         onClick={() => {
    //                             setOpen(true)
    //                             setDeleteid(metaData?.rowData[0])
    //                         }}
    //                     />

    //                 </div>
    //             )
    //         }
    //     }
    // }
  ];

  useEffect(() => {
    GetData("Pending");
  }, [selectedTab]);
  return (
    <div className="table-catogory-height">
      <div className="content-header">
        <p>Master Data Management</p>
        <div>
          <button
            onClick={() => navigate("/admin/master-create")}
            className="admin-btn"
          >
            Add Master-Data
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between gap-4 text-sm">
        <div className="tab-section">
          <ul className="ps-0">
            {AllTabs.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={
                    selectedTab === tab.toLocaleLowerCase()
                      ? "serviceTab active"
                      : "serviceTab inactive"
                  }
                  onClick={() => {
                    setSelectedTab(tab.toLocaleLowerCase());
                    if (tab.toLocaleLowerCase() === "pending")
                      if (tab.toLocaleLowerCase() === "approved")
                        if (tab.toLocaleLowerCase() === "rejected");
                  }}
                >
                  <li>
                    <span className="align-middle">{tab}</span>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>

      <DeleteModal
        open={open}
        setOpen={() => {
          setOpen(false);
        }}
        api={DeleteMaster}
        id={deleteid}
        token={token}
        get={GetData}
      />
      <MUIDataTable
        title="Master Data Management"
        data={data}
        columns={columns}
        options={MuiTableOptions}
      />
    </div>
  );
};

export default MasterList;
