import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginSlice from './LoginSlice'
import RegisterSlice from "./RegisterSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import { thunk } from "redux-thunk";
const persistConfig = {
    key: "infletio",
    storage,
};

const rootReducer = combineReducers({
    login: loginSlice,
    register: RegisterSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    // If thunk error
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    // import {thunk} from “redux-thunk”
    devTools: process.env.NODE_ENV !== "production",
});

const persistor = persistStore(store);

export { store, persistor };
