import React, { useEffect, useRef, useState } from "react";
import "./Blogs.css";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { BlogGet, BlogRecent, ImageBaseUrl } from "../../Repository/Api";
import { useSelector } from "react-redux";
import NoData from "../../Assets/Pictures/noblogfound.png";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import { truncateText } from "../../Utils/utils";

const Blogs = () => {
  const scrollToTop = () => {
    window.scrollTo(400, 400);
  };
  const scrollToTops = () => {
    window.scrollTo(0, 0);
  };
  const { token } = useSelector((state) => state?.login);
  const listRef = useRef(null);
  const [blogList, setBlogList] = useState([]);
  const [blogPopularList, setBlogPopularList] = useState([]);
  const [blogRecentList, setBlogRecentList] = useState([]);

  const navigate = useNavigate();

  const GetData = async () => {
    const response = await BlogGet(token);
    if (response && response.statusCode === 200) {
      setBlogRecentList(
        response?.data?.filter(
          (blog, index) => blog?.status === "active" && index === 0
        )
      );
      setBlogList(response?.data?.filter(
        (blog, index) => blog?.status === "active"
      ));
    } else {
      setBlogRecentList([]);
      setBlogList([]);
    }
  };

  const GetDataRecent = async () => {
    const response = await BlogRecent(token);
    if (response && response.statusCode === 200) {
      setBlogPopularList(
        response?.data?.filter((blog, index) => blog?.status === "active")
      );
    } else {
      setBlogRecentList([]);
      setBlogList([]);
    }
  };

  useEffect(() => {
    GetData();
    GetDataRecent();
  }, [token]);


  const [currentPage, setCurrentPage] = useState(2);

  const onPageChange = (page) => {
    setCurrentPage(page);

    // listRef.current.scrollToOffset({ offset: 0, animated: true });
  };
  const itemsPerPage = 6;

  useEffect(() => {
    onPageChange(1);
  }, []);
  const totalPages = Math.ceil((blogList?.length || 0) / itemsPerPage);

  return (
    <div>
      <span>
        <Navbar />
      </span>
      <div className="Blogbanner text-center pt-5 pb-5">
        <h1>
          News That <span>Helps</span>
        </h1>
       
      </div>
      <div className="blogtabs">
        {/* <a href="#..." className="ms-5 tablink"> */}
        <p className="mb-0"> All Blog</p>
        {/* </a> */}
      </div>
      {/* RECENTLY POST */}

      {console.log(blogRecentList, "blogRecentList")}
      {blogRecentList?.length === 0 ? (
        <>
          <div class="d-flex justify-content-center">
            {" "}
            <img src={NoData} className="NoblogImage" />{" "}
          </div>
        </>
      ) : (
        <>
          {blogRecentList?.map((item, index) => (
            <div className="container" key={index}>
              <div className="row">
                <div className="col-lg-7">
                  <h5 className="mt-5 blogtophead">
                    Recently <span>Posted</span>
                  </h5>
                  <div className="recentlypost mt-3">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="recentfrstflex">
                          {item?.image?.length > 0 ? (
                            <img
                              src={ImageBaseUrl + item.image}
                              className="recentpostpng"
                              alt=".."
                            />
                          ) : (
                            <img
                              src="./image/recentpost.png"
                              className="recentpostpng"
                              alt=".."
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="recpostcont">
                          {/* <p className="scrollcar ms-4">
                          <span>Career</span>
                            </p> */}
                          <h4 className=" mt-3">{item?.title}</h4>
                          <div className="d-flex align-items-center allpostbot  mt-3">
                            <img
                              className="Blog-pro-image"
                              src={ImageBaseUrl + item?.authorImage}
                              alt="..."
                            />
                            <p className="ms-1"> {item?.author}</p>
                            <FontAwesomeIcon
                              icon={faCalendarDay}
                              className="calenicon ms-2"
                              style={{ color: "rgba(85, 85, 85, 1)" }}
                            />
                            <p className="ms-1">
                              {moment(item?.publishedAt).format("MMM Do YY")}{" "}
                            </p>
                            <FontAwesomeIcon
                              icon={faClock}
                              className="clockiconrecent ms-2"
                              style={{ color: "rgba(85, 85, 85, 1)" }}
                            />
                            <p className="ms-1">{`${item?.minutes} Min.To Read`}</p>
                          </div>

                          <div>
                            <p className="mt-3 recentcontpara">
                              <div
                                className="faq-body"
                                dangerouslySetInnerHTML={{
                                  __html: truncateText(item?.content, 15),
                                }}
                              />

                              {item?.content.length > 15 && (
                                <div
                                  onClick={() => {
                                    navigate(`/BlogDetails/${item?._id}`);
                                    scrollToTops();
                                  }}
                                  className="allblogread "
                                >
                                  Read More
                                </div>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ALL BLOGS */}
                  <>
                    {/* {blogList
                      ?.filter(
                        (blog, index) =>
                          blog?.status === "active" && index !== 0
                      ) 
                      ?.slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage
                      )
                      ?.map((item) => ( */}
                    <>
                      <h5 className="mt-5 blogtophead">
                        All <span>Blogs</span>
                      </h5>
                    </>
                    {/* ))} */}
                    <div className="row">
                      {blogList
                        ?.filter(
                          (blog, index) =>
                            blog?.status === "active" && index !== 0
                        )
                        ?.slice(
                          (currentPage - 1) * itemsPerPage,
                          currentPage * itemsPerPage
                        )
                        ?.map((item) => (
                          <>
                            <div className="col-lg-6 mt-4" key={item.id}>
                              <div className="card-all-blogs">
                                <img
                                  src={ImageBaseUrl + item?.image}
                                  className="card-img-top blog-img-des "
                                  alt="..."
                                />
                                <div className="card-body">
                                  <p className="card-text-allblogs">
                                    {item?.title}
                                  </p>
                                  <div
                                    onClick={() => {
                                      navigate(`/BlogDetails/${item?._id}`);
                                      scrollToTops();
                                    }}
                                    className="allblogread"
                                  >
                                    Read More
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </>
                </div>
                {/*  POPULAR POST*/}
                <div className="col-lg-5  popularrght">
                  <div className="container">
                    <div className="popularpost mt-5">
                      <h5 className="blogtophead">
                        Popular <span>Post</span>
                      </h5>
                      <div className="scrollbar mt-3">
                        {/* <div className="scrollbox"> */}
                        <div className="scrollboxcon">
                          {blogPopularList.slice(0, blogPopularList.length && 5).map((item, index) => (

                            <>
                              <div className="sides mt-5" >
                                <div className="row">
                                  <div className="scrollimg col-lg-5">
                                    <img
                                      src={ImageBaseUrl + item?.image}
                                      alt=""
                                    />
                                  </div>
                                  <div className="scrollboxright col-lg-7">
                                    <div className="scrollboxrighthead">
                                      <p className="">{item?.title}</p>
                                    </div>
                                    <div className="d-flex align-items-center scrollboxbot mt-3">
                                      <img
                                        src={ImageBaseUrl + item?.authorImage}
                                        alt="..."
                                      />
                                      <p className=""> {item?.author}</p>
                                      <FontAwesomeIcon
                                        icon={faClock}
                                        className="clockicon "
                                      />
                                      <p className="">
                                        {item?.minutes} Min.To Read
                                      </p>
                                    </div>
                                    <div className=" mt-4">
                                      <div
                                        onClick={() => {
                                          navigate(`/BlogDetails/${item?._id}`);
                                          scrollToTops();
                                        }}
                                        className="allblogread"
                                      >
                                        Read More
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                        {/* </div> */}
                        {/*  */}

                        {/*  */}
                        {/* <div className="scrollbox mt-5">
                            <div className=" d-flex">
                              <img src="./image/scrolljpg.png" alt="" />
                              <div className="scrollboxcon">
                                <p className="scrollcar ms-4">
                                  <span>Career</span>
                                </p>
                                <p className="mt-2 ms-4">
                                  How to win any job you want.Get started with 5
                                </p>
                                <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                                  <img src="./image/jesicakoli.png" alt="..." />
                                  <p className="ms-2">Jesica Koli</p>
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="clockicon ms-3"
                                  />
                                  <p className="ms-2">3 Min.To Read</p>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        {/*  */}
                        {/* <div className="scrollbox mt-5">
                            <div className=" d-flex">
                              <img src="./image/scrolljpg.png" alt="" />
                              <div className="scrollboxcon">
                                <p className="scrollcar ms-4">
                                  <span>Career</span>
                                </p>
                                <p className="mt-2 ms-4">
                                  How to win any job you want.Get started with 5
                                </p>
                                <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                                  <img src="./image/jesicakoli.png" alt="..." />
                                  <p className="ms-2">Jesica Koli</p>
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="clockicon ms-3"
                                  />
                                  <p className="ms-2">3 Min.To Read</p>
                                </div>
                              </div>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </div>
                  {/* Company NEWS */}
                  {/* <div className="container">
                    <div className="companynews">
                      <h5 className="mt-5 blogtophead">
                        Company<span>News</span>
                      </h5>
                      <div className="mt-4">
                        <div className="scrollbox">
                          <div className=" d-flex">
                            <img src="./image/scrolljpg.png" alt="" />
                            <div className="scrollboxcon">
                              <p className="scrollcar ms-4">
                                <span>Career</span>
                              </p>
                              <p className="mt-2 ms-4">
                                How to win any job you want.Get started with 5
                              </p>
                              <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                                <img src="./image/jesicakoli.png" alt="..." />
                                <p className="ms-2">Jesica Koli</p>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="clockicon ms-3"
                                />
                                <p className="ms-2">3 Min.To Read</p>
                              </div>
                            </div>
                          </div>
                        </div>
                     
                        <div className="scrollbox mt-3">
                          <div className=" d-flex">
                            <img src="./image/scrolljpg.png" alt="" />
                            <div className="scrollboxcon">
                              <p className="scrollcar ms-4">
                                <span>Career</span>
                              </p>
                              <p className="mt-2 ms-4">
                                How to win any job you want.Get started with 5
                              </p>
                              <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                                <img src="./image/jesicakoli.png" alt="..." />
                                <p className="ms-2">Jesica Koli</p>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="clockicon ms-3"
                                />
                                <p className="ms-2">3 Min.To Read</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* PRODUCT & INNOVATION */}
                  {/* <div className="container">
                    <div className="product-innovation">
                      <h5 className="mt-5 blogtophead">
                        Product & <span>Innovation</span>
                      </h5>
                      <div className="mt-4">
                        <div className="scrollbox">
                          <div className=" d-flex">
                            <img src="./image/scrolljpg.png" alt="" />
                            <div className="scrollboxcon">
                              <p className="scrollcar ms-4">
                                <span>Career</span>
                              </p>
                              <p className="mt-2 ms-4">
                                How to win any job you want.Get started with 5
                              </p>
                              <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                                <img src="./image/jesicakoli.png" alt="..." />
                                <p className="ms-2">Jesica Koli</p>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="clockicon ms-3"
                                />
                                <p className="ms-2">3 Min.To Read</p>
                              </div>
                            </div>
                          </div>
                        </div>
                
                        <div className="scrollbox mt-3">
                          <div className=" d-flex">
                            <img src="./image/scrolljpg.png" alt="" />
                            <div className="scrollboxcon">
                              <p className="scrollcar ms-4">
                                <span>Career</span>
                              </p>
                              <p className="mt-2 ms-4">
                                How to win any job you want.Get started with 5
                              </p>
                              <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                                <img src="./image/jesicakoli.png" alt="..." />
                                <p className="ms-2">Jesica Koli</p>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="clockicon ms-3"
                                />
                                <p className="ms-2">3 Min.To Read</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="container"></div> */}
                  {/* People nd Culture */}
                  {/* <div className="container">
                    <div className="product-innovation">
                      <h5 className="mt-5 blogtophead">
                        People & <span>Culture</span>
                      </h5>
                      <div className="mt-4">
                        <div className="scrollbox">
                          <div className=" d-flex">
                            <img src="./image/scrolljpg.png" alt="" />
                            <div className="scrollboxcon">
                              <p className="scrollcar ms-4">
                                <span>Career</span>
                              </p>
                              <p className="mt-2 ms-4">
                                How to win any job you want.Get started with 5
                              </p>
                              <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                                <img src="./image/jesicakoli.png" alt="..." />
                                <p className="ms-2">Jesica Koli</p>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="clockicon ms-3"
                                />
                                <p className="ms-2">3 Min.To Read</p>
                              </div>
                            </div>
                          </div>
                        </div>
                  
                        <div className="scrollbox mt-3">
                          <div className=" d-flex">
                            <img src="./image/scrolljpg.png" alt="" />
                            <div className="scrollboxcon">
                              <p className="scrollcar ms-4">
                                <span>Career</span>
                              </p>
                              <p className="mt-2 ms-4">
                                How to win any job you want.Get started with 5
                              </p>
                              <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                                <img src="./image/jesicakoli.png" alt="..." />
                                <p className="ms-2">Jesica Koli</p>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="clockicon ms-3"
                                />
                                <p className="ms-2">3 Min.To Read</p>
                              </div>
                            </div>
                          </div>
                        </div>
                    
                        <div className="scrollbox mt-3">
                          <div className=" d-flex">
                            <img src="./image/scrolljpg.png" alt="" />
                            <div className="scrollboxcon">
                              <p className="scrollcar ms-4">
                                <span>Career</span>
                              </p>
                              <p className="mt-2 ms-4">
                                How to win any job you want.Get started with 5
                              </p>
                              <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                                <img src="./image/jesicakoli.png" alt="..." />
                                <p className="ms-2">Jesica Koli</p>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="clockicon ms-3"
                                />
                                <p className="ms-2">3 Min.To Read</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </>
      )}

      {/*  */}
      {/* {blogList
        ?.filter(
          (blog, index) =>
            blog?.status === "active" && index !== 0
        ) 
        ?.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        )
        ?.map((item) => ( */}
      <>
        <div>
          <nav aria-label="Page navigation example ">
            <ul class="pagination justify-content-center mt-5">
              <li
                className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                // onClick={() => onPageChange(currentPage - 1)}
                // disabled={currentPage === 1}
                onClick={() => {
                  if (currentPage !== 1) {
                    onPageChange(currentPage - 1);
                    scrollToTop();
                  }
                }}
              >
                <a
                  class="page-link"
                // onClick={() => {
                //   onPageChange(currentPage - 1);
                //   scrollToTop();
                // }}
                // disabled={currentPage === 0}

                >

                  Previous
                </a>
              </li>
              {[...Array(totalPages)].map((_, index) => (
                <li
                  class="page-item"
                  key={index}
                  onClick={() => {
                    onPageChange(index + 1);
                    scrollToTop();
                  }}
                >
                  <a
                    class="page-link"

                    style={{
                      backgroundColor:
                        currentPage === index + 1 ? " #10bea9" : "#fff",
                      color: currentPage === index + 1 ? " white" : "#000",
                    }}
                  >
                    {index + 1}
                  </a>
                </li>
              ))}

              <li
                className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                // onClick={() => {
                //   onPageChange(currentPage + 1);
                //   scrollToTop();
                // }}
                // disabled={currentPage === totalPages}
                onClick={() => {
                  if (currentPage !== totalPages) {
                    onPageChange(currentPage + 1);
                    scrollToTop();
                  }
                }}
              >
                <a
                  class="page-link"

                >
                  Next

                </a>
              </li>
            </ul>
          </nav>
        </div>
      </>
      {/* ))} */}



      {/*  */}
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default Blogs;
