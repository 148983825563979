import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Companylist, DeleteCompany, UpdateCompany } from '../../../Repository/Api';
import DeleteModal from '../../../Utils/DeleteModal';
import { successMsg } from '../../../Utils/Toastify';
import { MuiTableOptions } from '../../../Utils/utils';
const CompaniesList = () => {
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [deleteid, setDeleteid] = useState()
    const { token } = useSelector((state) => state?.login)
    const navigate = useNavigate()
    const GetData = async () => {
        const response = await Companylist(token)
        if (response && response.statusCode === 200) {
            setData(response?.data)
        }
    }
    const handleStatus = async (payload) => {
        const response = await UpdateCompany(payload, token)
        if (response && response?.statusCode === 200) {
            successMsg(response?.message)
            GetData()
        }
    }
    useEffect(() => {
        GetData()
    }, [])
    console.log(data)
    const columns = [
        {
            name: "_id",
            label: "S.No",
            options: {
                filter: false,
                customBodyRender: (value, meta) => {
                    return meta.rowIndex + 1;
                },
            },
        },
        {
            name: "logo",
            label: "Company Logo ",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    <img width={"50px"} height={"50px"} src={`https://infletio-prod.s3.us-east-2.amazonaws.com/${value}`} alt="" />
                  </div>
                );
              },
            },
          },
        {
            name: "name",
            label: "Name"
        },
        {
            name: "companySize",
            label: "Company Size",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    {value}
                  </div>
                );
              },
            },
          },
          
        
        
        {
            name: "status",
            label: "Status",
            options: {
              display: false,
            },
          },
      
        {
            name: "",
            label: "Actions",
            options: {
                customBodyRender: (value, metaData) => {
                    console.log(metaData)
                    const status = metaData?.rowData[4] === "active" ? true : false
                    const id = metaData?.rowData[0]
                    return (
                        <div className='action-icons'>
                            <i
                                class="fa-regular fa-pen-to-square"
                                onClick={() => navigate(`/admin/company-edit/${id}`)}
                            />
                            <i
                                class="fa-solid fa-trash"
                                onClick={() => {
                                    setOpen(true)
                                    setDeleteid(metaData?.rowData[0])
                                }}
                            />
                            <label className="switch ms-2">
                                <input
                                    type="checkbox"
                                    checked={status}
                                    onChange={async (e) => {
                                        const status = e.target.checked === true ? "active" : "inactive";
                                        const payload = {
                                            _id: metaData.rowData[0],
                                            status: status,
                                        };
                                        handleStatus(payload);
                                    }
                                    }
                                /><span className="slider round"></span>
                            </label>
                        </div>
                    )
                }
            }
        }
    ]

    return (
        <div>
            <DeleteModal
                open={open}
                setOpen={() => {
                    setOpen(false);
                }}
                api={DeleteCompany}
                id={deleteid}
                token={token}
                get={GetData}
            />
            <div className='content-header'>
                <p >Companies Management</p>
                <button
                    onClick={() => navigate('/admin/company-create')}
                    className='admin-btn'
                >Add Company</button>
            </div>
            <MUIDataTable
                title='Companies List'
                data={data}
                columns={columns}
                options={MuiTableOptions}
            />
        </div>
    )
}

export default CompaniesList