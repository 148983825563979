import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import job from "../../../Assets/Pictures/custom-img-upload.png";
import { Categorylist, DeleteCategory, UpdateCategory } from '../../../Repository/Api';
import DeleteModal from '../../../Utils/DeleteModal';
import { successMsg } from '../../../Utils/Toastify';
import { MuiTableOptions } from '../../../Utils/utils';
const CategoryList = () => {
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [deleteid, setDeleteid] = useState()
    const Tabs = ["company", "job"]
    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    const [selectedTab, setSelectedTab] = useState(Tabs[0])
    const { token } = useSelector((state) => state?.login)
    const filtereddata=  data?.filter((a) => a?.categoryType === selectedTab)
    const navigate = useNavigate()
    const GetData = async () => {
        const response = await Categorylist(token,selectedTab)
        if (response && response.statusCode === 200) {
            setData(response?.data)
        }
    }
    const handleStatus = async (payload) => {
        const response = await UpdateCategory(payload, token)
        if (response && response?.statusCode === 200) {
            successMsg(response?.message)
            GetData()
        }
    }
    useEffect(() => {
        GetData()
       
    }, [selectedTab])
    const columns = [
        {
          name: "_id",
          label: " S.No",
          options: {
            filter: false,
            customBodyRender: (value, meta) => {
              return meta.rowIndex + 1;
            },
          },
        },
    {
  name: "icon",
  label: "Company Logo",
  options: {
    filter: false,
    customBodyRender: (value, tableMeta) => {
      if (tableMeta && tableMeta.rowData && tableMeta.rowData[1]) {
        return (
          <div 
          // onClick={() =>
          //   Swal.fire({
          //     imageUrl:
          //       ImageBaseUrl +
          //       value,
          //     imageHeight: 350,
          //     imageAlt: "Your Profile Picture",
          //     confirmButtonColor: "rgb(31 37 59)",

          //     confirmButtonText: "Close",
          //   })
          // }
          >
            {tableMeta.rowData[1].length > 0 ? (
              <img width={"50px"} height={"50px"} src={`https://infletio-prod.s3.us-east-2.amazonaws.com/${value}`} alt="" />
            ) :  <img width={"50px"} height={"50px"} src={job} alt="" />}
          </div>
        );
      } else {
        return null;
      }
    },
  
  },
}

          
          
           
          ,
        {
          name: "categoryType",
          label: "Category Name",
        },
        {
          name: "title",
          label: "Title",
        },
        {
          name: "status",
          label: "Status",
          options: {
            display: false,
          },
        },
        {
            name: "",
            label: "Actions",
            options: {
                customBodyRender: (value, metaData) => {
                    const status = metaData?.rowData[3] === "active" ? true : false
                    const id = metaData?.rowData[0]
                    return (
                        <div className='action-icons'>
                            <i
                                class="fa-regular fa-pen-to-square"
                                onClick={() => navigate(`/admin/category-edit/${id}`)}
                            />
                            <i
                                class="fa-solid fa-trash"
                                onClick={() => {
                                    setOpen(true)
                                    setDeleteid(metaData?.rowData[0])
                                }}
                            />
                            <label className="switch ms-2">
                                <input
                                    type="checkbox"
                                    checked={status}
                                    onChange={async (e) => {
                                        const status = e.target.checked === true ? "active" : "inactive";
                                        const payload = {
                                            _id: metaData.rowData[0],
                                            status: status,
                                        };
                                        handleStatus(payload);
                                    }
                                    }
                                /><span className="slider round"></span>
                            </label>
                        </div>
                    )
                }
            }
        }
      ];
      
      const columns2 = [
        {
          name: "_id",
          label: " S.No",
          options: {
            filter: false,
            customBodyRender: (value, meta) => {
              return meta.rowIndex + 1;
            },
          },
        },

        {
          name: "categoryType",
          label: "Category Name",
        },
        {
          name: "title",
          label: "Title",
        },
        {
          name: "status",
          label: "Status",
          options: {
            display: false,
          },
        },
        {
            name: "",
            label: "Actions",
            options: {
                customBodyRender: (value, metaData) => {
                    const status = metaData?.rowData[3] === "active" ? true : false
                    const id = metaData?.rowData[0]
                    return (
                        <div className='action-icons'>
                            <i
                                class="fa-regular fa-pen-to-square"
                                onClick={() => navigate(`/admin/category-edit/${id}`)}
                            />
                            <i
                                class="fa-solid fa-trash"
                                onClick={() => {
                                    setOpen(true)
                                    setDeleteid(metaData?.rowData[0])
                                }}
                            />
                            <label className="switch ms-2">
                                <input
                                    type="checkbox"
                                    checked={status}
                                    onChange={async (e) => {
                                        const status = e.target.checked === true ? "active" : "inactive";
                                        const payload = {
                                            _id: metaData.rowData[0],
                                            status: status,
                                        };
                                        handleStatus(payload);
                                    }
                                    }
                                /><span className="slider round"></span>
                            </label>
                        </div>
                    )
                }
            }
        }
      ];

    return (
        <div>
            <DeleteModal
                open={open}
                setOpen={() => {
                    setOpen(false);
                }}
                api={DeleteCategory}
                id={deleteid}
                token={token}
                get={GetData}
            />
            <div className='content-header'>
                <p >Category Management</p>
                <div>
                <button
                    onClick={() => navigate('/admin/category-create')}
                    className='admin-btn'
                >Add Category</button>
        </div>
     
              
               
            </div>
            <div className="flex items-center justify-between gap-4 text-sm">
        <div className="tab-section">
          <ul className="ps-0">
      
            {Tabs.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={
                    selectedTab === tab
                      ? "serviceTab active"
                      : "serviceTab inactive"
                  }
                  onClick={() => {
                    setSelectedTab(tab)}}
                >
                    {console.log(selectedTab,"setSelectedTab")}
                  <li>
                    <span className="align-middle">{tab}</span>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
            <MUIDataTable
                title={`${capitalizeFirstLetter(selectedTab)} Category `}

                data={filtereddata}
                columns= {selectedTab ==="company"?columns : columns2}
                
                options={MuiTableOptions}
            />
        </div>
    )
}

export default CategoryList