import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Logo from "./Assets/images/logo.png";
import si1 from "./Assets/image/logo-twitter.png";
import si2 from "./Assets/image/logo-facebook.png";
import si3 from "./Assets/image/logo-instagram.png";
import si4 from "./Assets/image/logo-linkedin.png";
const Footer = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ft1  ">
                <img src={Logo} alt="logo" className="mb-5" />

                {/* <p className="mt-3 ">
                  Trust us…we mean it 🙂
                </p> */}
                <span className="otherlogos">
                  <div
                    onClick={() => navigate(`BlogDetails`)}
                    className="anchor"
                  >
                    <img src={si1} alt="logo" />
                  </div>
                  <div
                    onClick={() => navigate(`BlogDetails`)}
                    className="anchor"
                  >
                    <img src={si2} alt="logo" />
                  </div>
                  <div
                    onClick={() => navigate(`BlogDetails`)}
                    className="anchor"
                  >
                    <img src={si3} alt="logo" />
                  </div>
                  <div
                    onClick={() => navigate(`BlogDetails`)}
                    className="anchor"
                  >
                    <img src={si4} alt="logo" />
                  </div>
                </span>
              </div>
            </div>
            {/*  */}
            <div className="col-lg-8 secondpart">
              <div className="row">
                <div className="footerdiv">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-3 ">
                    <div className="secondpart1">
                      <h4>Company</h4>
                      <ul className="secondpartul">
                        <li className="mt-3 footernav">
                          <div
                            onClick={() => {
                              navigate(`/Aboutus/`);
                              scrollToTop();
                            }}
                          >
                            About Us
                          </div>
                        </li>
                        <li className="mt-3 footernav">
                          <div
                            onClick={() => {
                              navigate(`/ContactUs/`);
                              scrollToTop();
                            }}
                          >
                            Contact Us
                          </div>
                        </li>
                        <li className="mt-3 footernav">
                          <div
                            onClick={() => {
                              navigate(`/Blogs/`);
                              scrollToTop();
                            }}
                          >
                            Blogs
                          </div>
                        </li>

                        <li className="mt-3 footernav">
                          <div
                            onClick={() => {
                              navigate(`JobOpportunities`);
                              scrollToTop();
                            }}
                          >
                            Job Opportunities
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-lg-2">
                    <div className="secondpart2">
                      <h4>Legal</h4>
                      <ul className="secondpartul">
                        <li className="mt-3">
                          <div
                            onClick={() => {
                              navigate(`/Policy/`);
                              scrollToTop();
                            }}
                          >
                            Privacy Policy
                          </div>
                        </li>

                        <li className="mt-3">
                          <div
                            onClick={() => {
                              navigate(`/Terms/`);
                              scrollToTop();
                            }}
                          >
                            Terms&Condition{" "}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-lg-4">
                    <div className="secondpart3">
                      <h4>Get in touch</h4>
                      <ul className="secondpartul">
                        <li className="mt-3">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="mailfoot me-2"
                            style={{ color: "#0bb4a1" }}
                          />
                          <a href="mailto:infletio@gmail.com">
                            infletio@gmail.com
                          </a>
                        </li>
                        <li className="mt-3">
                          <FontAwesomeIcon
                            icon={faPhoneVolume}
                            className="callfoot me-2"
                            style={{ color: "#0bb4a1" }}
                          />{" "}
                          <a href="tel:+91-40-66116611">
                            Toll No: 510-676-1539
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <span className="lastpara">
          © 2024 Copyright, All Right Reserved, Infletio
        </span>
      </div>
    </div>
  );
};

export default Footer;
