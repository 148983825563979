import React from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import logo from "./Assets/images/logo.png";

const Navbar = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-light dropstart">
        <div class="container">
          <a href="/">
            <img src={logo} alt="" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav mx-auto ">
              <li class="nav-item">
                {/* <a class="nav-link" href="">
                  Home
                </a> */}
                <div
                  className="nav-link"
                  onClick={() => {
                    navigate(`/`);
                    scrollToTop();
                  }}
                >
                  Home
                </div>
              </li>
              <li class="nav-item">
                {/* <a class="nav-link" href="#JobOpportunities">
                  Job Opportunities
                </a> */}
                <div
                  className="nav-link"
                  onClick={() => {
                    navigate(`/JobOpportunities/`);
                    scrollToTop();
                  }}
                >
                  Job Opportunities
                </div>
              </li>

              <li class="nav-item">
                {/* <a class="nav-link" href="#Blogs">
                  Blogs
                </a> */}
                <div
                  className="nav-link"
                  onClick={() => {
                    navigate(`/Blogs/`);
                    scrollToTop();
                  }}
                >
                  Blogs
                </div>
              </li>
              <li class="nav-item">
                {/* <a class="nav-link" href="#Aboutus">
                  About Us
                </a> */}
                <div
                  className="nav-link"
                  onClick={() => {
                    navigate(`/Aboutus/`);
                    scrollToTop();
                  }}
                >
                  About Us
                </div>
              </li>
            </ul>
            <div className="hmecontbtn">
              {/* <a className="contactbtn" href="#CantactUs">
                Contact Us
              </a> */}
              <div
                className="contactbtn"
                onClick={() => {
                  navigate(`/ContactUs/`);
                  scrollToTop();
                }}
              >
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
