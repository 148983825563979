import React, { useState, useEffect } from 'react'
import Logo from '../../Assets/Pictures/logo.png'
import { EmailValidation } from '../../Utils/validate'
import { Forgotpassword, VerifyOtp } from '../../Repository/Api'
import { errorMsg, successMsg } from '../../Utils/Toastify'
import { useNavigate } from 'react-router-dom'
import OtpInput from "react-otp-input";
import { useRef } from 'react'
import { useSelector } from 'react-redux'
const ForgotPassword = () => {

    const [otp, setotp] = useState("")
    const [validation, setValidation] = useState({})
    const [timer, setTimer] = useState("00:00")
    const { emailId } = useSelector(state => state?.register)
    const navigate = useNavigate()
    const Ref = useRef(null);

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);

        return {
            total,
            minutes,
            seconds,
        };
    };

    const startTimer = (e) => {
        let { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : "0" + minutes) +
                ":" +
                (seconds > 9 ? seconds : "0" + seconds)
            );
        }
    };

    const clearTimer = (e) => {
        setTimer("00:30");
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 30);
        return deadline;
    };

    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);
    const resendOtp = async () => {
        clearTimer(getDeadTime());
        const payload = {
            emailId: emailId
        }
        const response = await Forgotpassword(payload)
        console.log(response)
        if (response && response.statusCode === 200) {
            successMsg(response?.message)
        } else {
            errorMsg(response?.message)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const payload = {
            emailId: emailId,
            otp: otp,
            otpType: "forgotPassword"
        }
        const response = await VerifyOtp(payload)
        if (response && response.statusCode === 200) {
            successMsg(response?.message)
            setTimeout(() => {
                navigate('/change-password')
            }, 1000);
        } else {
            errorMsg(response.message)
        }
    }
    return (
        <div className='conatainer'>
            <div className='forgot-password-page'>
                <div className='w-auto'>
                    <div className='d-flex justify-content-center'>
                        <img src={Logo} />
                    </div>
                    <div>
                        <p className='forgot-header text-center'>Verify with OTP</p>
                        <OtpInput
                            className="otpInput"
                            value={otp}
                            onChange={(e) => setotp(e)}
                            numInputs={6}
                            renderInput={(props) => <input {...props} />}
                            renderSeparator={<span className="otpspace"></span>}
                            inputStyle={{
                                border: "1px solid #CFD3DB",
                                borderRadius: "8px",
                                width: "45px",
                                height: "45px",
                                fontSize: "12px",
                                color: "#000",
                                fontWeight: "400",
                                caretColor: "blue",
                                margin: "10px"
                            }}
                            focusStyle={{
                                border: "1px solid #3773C9",
                                outline: "none",
                            }}
                            separator={<span style={{ width: "8px" }}></span>}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            name="otpValue"
                        />
                        <button className='admin-btn' onClick={handleSubmit}>Verify Otp</button>
                        <p className='text-center'>
                            {timer === "00:00" ?
                                <p onClick={resendOtp} className='resend-otp'>
                                    Resend Otp
                                </p>
                                : `Resend Otp in ${timer}`}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword