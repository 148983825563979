import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { MuiTableOptions } from '../../../Utils/utils';
import { Adminlist, UpdateAdmin, DeleteAdmin } from '../../../Repository/Api';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { successMsg } from '../../../Utils/Toastify';
import DeleteModal from '../../../Utils/DeleteModal';
const AdminList = () => {
    const [admin, setAdmin] = useState([])
    const [open, setOpen] = useState(false)
    const [deleteid, setDeleteid] = useState()
    const { token, userId } = useSelector((state) => state?.login)
    const navigate = useNavigate()
    const GetAdminList = async () => {
        const response = await Adminlist(token)
        if (response && response.statusCode === 200) {
            setAdmin( response?.data?.filter((blog) => blog?._id !== userId))
           
        }
    }
    const handleStatus = async (payload) => {
        const response = await UpdateAdmin(payload, token)
        if (response && response?.statusCode === 200) {
            successMsg(response?.message)
            GetAdminList()
        }
    }
    const handleDelete = (id) => {

    }
    useEffect(() => {
        GetAdminList()
    }, [])
    console.log(admin)
    const columns = [
        {
            name: "_id",
            label: "S.No",
            options: {
                filter: false,
                customBodyRender: (value, meta) => {
                    return meta.rowIndex + 1;
                },
            },
        },
        {
            name: "firstName",
            label: "Name"
        },
        {
            name: "emailId",
            label: "Email Id"
        },
        {
            name: "status",
            label: "Status",
            options: {
              display: false,
            },
          },
        {
            name: "",
            label: "Actions",
            options: {
                customBodyRender: (value, metaData) => {
                    console.log(metaData)
                    const status = metaData?.rowData[3] === "active" ? true : false
                    const id = metaData?.rowData[0]
                    return (
                        <div className='action-icons'>
                            {/* <i
                                class="fa-regular fa-pen-to-square"
                                onClick={() => navigate(`/admin/admin-edit/${id}`)}
                            /> */}
                            <i
                                class="fa-solid fa-trash"
                                onClick={() => {
                                    setOpen(true)
                                    setDeleteid(metaData?.rowData[0])
                                }}
                            />
                            <label className="switch ms-2">
                                <input
                                    type="checkbox"
                                    checked={status}
                                    onChange={async (e) => {
                                        const status = e.target.checked === true ? "active" : "inactive";
                                        const payload = {
                                            _id: metaData.rowData[0],
                                            status: status,
                                        };
                                        handleStatus(payload);
                                    }
                                    }
                                /><span className="slider round"></span>
                            </label>
                        </div>
                    )
                }
            }
        }
    ]

    return (
        <div>
            <DeleteModal
                open={open}
                setOpen={() => {
                    setOpen(false);
                }}
                api={DeleteAdmin}
                id={deleteid}
                token={token}
                get={GetAdminList}
            />
            <div className='content-header'>
                <p >Admin Management</p>
                <button
                    onClick={() => navigate('/admin/admin-create')}
                    className='admin-btn'
                >Add Admin</button>
            </div>
            <MUIDataTable
                title='Admin List'
                data={admin}
                columns={columns}
                options={MuiTableOptions}
            />
        </div>
    )
}

export default AdminList