import React, { useEffect } from "react";
import CustomModal from "./CustomModal";
import { successMsg } from "./Toastify";
const DeleteModal = ({ open, setOpen, api, id, token, get }) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [])
    const handleDelete = async () => {
        const payload = {
            _id: id,
        };
        const response = await api(payload, token);
        if (response && response.statusCode === 200) {
            setOpen(false);
            successMsg(response?.message);
            get()
        }
    };
    return (

        <div>
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setOpen(false);
                }}
            >
                <div className="modal-content delete-modal">
                    <div className="delete-modal nunito">
                        <span className="icon-container" onClick={() => setOpen(false)}>
                            <i className="fa-solid fa-circle-xmark"></i>
                        </span>
                        <p className="delete-confirm">Confirmation</p>
                        <p className="">Are you sure you want to delete this?</p>
                        <div className="mt-4 d-flex justify-content-around">
                            <button
                                className="btn-cancel h-auto"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-delete h-auto"
                                onClick={handleDelete}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </CustomModal >
        </div >
    );
};

export default DeleteModal;
